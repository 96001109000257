import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { addTax, formatDate, formatPrice, nestedObjToFlat } from "../../util";
import { stylesCommon, stylesOrder } from "../../css";

import CancelDialog from "./CancelDialog";
import InvoiceModifyDialog from "./InvoiceModifyDialog";
import ReceiptModifyDialog from "./ReceiptModifyDialog";
import dict from "../../dictionary";
import { getOrderStatus } from "./OrderList";
import { useGetOrder } from "../../fetch/order";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const OrderDetail = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { orders, getOrders } = useGetOrder();
  const [order, setOrder] = useState<any>(undefined);

  useEffect(() => {
    getOrders({});
  }, []);

  useEffect(() => {
    orders.length > 0 && getOrderStatus(orders[0]);
  }, [orders]);

  useEffect(() => {
    const matchOrders = orders
      ? orders.filter((order: any) => {
          return order.id === props.match.params.orderId;
        })
      : [];
    matchOrders.length &&
      setOrder(nestedObjToFlat(matchOrders[0], matchOrders[0].questions));
  }, [orders]);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  const getCancelableStatus = () => {
    let status = "cancelable";
    if (order) {
      if (order.canceled_at) {
        status = "canceled";
      } else {
        if (order.confirmed_at) {
          status = "confirmed";
        } else {
          if (new Date(order.product.close_at) < new Date()) {
            status = "closed";
          }
        }
      }
    }
    return status;
  };

  // For popup menu for invoice / receipt download
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // For dialog for invoice / receipt modification
  const [openInvoiceModifyDialog, setOpenInvoiceModifyDialog] =
    useState<boolean>(false);
  const [openReceiptModifyDialog, setOpenReceiptModifyDialog] =
    useState<boolean>(false);

  const handleCloseInvoiceModifyDialog = () => {
    setOpenInvoiceModifyDialog(false);
  };
  const handleCloseReceiptModifyDialog = () => {
    setOpenReceiptModifyDialog(false);
  };

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mb4}>
        <Typography variant="h6" className={classes.title}>
          {dict.order.confirm}
        </Typography>
        <Paper elevation={0} square className={classes.p4}>
          <Grid container className={classes.mb3} justify="flex-end">
            {order?.product.price > 0 && (order.invoice || order.receipt) && (
              <Box>
                {order.invoice && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      order &&
                      order.invoice.name === null &&
                      order.invoice.postcode === null &&
                      order.invoice.address === null
                        ? setOpenInvoiceModifyDialog(true)
                        : props.history.push(`../invoice/${order.id}`);
                    }}
                  >
                    {dict.order.invoice}
                  </Button>
                )}
                {order.receipt && (
                  <Button
                    variant="outlined"
                    className={classes.ml2}
                    onClick={() => {
                      order &&
                      order.receipt.name === null &&
                      order.receipt.postcode === null &&
                      order.receipt.address === null
                        ? setOpenReceiptModifyDialog(true)
                        : props.history.push(`../receipt/${order.id}`);
                    }}
                  >
                    {dict.order.receipt}
                  </Button>
                )}
              </Box>
            )}
            <Grid item xs={12} md={12} className={classes.mb1}>
              <Typography className={classes.mt1}>
                {dict.order.orderStatus}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.order.orderedDate}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {order && formatDate(new Date(order.ordered_at))}
                  </Typography>
                </Grid>
                {order?.product.price > 0 && (
                  <Fragment>
                    <Grid item xs={12} md={3} className={classes.mt1}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {dict.order.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.mt1}>
                      <Typography variant="subtitle2">
                        {order && getOrderStatus(order)}
                      </Typography>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.mb1} />

          <Grid container className={classes.mb3} justify="flex-end">
            <Grid item xs={12} md={12} className={classes.mb1}>
              <Typography className={classes.mt1}>
                {dict.order.orderingProduct}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.order.productName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {order && order.product.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.common.totalPrice}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {order && formatPrice(addTax(order.product.price))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {order?.product.price > 0 && (
            <Fragment>
              <Divider className={classes.mb1} />
              <Grid container className={classes.mb3} justify="flex-end">
                <Grid item xs={12} md={12} className={classes.mb1}>
                  <Typography className={classes.mt1}>
                    {dict.order.orderInfo}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container>
                    <Grid item xs={12} md={3} className={classes.mt1}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {dict.order.orderInfo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.mt1}>
                      <Typography variant="subtitle2">
                        {order && order.prepayment
                          ? dict.order.prepayment
                          : dict.order.nonPrepayment}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.mt1}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {dict.order.paymentName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.mt1}>
                      <Typography variant="subtitle2">
                        {order && order.oneself
                          ? dict.order.oneself
                          : dict.order.nonOneself}
                      </Typography>
                    </Grid>
                    {order && order.prepayment && !order.oneself && (
                      <Fragment>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {order && order.payment_name}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                    {order && !order.prepayment && !order.oneself && (
                      <Fragment>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {order && order.payment_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {order && order.postcode} {order && order.address}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                    {order && order.kyufukin && (
                      <Fragment>
                        <Grid item xs={12} md={3} className={classes.mt1}>
                          <Typography variant="subtitle2" color="textSecondary">
                            {dict.order.kyufukin}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {dict.order.requestKyufukin}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                    {/* <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.order.note}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {order && order.note ? order.note : dict.common.none}
                  </Typography>
                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )}

          <Grid container justify="center" className={classes.mt3} spacing={3}>
            <Grid item sm={6} xs={12}>
              <Fab
                className={classes.defaultFab}
                variant="extended"
                disabled={getCancelableStatus() != "cancelable"}
                onClick={async () => {
                  setOpenCancelDialog(true);
                }}
                size="large"
                color="default"
              >
                {getCancelableStatus() === "cancelable"
                  ? dict.order.cancel
                  : getCancelableStatus() === "canceled"
                  ? dict.order.canceled
                  : dict.order.notCancelable}
              </Fab>
              <Box className={classes.mt1}>
                <Typography variant="caption">
                  {getCancelableStatus() === "confirmed"
                    ? dict.order.notCancelableConfirmed
                    : getCancelableStatus() === "closed"
                    ? dict.order.notCancelableClosed
                    : null}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <CancelDialog
        open={openCancelDialog}
        handleClose={() => {
          setOpenCancelDialog(false);
        }}
        history={props.history}
        order={order}
      />
      <InvoiceModifyDialog
        open={openInvoiceModifyDialog}
        handleClose={handleCloseInvoiceModifyDialog}
        history={props.history}
        orderId={order ? order.id : null}
        order={order ? order : null}
      />
      <ReceiptModifyDialog
        open={openReceiptModifyDialog}
        handleClose={handleCloseReceiptModifyDialog}
        history={props.history}
        orderId={order ? order.id : null}
        order={order ? order : null}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(OrderDetail))
);
