import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  Box,
  Container,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Typography,
  withStyles
} from "@material-ui/core";

import { stylesAuthentication, stylesCommon } from "../../css";
import dict from "../../dictionary";
import firebase, { actionCodeSettings } from "../../firebase";
import useUserStateContext from "../../userstate";
import MessageDialog from "../MessageDialog";

const PasswordForgot = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>(
    dict.form.passwordResetFailedTitle
  );
  const [messageContent, setMessageContent] = useState<string>("");
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [actionButtonCaption, setActionButtonCaption] = useState<string | null>(
    null
  );

  const handleClose = () => {
    setOpen(false);
  };

  const PasswordForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email(dict.form.email)
      .required(dict.form.required)
  });

  useEffect(() => {
    userState.signedIn && props.history.push("/");
  }, [userState, props.history]);

  async function resetPassword(email: string, setSubmitting: any) {
    try {
      await firebase.auth.sendPasswordResetEmail(email, actionCodeSettings);
      setNextUrl("/login");
      setActionButtonCaption(dict.common.toLogin);
      setMessageTitle(dict.passwordReset.succeededTitle);
      setMessageContent(dict.passwordReset.succeededMessage);
      setOpen(true);
    } catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          setMessageContent(dict.form.loginFailedMessageUserNotFound);
          break;
        default:
          setMessageContent(error.message);
      }
      setNextUrl(null);
      setActionButtonCaption(null);
      setOpen(true);
    }
    setSubmitting(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={10}>
        <Formik
          initialValues={{
            email: ""
          }}
          onSubmit={(values, formikActions) => {
            resetPassword(values.email, formikActions.setSubmitting);
          }}
          validationSchema={PasswordForgotSchema}
          render={({ submitForm, isSubmitting }) => (
            <Fragment>
              <Box className={classes.header}>
                <Typography component="h1" variant="h6" align="center">
                  {dict.common.passwordReset}
                </Typography>
              </Box>
              <Divider />
              {isSubmitting && <LinearProgress />}
              <Form className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography component="p">
                      {dict.passwordReset.message1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="email"
                      variant="outlined"
                      label={dict.common.email}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fab
                      className={classes.primaryFabFlat}
                      disabled={isSubmitting}
                      type="submit"
                      variant="extended"
                      onClick={submitForm}
                      size="large"
                      color="primary"
                    >
                      {dict.common.receiveMail}
                    </Fab>
                  </Grid>
                  <Divider />
                </Grid>
                <Divider />

                <Grid container spacing={4} justify="center">
                  <Link
                    className={classes.link}
                    onClick={() => props.history.push("/login")}
                  >
                    {dict.common.forMember}
                  </Link>
                </Grid>
              </Form>
            </Fragment>
          )}
        ></Formik>
      </Paper>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={nextUrl}
        actionButtonCaption={actionButtonCaption}
        history={props.history}
      />
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(PasswordForgot))
);
