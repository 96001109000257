import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

declare global {
  interface Window {
    piAId: string;
    piCId: string;
    piHostname: string;
  }
}

const MAWrapper: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    if (process.env.REACT_APP_MA_ENABLED === "false") {
      return;
    }

    const async_load = () => {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        (document.location.protocol === "https:" ? "https://" : "http://") +
        window.piHostname +
        "/pd.js";
      var c = document.getElementsByTagName("script")[0];

      if (c && c.parentNode) {
        c.parentNode.insertBefore(s, c);
      }
    };

    window.piAId = "1030621";
    window.piCId = "20191";
    window.piHostname = "go.datamix-school.com";

    async_load();
  }, [props.location]); // every time url changes

  return <>{props.children}</>;
};

export default withRouter(MAWrapper);
