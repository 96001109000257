import React from "react";
import { doubleToSingleArray } from "../util";
import { getUrl } from "../userstate";
import { intToWeekdayJa } from "../../../enum";
import useFetch from "../fetch/useFetch";

export const useGetProduct = (): any => {
  const { execute } = useFetch("GET");
  const [products, setProducts] = React.useState([]);
  const [getProductsError, setGetProductsError] = React.useState(null);

  const getProducts = async () => {
    try {
      const fetchedProducts = await execute({
        url: getUrl() + `v1/s/product/`,
      });
      setProducts(fetchedProducts);
    } catch (error) {
      setGetProductsError(error);
    }
  };

  return { products, getProducts, getProductsError };
};

export const useGetProducts = (): any => {
  const { execute } = useFetch("GET");
  const [productsAll, setProductsAll] = React.useState([]);
  const [productsIkusei, setProductsIkusei] = React.useState<any[]>([]);
  const [productsWeather, setProductsWeather] = React.useState<any[]>([]);
  const [productsHR, setProductsHR] = React.useState<any[]>([]);
  const [productsPDA, setProductsPDA] = React.useState<any[]>([]);
  const [productsDataEng, setProductsDataEng] = React.useState<any[]>([]);
  const [productsAIMLEng, setProductsAIMLEng] = React.useState<any[]>([]);
  const [productsDSIkusei, setProductsDSIkusei] = React.useState<any[]>([]);
  const [productsDSWeather, setProductsDSWeather] = React.useState<any[]>([]);
  const [productsJunbi, setProductsJunbi] = React.useState([]);
  const [productsOther, setProductsOther] = React.useState([]);
  const [productsFree, setProductsFree] = React.useState([]);
  const [getProductsError, setGetProductsError] = React.useState(null);
  const [typesIkusei, setTypesIkusei] = React.useState<object[]>([]);
  const [weekdaysIkusei, setWeekdaysIkusei] = React.useState<object[]>([]);
  const [typesWeather, setTypesWeather] = React.useState<object[]>([]);
  const [weekdaysWeather, setWeekdaysWeather] = React.useState<object[]>([]);
  const [typesHR, setTypesHR] = React.useState<object[]>([]);
  const [weekdaysHR, setWeekdaysHR] = React.useState<object[]>([]);
  const [typesPDA, setTypesPDA] = React.useState<object[]>([]);
  const [weekdaysPDA, setWeekdaysPDA] = React.useState<object[]>([]);
  const [typesDataEng, setTypesDataEng] = React.useState<object[]>([]);
  const [weekdaysDataEng, setWeekdaysDataEng] = React.useState<object[]>([]);
  const [typesAIMLEng, setTypesAIMLEng] = React.useState<object[]>([]);
  const [weekdaysAIMLEng, setWeekdaysAIMLEng] = React.useState<object[]>([]);
  const [typesDSIkusei, setTypesDSIkusei] = React.useState<object[]>([]);
  const [weekdaysDSIkusei, setWeekdaysDSIkusei] = React.useState<object[]>([]);
  const [typesDSWeather, setTypesDSWeather] = React.useState<object[]>([]);
  const [weekdaysDSWeather, setWeekdaysDSWeather] = React.useState<object[]>([]);

  const getProducts = async () => {
    try {
      const fetchedProducts = await execute({
        url: getUrl() + `v1/s/product/`,
      });
      setProductsAll(fetchedProducts);
      const fetchedProductsOther = fetchedProducts.filter(
        (product: any) =>
          !product.tags.includes("ikusei") &&
          !product.tags.includes("weather") &&
          !product.tags.includes("hr") &&
          !product.tags.includes("pda") &&
          !product.tags.includes("de") &&
          !product.tags.includes("mle") &&
          !product.tags.includes("ds-ikusei") &&
          !product.tags.includes("ds-weather") &&
          !product.tags.includes("junbi") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsIkusei = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("ikusei") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsWeather = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("weather") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsHR = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("hr") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsPDA = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("pda") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsDataEng = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("de") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsAIMLEng = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("mle") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsDSIkusei = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("ds-ikusei") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsDSWeather = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("ds-weather") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      const fetchedProductsJunbi = fetchedProducts.filter(
        (product: any) =>
          product.tags.includes("junbi") &&
          product.tags.filter((tag: string) => {
            return tag.startsWith("free");
          }).length === 0
      );
      let freeTags: string[] = [];
      let productsFreeUnique: any = [];
      fetchedProducts
        .filter(
          (product: any) =>
            product.tags.filter((tag: string) => {
              return tag.startsWith("free");
            }).length > 0
        )
        .forEach((product: any) => {
          const intersection = freeTags.filter((x: string) =>
            product.tags.includes(x)
          );
          if (intersection.length === 0) {
            product.tags.forEach((tag: string) => {
              tag.startsWith("free") && freeTags.push(tag);
            });
            productsFreeUnique.push(product);
          }
        });

      setProductsIkusei(fetchedProductsIkusei);
      setProductsWeather(fetchedProductsWeather);
      setProductsHR(fetchedProductsHR);
      setProductsPDA(fetchedProductsPDA);
      setProductsDataEng(fetchedProductsDataEng);
      setProductsAIMLEng(fetchedProductsAIMLEng);
      setProductsDSIkusei(fetchedProductsDSIkusei);
      setProductsDSWeather(fetchedProductsDSWeather);
      setProductsJunbi(fetchedProductsJunbi);
      setProductsOther(fetchedProductsOther);
      setProductsFree(productsFreeUnique);

      const tagsIkusei = fetchedProductsIkusei.map(
        (product: any) => product.tags
      );
      setWeekdaysIkusei(tagsToWeeks(tagsIkusei));
      setTypesIkusei(tagsToTypes(tagsIkusei));

      const tagsWeather = fetchedProductsWeather.map(
        (product: any) => product.tags
      );
      setWeekdaysWeather(tagsToWeeks(tagsWeather));
      setTypesWeather(tagsToTypes(tagsWeather));

      const tagsHR = fetchedProductsHR.map(
        (product: any) => product.tags
      );
      setWeekdaysHR(tagsToWeeks(tagsHR));
      setTypesHR(tagsToTypes(tagsHR));

      const tagsPDA = fetchedProductsPDA.map(
        (product: any) => product.tags
      );
      setWeekdaysPDA(tagsToWeeks(tagsPDA));
      setTypesPDA(tagsToTypes(tagsPDA));

      const tagsDataEng = fetchedProductsDataEng.map(
        (product: any) => product.tags
      );
      setWeekdaysDataEng(tagsToWeeks(tagsDataEng));
      setTypesDataEng(tagsToTypes(tagsDataEng));

      const tagsAIMLEng = fetchedProductsAIMLEng.map(
        (product: any) => product.tags
      );
      setWeekdaysAIMLEng(tagsToWeeks(tagsAIMLEng));
      setTypesAIMLEng(tagsToTypes(tagsAIMLEng));

      const tagsDSIkusei = fetchedProductsDSIkusei.map(
        (product: any) => product.tags
      );
      setWeekdaysDSIkusei(tagsToWeeks(tagsDSIkusei));
      setTypesDSIkusei(tagsToTypes(tagsDSIkusei));

      const tagsDSWeather = fetchedProductsDSWeather.map(
        (product: any) => product.tags
      );
      setWeekdaysDSWeather(tagsToWeeks(tagsDSWeather));
      setTypesDSWeather(tagsToTypes(tagsDSWeather));
    } catch (error) {
      setGetProductsError(error);
    }
  };

  return {
    getProducts,
    productsAll,
    productsIkusei,
    productsWeather,
    productsHR,
    productsPDA,
    productsDataEng,
    productsAIMLEng,
    productsDSIkusei,
    productsDSWeather,
    productsJunbi,
    productsOther,
    productsFree,
    setProductsIkusei,
    setProductsWeather,
    setProductsHR,
    setProductsPDA,
    setProductsDataEng,
    setProductsAIMLEng,
    setProductsDSIkusei,
    setProductsDSWeather,
    setProductsJunbi,
    setProductsOther,
    setProductsFree,
    typesIkusei,
    typesWeather,
    typesHR,
    typesPDA,
    typesDataEng,
    typesAIMLEng,
    typesDSIkusei,
    typesDSWeather,
    weekdaysIkusei,
    weekdaysWeather,
    weekdaysHR,
    weekdaysPDA,
    weekdaysDataEng,
    weekdaysAIMLEng,
    weekdaysDSIkusei,
    weekdaysDSWeather,
    getProductsError,
  };
};

const compare = (a: any, b: any) => {
  let comparison = 0;
  if (a.forSort > b.forSort) {
    comparison = 1;
  } else if (a.forSort < b.forSort) {
    comparison = -1;
  }
  return comparison;
};

const tagsToTypes = (tags: any) => {
  const tagsSingleArray = doubleToSingleArray(tags);
  const tagsUnique = Array.from(new Set(tagsSingleArray));
  let typeTags = [];
  tagsUnique.includes("type_all") &&
    typeTags.push({
      value: "type_all",
      label: "一括申込（全ステップ・入学金免除）",
    });
  tagsUnique.includes("type_bcf") &&
    typeTags.push({
      value: "type_bcf",
      label: "ステップ別申込（ブートキャンプ前期）",
    });
  tagsUnique.includes("type_bcs") &&
    typeTags.push({
      value: "type_bcs",
      label: "ステップ別申込（ブートキャンプ後期）",
    });
    tagsUnique.includes("type_bcfs") &&
    typeTags.push({
      value: "type_bcfs",
      label: "ステップ別申込（ブートキャンプ前期＆後期）",
    });
  tagsUnique.includes("type_bc") &&
    typeTags.push({
      value: "type_bc",
      label: "ステップ別申込（ブートキャンプ）",
    });
  tagsUnique.includes("type_bcbs") &&
    typeTags.push({
      value: "type_bcbs",
      label: "ステップ別申込（ブートキャンプ&ベーシック）",
    });
  tagsUnique.includes("type_bcbsad") &&
    typeTags.push({
      value: "type_bcbsad",
      label: "ステップ別申込（ブートキャンプ&ベーシック&アドバンス）",
    });
  tagsUnique.includes("type_bs") &&
    typeTags.push({
      value: "type_bs",
      label: "ステップ別申込（ベーシック）"
    });
  tagsUnique.includes("type_ad") &&
    typeTags.push({
      value: "type_ad",
      label: "ステップ別申込（アドバンス）"
    });
  tagsUnique.includes("type_integ") &&
    typeTags.push({
      value: "type_integ",
      label: "ステップ別申込（インテグレーション）",
    });

  return typeTags;
};

const tagsToWeeks = (tags: any) => {
  const tagsSingleArray = doubleToSingleArray(tags);
  const tagsUnique = Array.from(new Set(tagsSingleArray));
  const weekdayTags = tagsUnique.filter((tag: any) => {
    return tag.startsWith("weekday");
  });
  const weekdays = weekdayTags
    .map((tag: any) => {
      const weekday = Number(tag.replace("weekday_", "").split("-")[0]);
      const time =
        tag.replace("weekday_", "").split("-").length >= 2
          ? tag.replace("weekday_", "").split("-")[1]
          : "";
      const forSort = `${weekday.toString()}${time}`;
      return {
        value: tag,
        label: time
          ? `${intToWeekdayJa[weekday]} ${time} スタート`
          : `オンライン限定`,
        forSort: forSort,
      };
    })
    .sort(compare);
  return weekdays;
};

// TODO remove along with ProductOrdered.tsx
export const useGetProductOrdered = (): any => {
  const { execute } = useFetch("GET");
  const [products, setProducts] = React.useState([]);
  const [getProductsError, setGetProductsError] = React.useState(null);

  const getProducts = async () => {
    try {
      const fetchedProducts = await execute({
        url: getUrl() + `v1/s/product/?ordered=true`,
      });
      setProducts(fetchedProducts);
    } catch (error) {
      setGetProductsError(error);
    }
  };

  return { products, getProducts, getProductsError };
};
