import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  Box,
  Container,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Typography,
  withStyles,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

import { stylesAuthentication, stylesCommon } from "../../css";
import dict from "../../dictionary";
import { usePostSignup } from "../../fetch/signup";
import firebase from "../../firebase";
import useUserStateContext from "../../userstate";
import MessageDialog from "../MessageDialog";

function SignupExistingStudent(props: any) {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(dict.form.email)
      .required(dict.form.required),
    password: Yup.string()
      .min(6, dict.form.minLen6)
      .required(dict.form.required)
  });

  useEffect(() => {
    userState.signedIn && props.history.push("/");
  }, [userState, props.history]);

  async function signup(email: string, password: string, setSubmitting: any) {
    try {
      await firebase.auth.createUserWithEmailAndPassword(email, password);
      const actionCodeSettings = {
        url: process.env.REACT_APP_STUDENT_SITE_URL
          ? `${process.env.REACT_APP_STUDENT_SITE_URL}signup-existing/complete`
          : ""
      };
      await firebase.auth.currentUser
        ?.sendEmailVerification(actionCodeSettings)
        .then(async function() {
          const slackEndpoint: string = process.env.REACT_APP_SLACK_ENDPOINT
            ? process.env.REACT_APP_SLACK_ENDPOINT
            : "https://hooks.slack.com/services/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";
          await fetch(slackEndpoint, {
            method: "POST",
            body: JSON.stringify({
              text: `existing student (email: ${email}) signed up to S2M. please find the student and add \`${firebase.auth.currentUser?.uid}\` to uid field`,
              icon_emoji: "heavy_check_mark"
            })
          });

          props.history.push({
            pathname: `/signup/complete`,
            state: {
              message: dict.form.signupSucceededMessage
            }
          });
        })
        .catch(function({ error: any }) {
          // Error occurred. Inspect error.code.
        });
    } catch (error) {
      setMessageTitle(dict.form.signupFailedTitle);
      switch (error.code) {
        case "auth/email-already-in-use":
          error.message.includes("as another user's initial email.")
            ? setMessageContent(
                dict.form.signupFailedMessageEmailAlreadyInUseAsInitial
              )
            : setMessageContent(dict.form.signupFailedMessageEmailAlreadyInUse);
          break;
        case "auth/user-not-found":
          setMessageContent(dict.form.loginFailedMessageUserNotFound);
          break;
        default:
          setMessageContent(error.message);
      }
      setOpen(true);
    }
    setSubmitting(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={10}>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={(values, formikActions) => {
            signup(values.email, values.password, formikActions.setSubmitting);
          }}
          validationSchema={SignupSchema}
          render={({ submitForm, isSubmitting }) => (
            <Fragment>
              <Box className={classes.header}>
                <Typography component="h1" variant="h6" align="center">
                  {dict.common.signupExistingStudent}
                </Typography>
              </Box>
              <Divider />
              {isSubmitting && <LinearProgress />}
              <Form className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="email"
                      variant="outlined"
                      label={dict.common.email}
                      component={TextField}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      type="password"
                      variant="outlined"
                      label={dict.common.password}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>

                  <Grid container justify="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={policyAgreed}
                          onChange={(event: any) => {
                            setPolicyAgreed(event.target.checked);
                          }}
                          value={policyAgreed}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          <Link
                            href="https://datamix.co.jp/privacy-policy/"
                            target="_blank"
                          >
                            {dict.order.privacyPolicy}
                          </Link>
                          {dict.order.agree}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Fab
                      className={classes.primaryFab}
                      disabled={isSubmitting || !policyAgreed}
                      type="submit"
                      variant="extended"
                      onClick={submitForm}
                      size="large"
                    >
                      {dict.common.signup}
                    </Fab>
                  </Grid>
                  <Divider />
                </Grid>
                <Divider />

                <Grid container spacing={4} justify="center">
                  <Link
                    className={classes.link}
                    onClick={() => props.history.push("/login")}
                  >
                    {dict.common.forMember}
                  </Link>
                </Grid>
              </Form>
            </Fragment>
          )}
        ></Formik>
      </Paper>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={null}
        history={null}
      />
    </Container>
  );
}

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(SignupExistingStudent))
);
