import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router";

import { Container, Paper, Typography, withStyles } from "@material-ui/core";

import { stylesCommon, stylesOrder } from "../../css";
import dict from "../../dictionary";
import useUserStateContext from "../../userstate";
import firebase from "../../firebase";

const SignupComplete = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();

  useEffect(() => {
    firebase.logout();
  }, [userState, props.history]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mt4}>
        <Paper elevation={0} square className={classes.p4}>
          <Typography variant="h6" className={classes.mb2}>
            {dict.form.signupExistingStudentSucceededTitle}
          </Typography>
          <Typography className={classes.lineBreak}>
            {dict.form.signupExistingStudentSucceededMessage}
          </Typography>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(SignupComplete))
);
