import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router";

import {
  ButtonBase,
  CircularProgress,
  Container,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";

import { stylesCommon, stylesCourseCard, stylesList } from "../../css";
import dict from "../../dictionary";
import { useGetCourses } from "../../fetch/course";
import useUserStateContext from "../../userstate";
import CourseCard, { getFirstDate, getLastDate } from "./CourseCard";

const CourseList = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const {
    courses,
    getEnrolledCourses,
    getEnrolledCoursesError,
  } = useGetCourses();

  const colorNum = (function () {
    let count = 0;
    return {
      increment: function () {
        count === 7 ? (count = 0) : count++;
        return count;
      },
    };
  })();

  useEffect(() => {
    getEnrolledCourses({});
  }, []);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  const getCoursesOpen = (courses: any) => {
    return courses.filter((course: any) => {
      return (
        !course.categories.includes("E-Learning") &&
        !course.categories.includes("Online") &&
        getFirstDate(course) <= new Date() &&
        new Date() <= getLastDate(course)
      );
    });
  };

  const getCoursesToBeOpen = (courses: any) => {
    return courses.filter((course: any) => {
      return (
        !course.categories.includes("E-Learning") &&
        !course.categories.includes("Online") &&
        new Date() <= getFirstDate(course)
      );
    });
  };

  const getCoursesClose = (courses: any) => {
    return courses.filter((course: any) => {
      return (
        !course.categories.includes("E-Learning") &&
        !course.categories.includes("Online") &&
        getLastDate(course) <= new Date()
      );
    });
  };

  const getCoursesELearning = (courses: any) => {
    return courses.filter((course: any) => {
      return (
        course.categories.includes("E-Learning") ||
        course.categories.includes("Online")
      );
    });
  };

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.listContainerInner}>
        <p
          style={{
            backgroundColor: "#fff",
            marginBottom: "1rem",
            padding: "1rem",
            border: "1px solid #02B3E4",
            borderRadius: "4px",
            lineHeight: "1.5rem",
          }}
        >
          利用規約が変更になりました。下記URLのお知らせにて詳細を記載しておりますので、必ずご確認頂くようお願い致します。
          <br />
          <a target="_blank" href="https://datamix.co.jp/news/20221011/">
            スクール利用規約変更のお知らせ（https://datamix.co.jp/news/20221011/）
          </a>
        </p>
        <Grid container className={classes.mb4} spacing={2}>
          {courses && getCoursesOpen(courses).length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6">
                {dict.course.coursesOpen}
              </Typography>
            </Grid>
          )}
          {courses.length === 0 && <CircularProgress />}
          {courses &&
            getCoursesOpen(courses).map((course: any) => {
              return (
                <Grid item xs={12} md={12}>
                  <ButtonBase
                    className={classes.fullWidth}
                    onClick={() => {
                      props.history.push({
                        pathname: `/course/${course.id}`,
                      });
                    }}
                  >
                    <CourseCard
                      course={course}
                      showDescription={true}
                      colorNum={colorNum.increment()}
                    />
                  </ButtonBase>
                </Grid>
              );
            })}
          {courses && getCoursesToBeOpen(courses).length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6">
                {dict.course.coursesToBeOpen}
              </Typography>
            </Grid>
          )}
          {courses &&
            getCoursesToBeOpen(courses).map((course: any) => {
              return (
                <Grid item xs={12} md={4}>
                  <ButtonBase
                    className={classes.fullWidth}
                    onClick={() => {
                      props.history.push({
                        pathname: `/course/${course.id}`,
                      });
                    }}
                  >
                    <CourseCard
                      course={course}
                      showDescription={false}
                      colorNum={colorNum.increment()}
                    />
                  </ButtonBase>
                </Grid>
              );
            })}
          {courses && getCoursesClose(courses).length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6">
                {dict.course.coursesClose}
              </Typography>
            </Grid>
          )}
          {courses &&
            getCoursesClose(courses).map((course: any) => {
              return (
                <Grid item xs={12} md={4}>
                  <ButtonBase
                    className={classes.fullWidth}
                    onClick={() => {
                      props.history.push({
                        pathname: `/course/${course.id}`,
                      });
                    }}
                  >
                    <CourseCard
                      course={course}
                      showDescription={false}
                      colorNum={colorNum.increment()}
                    />
                  </ButtonBase>
                </Grid>
              );
            })}
          {courses && getCoursesELearning(courses).length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6">
                {dict.course.coursesELearning}
              </Typography>
            </Grid>
          )}
          {courses &&
            getCoursesELearning(courses).map((course: any) => {
              return (
                <Grid item xs={12} md={4}>
                  <ButtonBase
                    className={classes.fullWidth}
                    onClick={() => {
                      props.history.push({
                        pathname: `/course/${course.id}`,
                      });
                    }}
                  >
                    <CourseCard
                      course={course}
                      showDescription={false}
                      colorNum={colorNum.increment()}
                    />
                  </ButtonBase>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesList)(withStyles(stylesCourseCard)(withRouter(CourseList)))
);
