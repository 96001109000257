import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const usePostSignup = () => {
  const { execute } = useFetch("POST");

  const postSignup = async ({ redirect }: { redirect?: string }) => {
    try {
      const newSignup = await execute({
        url: redirect
          ? getUrl() + `v1/s/signup/?redirect=${redirect}`
          : getUrl() + `v1/s/signup/`,
        body: {}
      });
      return newSignup;
    } catch (error) {
      console.log(error);
    }
  };

  return { postSignup };
};
