import React from "react";

import useFetch from "../fetch/useFetch";
import { getUrl } from "../userstate";

export const useGetMe = (): any => {
  const { execute } = useFetch("GET");
  const [me, setMe] = React.useState([]);
  const [getMeError, setGetMeError] = React.useState(null);

  const getMe = async () => {
    try {
      const fetchedMe = await execute({
        url: getUrl() + `v1/s/me/`
      });
      setMe(fetchedMe);
    } catch (error) {
      setGetMeError(error);
    }
  };

  return { me, getMe, getMeError };
};

export const usePutMe = (): any => {
  const { execute } = useFetch("PUT");

  const putMe = async ({ body }: { body: object }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/me`,
        body: body
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  return { putMe };
};
