import React, { Fragment } from "react";
import { withRouter } from "react-router";

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DescriptionIcon from "@material-ui/icons/Description";
// import BusinessIcon from "@material-ui/icons/Business";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";

import { stylesCommon, stylesProfile } from "../../css";
import dict from "../../dictionary";
import firebase from "../../firebase";

const ListItemLink = (props: any) => {
  return <ListItem button component="a" {...props} />;
};

const ProfileMenu = (props: any) => {
  const { classes } = props;

  return (
    <Fragment>
      <List component="nav" className={classes.list}>
        <ListItem
          button
          onClick={() => props.history.push("/profile")}
          className={classes.listItem}
        >
          <ListItemIcon>
            <AccountCircleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={dict.common.basicInfo} />
        </ListItem>
        {/* <Divider />
        <ListItem
          button
          onClick={() => props.history.push("/company")}
          className={classes.listItem}
        >
          <ListItemIcon>
            <BusinessIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={dict.common.companyInfo} />
        </ListItem> */}
        <Divider />
        <ListItem
          button
          onClick={() => props.history.push("/settings")}
          className={classes.listItem}
        >
          <ListItemIcon>
            <SettingsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={dict.common.account} />
        </ListItem>
        <Divider />
        <ListItemLink
          button
          href="https://datamix.co.jp/corp/terms/"
          className={classes.listItem}
        >
          <ListItemIcon>
            <DescriptionIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={dict.order.policy} />
        </ListItemLink>
        <Divider />
        <ListItem
          button
          onClick={() => firebase.auth.signOut()}
          className={classes.listItem}
        >
          <ListItemIcon>
            <ExitToAppIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={dict.common.logout} />
        </ListItem>
      </List>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProfile)(withRouter(ProfileMenu))
);
