import classes from "*.module.css";
import { Field, Form, Formik } from "formik";
import {
  CheckboxWithLabel,
  RadioGroup,
  Select,
  TextField,
} from "formik-material-ui";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import {
  Checkbox,
  CircularProgress,
  Container,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  Switch as MaterialSwitch,
  Typography,
  withStyles,
} from "@material-ui/core";

import { IItem } from "../../../../interface";
import { stylesCommon, stylesProduct } from "../../css";
import dict from "../../dictionary";
import {
  useGetQuestionnaire,
  usePutQuestionnaireAnswer,
} from "../../fetch/questionnaire";
import { useAddAttendSession } from "../../fetch/session";
import useUserStateContext from "../../userstate";
import MessageDialog from "../MessageDialog";

const QuestionnaireAnswer = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { questionnaire, getQuestionnaire } = useGetQuestionnaire();
  const { putQuestionnaireAnswer } = usePutQuestionnaireAnswer();
  const [QuestionnaireSchema, setQuestionnaireSchema] = useState<any>(null);
  const [QuestionnaireInitialValues, setQuestionnaireInitialValues] = useState(
    null
  );

  const [open, setOpen] = useState(false);
  const [messageTitle] = useState<string>(dict.questionnaire.answerFailedTitle);
  const [messageContent, setMessageContent] = useState<string>("");

  useEffect(() => {
    const redirect = process.env.REACT_APP_STUDENT_SITE_URL
      ? window.location.href.replace(process.env.REACT_APP_STUDENT_SITE_URL, "")
      : null;
    userState.signedIn === false &&
      props.history.push(`/login?redirect=${redirect}`);
  }, [userState, props.history]);

  useEffect(() => {
    getQuestionnaire({ questionnaireId: props.match.params.questionnaireId });
  }, []);

  useEffect(() => {
    if (questionnaire) {
      let newSchema: any = {};
      questionnaire?.items?.forEach((item: IItem) => {
        newSchema[item.name] = item.required
          ? Yup.string().required(dict.form.required)
          : Yup.string();
      });
      setQuestionnaireSchema(Yup.object().shape(newSchema));

      let newInitialValues: any = {};
      questionnaire?.items?.forEach((item: IItem) => {
        let answerValue = questionnaire?.answer
          ? questionnaire?.answer[item.name]
          : "";

        answerValue = answerValue
          ? answerValue.length > 1
            ? answerValue
            : answerValue[0]
          : "";
        newInitialValues[item.name] = answerValue;
      });
      setQuestionnaireInitialValues(newInitialValues);
    }
  }, [questionnaire]);

  const { addAttendSession } = useAddAttendSession();
  const attend = async (sessionId: string) => {
    const response = await addAttendSession({
      sessionId: sessionId,
    });
    switch (response.status) {
      case 200:
        console.log("attendance succeeded");
        break;
      default:
        console.log(response);
        break;
    }
  };

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container2}>
        {!questionnaire ? (
          <CircularProgress />
        ) : (
          <Fragment>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Paper elevation={0} className={classes.squareLeft}>
                  <Typography
                    component="h1"
                    variant="h6"
                    className={classes.mb2}
                  >
                    {questionnaire?.title}
                  </Typography>
                  <Typography
                    component="p"
                    variant="body2"
                    className={classes.lineBreak}
                  >
                    {questionnaire?.description}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Formik
              initialValues={QuestionnaireInitialValues}
              enableReinitialize
              validationSchema={QuestionnaireSchema}
              onSubmit={async (values: any, formikActions) => {
                if (window.confirm(dict.questionnaire.confirmAnswer)) {
                  formikActions.setSubmitting(true);
                  const params = new URLSearchParams(props.location.search);
                  const sessionId = params.get("session_id");
                  let newBody: any = {};
                  if (params.get("course_id")) {
                    newBody["course_id"] = params.get("course_id");
                  }
                  if (sessionId) {
                    newBody["session_id"] = sessionId;
                  }
                  newBody["items"] = {};
                  questionnaire?.items?.map((item: any) => {
                    let newValues: any = [];
                    if (item.type === "checkbox") {
                      for (let [key, value] of Object.entries(values)) {
                        console.log(key, value);
                        key.startsWith(item.name) &&
                          key !== item.name &&
                          newValues.push(key.replace(item.name, ""));
                      }
                    } else {
                      newValues.push(values[item.name]);
                    }
                    newBody["items"][item.name] = newValues;
                  });
                  const res = await putQuestionnaireAnswer({
                    questionnaireId: questionnaire?.id,
                    body: newBody,
                  });
                  switch (res.status) {
                    case 200:
                      sessionId && (await attend(sessionId));
                      props.history.push("./answer/complete");
                      break;
                    case 403:
                      setMessageContent(
                        dict.questionnaire.answerFailedMessageDuplicated
                      );
                      setOpen(true);
                      break;
                    case 400:
                    case 500:
                      setMessageContent(
                        dict.questionnaire.answerFailedMessageServerError
                      );
                      setOpen(true);
                      break;

                    default:
                      break;
                  }
                }
                formikActions.setSubmitting(false);
              }}
              render={({
                isValid,
                isSubmitting,
                submitForm,
                values,
                setFieldValue,
              }) => (
                <div className={classes.pcMarginBottom}>
                  <div className={classes.ptb2}>
                    <Typography className={classes.ml2}>
                      {dict.questionnaire.answerBelow}
                    </Typography>
                  </div>
                  <Paper elevation={0} square className={classes.prl2}>
                    <Form className={classes.mt2}>
                      <Grid
                        container
                        spacing={4}
                        className={classes.p2}
                        // alignItems="top"
                      >
                        {questionnaire?.items?.map((item: any) => {
                          return (
                            <Fragment>
                              <Grid item xs={12} className={classes.mt2}>
                                <Typography
                                  className={classes.bolder}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.name}
                                  {item.required && (
                                    <Typography
                                      variant="caption"
                                      style={{ color: "red" }}
                                    >
                                      {"　"}
                                      {dict.form.requiredShort}
                                    </Typography>
                                  )}
                                </Typography>
                              </Grid>
                              {/* <Grid item xs={12}> */}
                              {item.description && (
                                <div className={classes.ml2}>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.lineBreak}
                                  >
                                    {item.description}
                                  </Typography>
                                </div>
                              )}
                              {/* </Grid> */}
                              <Grid item xs={12}>
                                {item.type === "text" ? (
                                  <Field
                                    name={item.name}
                                    type="text"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                  />
                                ) : item.type === "textarea" ? (
                                  <Field
                                    name={item.name}
                                    type="text"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    multiline={true}
                                    rows={4}
                                  />
                                ) : item.type === "select" ? (
                                  <Field
                                    name={item.name}
                                    type="text"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    select
                                  >
                                    {item?.values.map((value: string) => (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    ))}
                                  </Field>
                                ) : item.type === "radio" ? (
                                  <Field
                                    name={item.name}
                                    type="text"
                                    variant="outlined"
                                    component={RadioGroup}
                                    fullWidth
                                  >
                                    {item?.values.map((value: string) => (
                                      <FormControlLabel
                                        value={value}
                                        label={value}
                                        checked={
                                          values
                                            ? value === values[item.name]
                                            : false
                                        }
                                        control={
                                          <Radio disabled={isSubmitting} />
                                        }
                                        disabled={isSubmitting}
                                      />
                                    ))}
                                  </Field>
                                ) : item.type === "checkbox" ? (
                                  item?.values.map((value: string) => (
                                    <Field
                                      name={`${item.name}${value}`}
                                      component={CheckboxWithLabel}
                                      Label={{
                                        label: value,
                                      }}
                                    />
                                  ))
                                ) : null}
                              </Grid>
                            </Fragment>
                          );
                        })}
                      </Grid>
                    </Form>
                    <Grid container className={classes.pb3}>
                      <Grid item xs={12} md={3}></Grid>
                      <Grid item xs={12} md={6}>
                        <Fab
                          className={classes.primaryFabMb0}
                          disabled={!isValid || isSubmitting}
                          type="submit"
                          variant="extended"
                          size="large"
                          color="secondary"
                          onClick={submitForm}
                        >
                          {dict.questionnaire.answer}
                        </Fab>
                      </Grid>
                      <Grid item xs={12} md={3}></Grid>
                    </Grid>
                  </Paper>
                  {isSubmitting && <LinearProgress />}
                </div>
              )}
            />
          </Fragment>
        )}
      </Container>
      <MessageDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={"../course"} // TODO currently url will be order/product
        actionButtonCaption={dict.questionnaire.backToMyCourse}
        history={props.history}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(QuestionnaireAnswer))
);
