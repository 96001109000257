import React, { useEffect } from "react";
import { withRouter } from "react-router";

import {
  Container,
  Fab,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

import { stylesCommon, stylesProduct } from "../../css";
import dict from "../../dictionary";
import ReactMarkdown from "react-markdown";

const ProductDetailCourses = (props: any) => {
  const { classes } = props;

  return (
    <Container maxWidth="xl" className={classes.courseContainerOuter}>
      <Container maxWidth="lg">
        <Typography
          component="h2"
          variant="h6"
          color="textSecondary"
          className={classes.containerHeader}
        >
          {dict.product.courseHeader}
        </Typography>
      </Container>
      {props.courses?.length > 0 &&
        props.courses.map((course: any, index: number) => {
          return (
            <Container maxWidth="md" className={classes.courseContainerInner}>
              <Paper
                elevation={10}
                className={eval(`classes.colorStrip${index}`)}
              >
                {props.stepHeader && (
                  <Typography
                    color="textSecondary"
                    className={classes.stepHeader}
                  >{`${props.stepHeader} ${index + 1}`}</Typography>
                )}
                <ReactMarkdown
                  linkTarget="_blank"
                  source={course.description}
                />
              </Paper>
            </Container>
          );
        })}
      {props.scheduleLink && (
        <Container maxWidth="lg">
          <Grid container className={classes.mt4}>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={6}>
              <Fab
                className={classes.primaryFabFlat2}
                // disabled={isSubmitting}
                type="submit"
                variant="extended"
                onClick={() => {
                  window.open(props.scheduleLink, "_blank");
                }}
                size="large"
                color="primary"
              >
                {dict.product.showSchedule}
              </Fab>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
          </Grid>
        </Container>
      )}
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(ProductDetailCourses))
);
