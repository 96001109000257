import { IStudentUserState } from "../../interface";
import React from "react";
import createUseContext from "constate";

const initialUserState: IStudentUserState = {
  signedIn: undefined,
  redirect: ""
};

function useUserState({ initialState = initialUserState } = {}) {
  const [userState, setUserState] = React.useState<IStudentUserState>(
    initialUserState
  );
  const switchSignedIn = (switchTo: boolean): void => {
    switchTo
      ? setUserState({ ...userState, signedIn: true })
      : setUserState({ ...userState, signedIn: false });
  };
  const setRedirect = (redirect: string): void => {
    setUserState({ ...userState, redirect: redirect });
  };
  return { userState, switchSignedIn, setRedirect };
}

const useUserStateContext = createUseContext(useUserState, value => [
  value.userState
]);

export default useUserStateContext;

export const getUrl = (): string => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  } else {
    return "";
  }
};
