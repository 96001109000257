import * as Yup from "yup";

import {
  Box,
  Container,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { stylesAuthentication, stylesCommon } from "../../css";

import MessageDialog from "../MessageDialog";
import { TextField } from "formik-material-ui";
import dict from "../../dictionary";
import firebase from "../../firebase";
import { usePutMe } from "../../fetch/me";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const Login = (props: any) => {
  const { classes } = props;
  const { userState, switchSignedIn } = useUserStateContext();
  const [open, setOpen] = useState(false);
  const [messageTitle] = useState<string>(dict.form.loginFailedTitle);
  const [messageContent, setMessageContent] = useState<string>("");
  const { putMe } = usePutMe();
  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(dict.form.email).required(dict.form.required),
    password: Yup.string()
      .min(6, dict.form.minLen6)
      .required(dict.form.required),
  });

  useEffect(() => {
    userState.signedIn && props.history.push("/");
  }, [userState, props.history]);

  async function login(email: string, password: string, setSubmitting: any) {
    try {
      const response = await firebase.login(email, password);
      // if (response.user && response.user.emailVerified) {
      if (response.user ) {
        switchSignedIn(true);
        userState.redirect
          ? props.history.push(`${userState.redirect}`)
          : props.history.push(`/`);
      } else {
        setMessageContent(dict.form.loginFailedMessageEmailNotVerified);
        setOpen(true);
        firebase.logout();
      }
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setMessageContent(dict.form.loginFailedMessageWrongPassword);
          break;
        case "auth/user-not-found":
          setMessageContent(dict.form.loginFailedMessageUserNotFound);
          break;
        default:
          setMessageContent(error.message);
      }
      setOpen(true);
    }
    setSubmitting(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={10}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values, formikActions) => {
            await login(
              values.email,
              values.password,
              formikActions.setSubmitting
            );
            await putMe({ body: { email: values.email } });
          }}
          validationSchema={LoginSchema}
          render={({ submitForm, isSubmitting }) => (
            <Fragment>
              <Box className={classes.header}>
                <Typography component="h1" variant="h6" align="center">
                  {dict.common.login}
                </Typography>
              </Box>
              <Divider />
              {isSubmitting && <LinearProgress />}

              <Form className={classes.form}>
                <Box
                  style={{
                    textAlign: "center",
                    width: "inherit",
                    marginBottom: "1.5rem",
                  }}
                >
                  {dict.common.forNonMemberPre}
                  <br />
                  <Link
                    onClick={() => props.history.push("/signup")}
                    className={classes.cursorPointer}
                  >
                    {dict.common.forNonMemberLink}
                  </Link>
                  {dict.common.forNonMemberPost}
                </Box>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="email"
                      variant="outlined"
                      label={dict.common.email}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      type="password"
                      variant="outlined"
                      label={dict.common.password}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fab
                      className={classes.primaryFab}
                      disabled={isSubmitting}
                      type="submit"
                      variant="extended"
                      onClick={submitForm}
                      size="large"
                    >
                      {dict.common.login}
                    </Fab>
                  </Grid>
                  <Divider />
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={4}
                  justify="center"
                  style={{ display: "block", textAlign: "center" }}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.link}
                  ></Typography>
                  <Typography variant="subtitle2" className={classes.link2}>
                    <Link
                      onClick={() => props.history.push("/password-reset")}
                      className={classes.cursorPointer}
                    >
                      {dict.common.forPasswordForgot}
                    </Link>
                  </Typography>
                </Grid>
              </Form>
            </Fragment>
          )}
        ></Formik>
      </Paper>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={null}
        history={null}
      />
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(Login))
);
