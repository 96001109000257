import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { withRouter } from "react-router";

import { Fab, Grid, LinearProgress, withStyles } from "@material-ui/core";

import { stylesCommon, stylesProfile } from "../../css";
import dict from "../../dictionary";
import { ThemeProvider } from "@material-ui/styles";

const OrderStudentInfoFormSubmit = (props: any) => {
  const { classes } = props;

  return (
    <Fragment>
      {props.isSubmitting && <LinearProgress />}
      <Grid container justify="center" className={classes.mt3} spacing={3}>
        <Grid item sm={3} xs={6}>
          <Fab
            className={classes.defaultFab}
            variant="extended"
            onClick={() => {
              props.history.goBack();
            }}
            size="large"
            color="default"
          >
            {dict.common.back}
          </Fab>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Fab
            className={classes.primaryFabMb0}
            disabled={
              props.isSubmitting || Object.keys(props.errors).length !== 0
            }
            type="submit"
            variant="extended"
            onClick={async event => {
              props.submitForm();
            }}
            size="large"
            color="primary"
          >
            {dict.common.next}
          </Fab>
        </Grid>
      </Grid>
    </Fragment>
  );
};

OrderStudentInfoFormSubmit.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  // setSubmitButtonClicked: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
};

export default withStyles(stylesCommon)(
  withStyles(stylesProfile)(withRouter(OrderStudentInfoFormSubmit))
);
