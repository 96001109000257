import React, { Fragment, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";

import { stylesBill, stylesCommon } from "../../css";
import dict from "../../dictionary";
import { useGetOrder } from "../../fetch/order";
import useUserStateContext from "../../userstate";
import { addTax, formatDateJa, formatPrice, nestedObjToFlat } from "../../util";

const Receipt = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { orders, getOrders } = useGetOrder();
  const [order, setOrder] = useState<any>(undefined);
  const printAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getOrders({});
  }, []);

  useEffect(() => {
    const matchOrders =
      orders.length > 0
        ? orders.filter((order: any) => {
            return order.id === props.match.params.orderId;
          })
        : [];
    matchOrders.length > 0 &&
      setOrder(nestedObjToFlat(matchOrders[0], matchOrders[0].questions));
  }, [orders]);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  const getIssueDate = () => {
    if (order) {
      const payments = order.payments.sort((paymentA: any, paymentB: any) => {
        return paymentA.paid_at < paymentB.paid_at ? 1 : -1;
      });
      return (
        payments.length > 0 &&
        formatDateJa(new Date(payments[0].paid_at), false)
      );
    }
  };

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mtb4}>
        <Grid
          container
          className={classes.mt2}
          justify="flex-end"
          ref={printAreaRef}
        >
          <Button
            aria-label="delete"
            size="large"
            variant="contained"
            className={classes.mb2}
            onClick={() => {
              if (printAreaRef.current) {
                printAreaRef.current.style.display = "none";
                window.print();
                printAreaRef.current.style.display = "flex";
              }
            }}
          >
            {dict.order.print}
          </Button>
        </Grid>
        <Paper elevation={0} square className={classes.p4}>
          <Typography align="center" variant="h5">
            {dict.order.receipt}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography>
                {dict.order.postcodeIcon}
                {order && order.receipt.postcode}
              </Typography>
              <Typography className={classes.mb2}>
                {order && order.receipt.address}
              </Typography>
              <Typography variant="h6" className={classes.bolder}>
                {order && order.receipt.name} {dict.common.samaKanji}
              </Typography>

              <Typography
                variant="h5"
                align="right"
                className={classes.priceText}
              >
                {order && formatPrice(addTax(order.price))}
              </Typography>
              <Box className={classes.mtb2}>
                <Typography className={classes.lineBreak}>
                  {dict.order.receiptMessage}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={3}>
              <Grid container justify="space-between" className={classes.mb2}>
                <Typography>{dict.order.issueDate}</Typography>
                <Typography>{getIssueDate()}</Typography>
              </Grid>
              <Typography variant="subtitle2">
                {dict.order.datamixPostcode}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixAddress1}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixAddress2}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixName}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixTel}
              </Typography>
              <Typography align="right" className={classes.stamp}>
                <img src={require("./stamp.png")} />
              </Typography>
            </Grid>
            <Typography className={classes.mb1}>{dict.order.detail}</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">{dict.order.detailName}</TableCell>
                  <TableCell align="right">{dict.order.detailPrice}</TableCell>
                  <TableCell align="right">{dict.order.detailAmount}</TableCell>
                  <TableCell align="right">
                    {dict.order.detailPriceTotal}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {order && order.product.name}
                  </TableCell>
                  <TableCell align="right">
                    {order && formatPrice(addTax(order.price))}
                  </TableCell>
                  <TableCell align="right">1</TableCell>
                  <TableCell align="right">
                    {order && formatPrice(addTax(order.price))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                    {dict.order.totalPrice}
                    {order && formatPrice(addTax(order.price))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid container className={classes.mt3}>
              <Typography className={classes.mtb2}>
                {dict.order.note}
              </Typography>
              <Box className={classes.note}></Box>
            </Grid>
            <Grid container className={classes.mt2} justify="flex-end">
              <Typography variant="caption">
                {dict.order.orderId}：{order && order.id}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesBill)(withRouter(Receipt))
);
