import { Container, Paper, Typography, withStyles } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { stylesCommon, stylesOrder } from "../../css";

import dict from "../../dictionary";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const CancelComplete = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mt4}>
        <Paper elevation={0} square className={classes.p4}>
          <Typography variant="h6" className={classes.mb2}>
            {dict.order.canceledTitle}
          </Typography>
          <Typography>{dict.order.canceledMessage}</Typography>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(CancelComplete))
);
