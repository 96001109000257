import * as Yup from "yup";

import {
  Box,
  Checkbox,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Typography,
  withStyles
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { stylesAuthentication, stylesCommon } from "../../css";

import MessageDialog from "../MessageDialog";
import { TextField } from "formik-material-ui";
import dict from "../../dictionary";
import firebase from "../../firebase";
import { usePostSignup } from "../../fetch/signup";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";
import { useLocation } from 'react-router';

function Signup(props: any) {
  const { classes } = props;
  const { userState, switchSignedIn } = useUserStateContext();
  const { postSignup } = usePostSignup();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(dict.form.email)
      .required(dict.form.required),
    password: Yup.string()
      .min(6, dict.form.minLen6)
      .required(dict.form.required)
  });

  useEffect(() => {
    userState.signedIn && props.history.push("/");
  }, [userState, props.history]);

  // URLからパラメータ情報取得
  const search = useLocation().search;

  // 広告流入情報のSalesforce連携用関数
  const sendAdInflowInfoToSf = (search: any, email: string) => {
    // Salesforceへの送信先URL
    const url_to_sf = "https://go.datamix-school.com/l/1029621/2023-10-04/6j24";

    // 必須項目
    const lastname = "_";
    const firstname = "_";
    const title = "新規会員登録";

    // UTMパラメータ取得
    const queryParams = new URLSearchParams(search);
    const utm_source = queryParams.get("utm_source");
    const utm_medium = queryParams.get("utm_medium");
    const utm_campaign = queryParams.get("utm_campaign");
    const utm_content = queryParams.get("utm_content");

    // 送信先URLの設定処理
    let send_url = url_to_sf + `?lastname=${encodeURIComponent(lastname)}&firstname=${encodeURIComponent(firstname)}&email=${encodeURIComponent(email)}&title=${encodeURIComponent(title)}`;
    if (utm_source) {
      send_url = send_url + `&utm_source=${encodeURIComponent(utm_source)}`;
    }
    if (utm_medium) {
      send_url = send_url + `&utm_medium=${encodeURIComponent(utm_medium)}`;
    }
    if (utm_campaign) {
      send_url = send_url + `&utm_campaign=${encodeURIComponent(utm_campaign)}`;
    }
    if (utm_content) {
      send_url = send_url + `&utm_content=${encodeURIComponent(utm_content)}`;
    }

    // Salesforceにデータ連携
    let element = document.createElement("iframe");
    element.src = send_url;
    element.width = "1";
    element.height = "1";
    document.body.appendChild(element);
  }

  async function signup(email: string, password: string, setSubmitting: any) {
    try {
      await firebase.auth.createUserWithEmailAndPassword(email, password);
      // userState.redirect
      //   ? postSignup({ redirect: userState.redirect.replace("/", "") })
      //   : postSignup({});
      postSignup({});

      // 広告流入情報をSalesforceに連携
      sendAdInflowInfoToSf(search, email);

      // props.history.push({
      //   pathname: `/signup/complete`,
      //   state: {
      //     message: dict.form.signupSucceededMessage
      //   }
      // });

      // 以下追記
      switchSignedIn(true)
      userState.redirect
        ? userState.redirect == `/profile`
          ? props.history.push(`/`)
          : props.history.push(`${userState.redirect}`)
        : props.history.push(`/`);

    } catch (error) {
      setMessageTitle(dict.form.signupFailedTitle);
      switch (error.code) {
        case "auth/email-already-in-use":
          error.message.includes("as another user's initial email.")
            ? setMessageContent(
                dict.form.signupFailedMessageEmailAlreadyInUseAsInitial
              )
            : setMessageContent(dict.form.signupFailedMessageEmailAlreadyInUse);
          break;
        case "auth/user-not-found":
          setMessageContent(dict.form.loginFailedMessageUserNotFound);
          break;
        default:
          setMessageContent(error.message);
      }
      setOpen(true);
    }
    setSubmitting(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={10}>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={(values, formikActions) => {
            signup(values.email, values.password, formikActions.setSubmitting);
          }}
          validationSchema={SignupSchema}
          render={({ submitForm, isSubmitting }) => (
            <Fragment>
              <Box className={classes.header}>
                <Typography component="h1" variant="h6" align="center">
                  {dict.common.signup}
                </Typography>
              </Box>
              <Divider />
              {isSubmitting && <LinearProgress />}
              <Form className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="email"
                      variant="outlined"
                      label={dict.common.email}
                      component={TextField}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      type="password"
                      variant="outlined"
                      label={dict.common.password}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>

                  <Grid container justify="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={policyAgreed}
                          onChange={(event: any) => {
                            setPolicyAgreed(event.target.checked);
                          }}
                          value={policyAgreed}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          <Link
                            href="https://datamix.co.jp/privacy-policy/"
                            target="_blank"
                          >
                            {dict.order.privacyPolicy}
                          </Link>
                          {dict.order.agree}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Fab
                      className={classes.primaryFab}
                      disabled={isSubmitting || !policyAgreed}
                      type="submit"
                      variant="extended"
                      onClick={submitForm}
                      size="large"
                    >
                      {dict.common.signup}
                    </Fab>
                  </Grid>
                  <Divider />
                </Grid>
                <Divider />

                <Grid container spacing={4} justify="center">
                  <Link
                    className={classes.link}
                    onClick={() => props.history.push("/login")}
                  >
                    {dict.common.forMember}
                  </Link>
                </Grid>
              </Form>
            </Fragment>
          )}
        ></Formik>
      </Paper>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={null}
        history={null}
      />
    </Container>
  );
}

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(Signup))
);
