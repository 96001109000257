import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  addTax,
  distinct,
  doubleToSingleArray,
  formatPrice,
  getUniqueObjects,
  renderTerm,
} from "../../util";
import {
  extractApplicationDueDate,
  renderCoursePeriod,
  renderPriceRange,
  extractCourses,
} from "./ProductDetailIkusei";
import {
  remainingsNumToMessage,
  renderApplyButton,
} from "./ProductDetailOther";
import { stylesCommon, stylesProduct } from "../../css";

import ProductDetailCourses from "./ProductDetailCourses";
import ProductDetailIkuseiScheduleDialog from "./ProductDetailIkuseiScheduleDialog";
import ProductDetailTop from "./ProductDetailTop";
import dict from "../../dictionary";
import { schoolNamesDict } from "../../../../enum";
import { useGetProducts } from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

export const compareCourses = (courseA: any, courseB: any) => {
  let comparison = 0;
  if (courseA.id > courseB.id) {
    comparison = 1;
  } else if (courseA.id < courseB.id) {
    comparison = -1;
  }
  return comparison;
};

const ProductDetailJunbi = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const {
    productsJunbi: products,
    getProducts,
    setProductsJunbi: setProducts,
  } = useGetProducts();
  const [selectedProduct, setSelectedProduct] = useState<any | undefined>(
    undefined
  );
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([]);
  const [schoolName, setSchoolName] = useState<string>("");

  const [coursesForRender, setCoursesForRender] = useState<any[]>([]);
  const [coursesRendered, setCoursesRendered] = useState(false);

  useEffect(() => {
    if (coursesRendered === false && products.length > 0) {
      setCoursesForRender(extractCourses(products));
      setCoursesRendered(true);
    }
  }, [products]);

  const extractAllCourses = (products: any) => {
    let courses: any = [];
    products.forEach((product: any) => {
      courses.push(...product.courses);
    });
    courses = courses.filter((course: any) => {
      return !course.categories.includes("Admission");
    });
    return getUniqueObjects(courses, "id").sort(compareCourses);
  };

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    getProducts({});
  }, [userState, props.history]);

  useEffect(() => {
    const school = props.match.params.school
      ? `school_${props.match.params.school}`
      : `school_tokyo`;
    const schoolNameTmp = schoolNamesDict.filter(
      (schoolNameDict: { name: string; nameJa: string }) => {
        return schoolNameDict.name === school.replace(`school_`, ``);
      }
    );
    setSchoolName(schoolNameTmp.length > 0 ? schoolNameTmp[0].nameJa : ``);
    const term = `term_${props.match.params.term}`;
    const productsFiltered = products.filter((product: any) => {
      return product.tags.includes(term) && product.tags.includes(school);
    });
    products.length !== productsFiltered.length &&
      setProducts(productsFiltered);
  }, [products]);

  const selectProduct = (courseIds: string[]) => {
    const selectedProducts = products.filter((product: any) => {
      return (
        product.courses
          .filter((course: any) => {
            return !course.categories.includes("Admission");
          })
          .map((course: any) => {
            return course.id;
          })
          .sort()
          .toString() === courseIds.sort().toString()
      );
    });
    selectedProducts.length > 0
      ? setSelectedProduct(selectedProducts[0])
      : setSelectedProduct(null);
  };

  return (
    <Fragment>
      <ProductDetailTop
        productTitle={`【${renderTerm(props.match.params.term)}】${
          dict.product.junbiTitle
        }`}
        productDescription={dict.product.junbiDescription}
        coursePeriod={products.length > 0 && renderCoursePeriod(products)}
        priceRange={products.length > 0 && renderPriceRange(products)}
        dueDate={products.length > 0 && extractApplicationDueDate(products)}
        schoolName={schoolName}
        backImage={classes.backImageJunbi}
      />

      <ProductDetailCourses
        courses={coursesForRender}
        scheduleLink={
          props.match.params.school
            ? `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_junbi_${props.match.params.term}_${props.match.params.school}.pdf`
            : `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_junbi_${props.match.params.term}.pdf`
        }
      />

      <Container maxWidth="xl" className={classes.selectionContainer}>
        <Container maxWidth="lg" className={classes.pt2}>
          <Box className={classes.mb2}>
            <Typography variant="subtitle2">
              {dict.product.selectCombination}
            </Typography>
            <Typography variant="subtitle2">
              {dict.product.subscriptionInstruction}
            </Typography>
          </Box>
          <FormControl component="fieldset" className={classes.mb2}>
            <FormGroup>
              {products.length > 0 &&
                extractAllCourses(products).map(
                  (course: any, index: number) => {
                    return (
                      <Fragment>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // checked={gilad}
                              color="primary"
                              onChange={(event: any) => {
                                let newSelectedCourseIds = [];
                                if (
                                  selectedCourseIds.includes(event.target.value)
                                ) {
                                  newSelectedCourseIds = selectedCourseIds.filter(
                                    (id: string) => {
                                      return id != event.target.value;
                                    }
                                  );
                                } else {
                                  newSelectedCourseIds = selectedCourseIds;
                                  newSelectedCourseIds.push(event.target.value);
                                }
                                selectProduct(newSelectedCourseIds);
                                setSelectedCourseIds(newSelectedCourseIds);
                              }}
                              value={course.id}
                            />
                          }
                          label={
                            course.remainings === null
                              ? `${course.name}`
                              : `${course.name}　${remainingsNumToMessage(
                                  course.remainings
                                )}`
                          }
                        />
                        {course.remainings === 0 ? (
                          course.totally_confirmed ? (
                            <Typography
                              variant="caption"
                              className={classes.ml4}
                            >
                              {dict.order.subscribable}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              className={classes.ml4}
                            >
                              {dict.order.notSubscribable}
                            </Typography>
                          )
                        ) : null}
                      </Fragment>
                    );
                  }
                )}
            </FormGroup>
          </FormControl>

          <Divider />
          {selectedProduct ? (
            <Fragment>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={2}>
                  <Typography>{dict.product.selectedProduct}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>{selectedProduct.name}</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={2}>
                  <Typography>{dict.common.totalPrice}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>
                    {formatPrice(addTax(selectedProduct.price))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.mb4}>
                    {renderApplyButton(selectedProduct, props, classes)}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </Fragment>
          ) : selectedCourseIds.length > 0 ? (
            <Fragment>
              <Typography>
                <Grid container className={classes.mt3}>
                  <Grid item xs={12} md={12} className={classes.mb3}>
                    <Typography>
                      {dict.product.combinationNotApplicable}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Fragment>
          ) : null}
        </Container>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(ProductDetailJunbi))
);
