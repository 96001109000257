import React, { Fragment, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  Container,
  LinearProgress,
  Paper,
  Typography,
  withStyles,
  Grid,
  Fab
} from "@material-ui/core";

import { stylesAuthentication, stylesCommon } from "../../css";
import Firebase from "../../firebase";
import dict from "../../dictionary";
import useUserStateContext from "../../userstate";

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(dict.form.required)
    .min(6, dict.form.minLen6)
});

const ResetPassword = (props: any) => {
  const { classes } = props;
  const [email, setEmail] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [validCode, setValidCode] = useState<boolean | null>(null);
  const [verifiedCode, setVerifiedCode] = useState<boolean | null>(null);

  useEffect(() => {
    Firebase.auth.verifyPasswordResetCode(props.actionCode).then(
      email => {
        setEmail(email);
        setValidCode(true);
        setVerifiedCode(true);
      },
      error => {
        setValidCode(false);
        setVerifiedCode(true);
      }
    );
  }, []);

  return (
    <Container maxWidth="lg" className={classes.mt4}>
      <Paper elevation={0} square className={classes.p4}>
        {success ? (
          <Fragment>
            <Typography variant="h6" className={classes.mb2}>
              {dict.resetPassword.succeededTitle}
            </Typography>
            <Typography>{dict.resetPassword.succeededMessage}</Typography>
          </Fragment>
        ) : verifiedCode && validCode ? (
          <Fragment>
            <Formik
              initialValues={{ password: "" }}
              validationSchema={PasswordSchema}
              onSubmit={(values: any) => {
                Firebase.auth
                  .confirmPasswordReset(props.actionCode, values.password)
                  .then(
                    () => {
                      setSuccess(true);
                    },
                    error => {
                      setError(error.message);
                    }
                  );
              }}
              render={({ submitForm, isSubmitting }) => (
                <Fragment>
                  <Typography variant="h6" className={classes.mb2}>
                    {dict.resetPassword.resetToNew}
                  </Typography>
                  <Form>
                    <Grid container spacing={4}>
                      {isSubmitting && <LinearProgress />}
                      <Grid item xs={12} md={8}>
                        <Field
                          name="password"
                          type="password"
                          variant="outlined"
                          label={dict.common.password}
                          component={TextField}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Fab
                          className={classes.primaryFabMb0}
                          disabled={isSubmitting}
                          type="submit"
                          variant="extended"
                          onClick={submitForm}
                          size="large"
                        >
                          {dict.resetPassword.reset}
                        </Fab>
                      </Grid>
                    </Grid>
                  </Form>
                </Fragment>
              )}
            />
          </Fragment>
        ) : verifiedCode && !validCode ? (
          <Fragment>
            <Typography variant="h6" className={classes.mb2}>
              {dict.resetPassword.failedTitle}
            </Typography>
            <Typography className={classes.lineBreak}>
              {dict.resetPassword.failedMessage}
            </Typography>
          </Fragment>
        ) : null}
      </Paper>
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(ResetPassword))
);
