import React from "react";
import { withRouter } from "react-router-dom";

import { Button, Paper, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";

import firebase from "../firebase";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

function Dashboard(props: any) {
  const { classes } = props;

  if (!firebase.getCurrentUser()) {
    // not logged in
    alert("Please login first");
    props.history.replace("/login");
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const [quote, setQuote] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     firebase.getCurrentUserQuote().then(setQuote);
  //   });

  return (
    <main className={classes.main}>
      <Paper className={classes.paper} elevation={10}>
        <Typography component="h1" variant="h5">
          Hello {firebase.getCurrentUsername()}
        </Typography>
        {/* <Typography component="h1" variant="h5">
          Your quote: {quote ? `"${quote}"` : <CircularProgress size={20} />}
        </Typography> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="default"
          onClick={logout}
          className={classes.submit}
        >
          Logout
        </Button>
      </Paper>
    </main>
  );

  async function logout() {
    await firebase.logout();
    props.history.push("/");
  }
}

export default withStyles(styles)(withRouter(Dashboard));
