import {
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  addTax,
  doubleToSingleArray,
  formatDate,
  formatPrice,
  renderTerm,
} from "../../util";
import {
  remainingsNumToMessage,
  renderApplyButton,
} from "./ProductDetailOther";
import { stylesCommon, stylesProduct } from "../../css";

import ProductDetailCourses from "./ProductDetailCourses";
import ProductDetailTop from "./ProductDetailTop";
import dict from "../../dictionary";
import { schoolNamesDict } from "../../../../enum";
import { useGetProducts } from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

// TODO how to display when E-learning ?
export const renderCoursePeriod = (products: any): string => {
  let openAts = products.map((product: any) =>
    product.courses.map((course: any) =>
      course.sessions.map((session: any) => session.start_at)
    )
  );
  openAts = doubleToSingleArray(openAts);
  openAts = doubleToSingleArray(openAts);
  openAts = openAts
    .map((open_at: string) => {
      return Date.parse(open_at);
    })
    .sort((a: number, b: number) => {
      return a > b ? 1 : -1;
    });

  let endAts = products.map((product: any) =>
    product.courses.map((course: any) =>
      course.sessions.map((session: any) => session.end_at)
    )
  );
  endAts = doubleToSingleArray(endAts);
  endAts = doubleToSingleArray(endAts);
  endAts = endAts
    .map((end_at: string) => {
      return Date.parse(end_at);
    })
    .sort((a: number, b: number) => {
      return a > b ? 1 : -1;
    });

  if (openAts[0] === undefined || endAts[0] === undefined) {
    return ``;
  }

  const firstDate = formatDate(new Date(openAts[0]));
  const lastDate = formatDate(new Date(endAts[endAts.length - 1]));

  return firstDate === lastDate ? `${firstDate}` : `${firstDate} ~ ${lastDate}`;
};

export const renderPriceRange = (products: any): string => {
  const prices = products
    .map((product: any) => {
      return product.price;
    })
    .sort((a: number, b: number) => {
      return a > b ? 1 : -1;
    });
  const minimumPrice = formatPrice(addTax(prices[0]));
  const maximumPrice = formatPrice(addTax(prices[prices.length - 1]));
  return `${minimumPrice} ~ ${maximumPrice}`;
};

export const compareCourses = (courseA: any, courseB: any) => {
  let comparison = 0;
  // if course has sessions
  if (courseA?.sessions && courseB?.sessions) {
    const firstSessionA = courseA.sessions.sort(
      (sessionA: any, sessionB: any) => {
        return (
          new Date(sessionA.start_at).getTime() -
          new Date(sessionB.start_at).getTime()
        );
      }
    )[0];
    const firstSessionB = courseB.sessions.sort(
      (sessionA: any, sessionB: any) => {
        return (
          new Date(sessionA.start_at).getTime() -
          new Date(sessionB.start_at).getTime()
        );
      }
    )[0];
    if (firstSessionA?.start_at && firstSessionB?.start_at) {
      comparison =
        new Date(firstSessionA.start_at).getTime() -
        new Date(firstSessionB.start_at).getTime();
    }
    // if course has no sessions (online)
  } else {
    comparison = courseA.id - courseB.id;
  }
  return comparison;
};

export const extractCourses = (products: any) => {
  const productWithMostCourses = products.sort((a: any, b: any) => {
    return a.courses.length < b.courses.length ? 1 : -1;
  })[0];
  return (
    productWithMostCourses &&
    productWithMostCourses.courses
      .filter((course: any) => {
        return !course.categories.includes("Admission");
      })
      .sort(compareCourses)
  );
};

export const extractApplicationDueDate = (products: any) => {
  const productWithEarliestDate = products.sort((a: any, b: any) => {
    return new Date(a.close_at).getTime() - new Date(b.close_at).getTime();
  })[0];

  return formatDate(new Date(productWithEarliestDate.close_at));
};

const ProductDetailDSIkusei = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const {
    productsDSIkusei: products,
    getProducts,
    typesDSIkusei: types,
    weekdaysDSIkusei: weekdays,
    setProductsDSIkusei: setProducts,
  } = useGetProducts();
  const [
    selectedType,
    setSelectedType
  ] = useState<string | undefined>(undefined);
  const [
    selectedWeekday,
    setSelectedWeekday
  ] = useState<string | undefined>(undefined);
  const [
    selectedProduct,
    setSelectedProduct
  ] = useState<any | undefined>(undefined);
  const [filteredWeekdays, setFilteredWeekdays] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [schoolName, setSchoolName] = useState<string>("");
  const [productDescription, setProductDescription] = useState<any>("");

  const [coursesForRender, setCoursesForRender] = useState<any[]>([]);
  const [coursesRendered, setCoursesRendered] = useState(false);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    getProducts({});
  }, [userState, props.history]);

  useEffect(() => {
    const term = `term_${props.match.params.term}`;
    const school = props.match.params.school
      ? `school_${props.match.params.school}`
      : `school_tokyo`;
    const schoolNameTmp = schoolNamesDict.filter(
      (schoolNameDict: { name: string; nameJa: string }) => {
        return schoolNameDict.name === school.replace(`school_`, ``);
      }
    );
    setSchoolName(schoolNameTmp.length > 0 ? schoolNameTmp[0].nameJa : ``);
    const productsFiltered = products.filter((product: any) => {
      return product.tags.includes(term) && product.tags.includes(school);
    });
    products.length !== productsFiltered.length &&
      setProducts(productsFiltered);
    products.length > 0 && setProductDescription(products[0].description);
  }, [products]);

  useEffect(() => {
    if (coursesRendered === false && products.length > 0) {
      setCoursesForRender(extractCourses(products));
      setCoursesRendered(true);
    }
  }, [products]);

  // when multiple terms run parallelly, weekdays and types need to be
  // filtered for particular term shown in the page.
  useEffect(() => {
    if (products.length > 0) {
      const typesFiltered = types.filter((type: any) => {
        let typeExists = false;
        products.forEach((product: any) => {
          typeExists = product.tags.includes(type.value) ? true : typeExists;
        });
        return typeExists;
      });
      setFilteredTypes(typesFiltered);
    }
  }, [types]);

  useEffect(() => {
    if (products.length > 0) {
      const weekdaysFiltered = weekdays.filter((weekday: any) => {
        let weekdayExists = false;
        products.forEach((product: any) => {
          weekdayExists = product.tags.includes(weekday.value)
            ? true
            : weekdayExists;
        });
        return weekdayExists;
      });
      setFilteredWeekdays(weekdaysFiltered);
    }
  }, [weekdays]);

  useEffect(() => {
    if (selectedType && selectedWeekday) {
      selectProduct(selectedType, selectedWeekday);
    }
  }, [selectedType, selectedWeekday]);

  const selectProduct = (type: string, weekday: string) => {
    const selectedProductArray = products.filter((product: any) => {
      return product.tags.includes(type) && product.tags.includes(weekday);
    });
    selectedProductArray.length > 0
      ? setSelectedProduct(selectedProductArray[0])
      : setSelectedProduct(undefined);
  };

  return (
    <Fragment>
      <ProductDetailTop
        productTitle={`【${renderTerm(props.match.params.term)}】${
          dict.product.dsikuseiTitle
        }`}
        productDescription={productDescription}
        coursePeriod={products.length > 0 && renderCoursePeriod(products)}
        priceRange={products.length > 0 && renderPriceRange(products)}
        dueDate={products.length > 0 && extractApplicationDueDate(products)}
        schoolName={schoolName}
        backImage={classes.backImageIkusei}
      />

      <ProductDetailCourses
        courses={coursesForRender}
        stepHeader={"Step"}
        scheduleLink={
          props.match.params.school
            ? `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_ds_ikusei_${props.match.params.term}_${props.match.params.school}.pdf`
            : `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_ds_ikusei_${props.match.params.term}.pdf`
        }
      />

      <Container maxWidth="xl" className={classes.selectionContainer}>
        <Container maxWidth="lg" className={classes.pt2}>
          <Box className={classes.mb2}>
            <Typography variant="subtitle2">
              {dict.product.applicationInstruction}
            </Typography>
          </Box>
          <Grid container className={classes.mb4}>
            <Grid item xs={12} md={2}>
              <Typography className={classes.mt1}>
                {dict.product.type}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">{dict.product.type}</FormLabel> */}
                <RadioGroup
                  value={selectedType}
                  onChange={(event: any) => {
                    setSelectedType(event.target.value);
                  }}
                >
                  {filteredTypes &&
                    filteredTypes.map((type: any) => {
                      return (
                        <FormControlLabel
                          value={type.value}
                          control={<Radio color="primary" />}
                          label={type.label}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2} className={classes.mb1}>
              <Typography className={classes.mt1}>
                {dict.product.weekday}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">{dict.product.weekday}</FormLabel> */}
                <RadioGroup
                  value={selectedWeekday}
                  onChange={(event: any) => {
                    setSelectedWeekday(event.target.value);
                  }}
                >
                  {filteredWeekdays &&
                    filteredWeekdays.map((weekday: any) => {
                      return (
                        <Fragment>
                          <FormControlLabel
                            value={weekday.value}
                            control={<Radio color="primary" />}
                            label={weekday.label}
                          />
                          {products.map((product: any) => {
                            if (product.tags.includes(selectedType) === true) {
                              if (
                                product.tags.includes(weekday.value) === true
                              ) {
                                return (
                                  <Typography className={classes.ml4}>
                                    {remainingsNumToMessage(product.remainings)}
                                  </Typography>
                                );
                              }
                            }
                          })}
                        </Fragment>
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          {selectedProduct && (
            <Fragment>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={2}>
                  <Typography>{dict.product.selectedProduct}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>{selectedProduct.name}</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={2}>
                  <Typography>{dict.common.totalPrice}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>
                    {formatPrice(addTax(selectedProduct.price))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.mb4}>
                    {renderApplyButton(selectedProduct, props, classes)}
                    {selectedProduct.remainings == 0 &&
                      !selectedProduct.totally_confirmed && (
                        <Box className={classes.mt1}>
                          <Typography variant="caption">
                            {dict.order.subscribeMessage}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </Fragment>
          )}
        </Container>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(ProductDetailDSIkusei))
);
