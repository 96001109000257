import React, { useEffect, useState } from "react";

import RecoverEmail from "./RecoverEmail";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import { withRouter } from "react-router";

const Auth = (props: any) => {
  const [mode, setMode] = useState<string | null>(null);
  const [actionCode, setActionCode] = useState<string | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    setMode(params.get("mode"));
    setActionCode(params.get("oobCode"));
    setRedirect(params.get("continueUrl"));
  }, []);

  return mode === "recoverEmail" ? (
    <RecoverEmail actionCode={actionCode} />
  ) : mode === "resetPassword" ? (
    <ResetPassword actionCode={actionCode} />
  ) : mode === "verifyEmail" ? (
    <VerifyEmail actionCode={actionCode} redirect={redirect} />
  ) : null;
};

export default withRouter(Auth);
