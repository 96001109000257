import React, { Fragment, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";

import { stylesBill, stylesCommon } from "../../css";
import dict from "../../dictionary";
import { useGetOrder } from "../../fetch/order";
import useUserStateContext from "../../userstate";
import { addTax, formatDateJa, formatPrice, nestedObjToFlat } from "../../util";

const Invoice = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { orders, getOrders } = useGetOrder();
  const [order, setOrder] = useState<any>(undefined);
  const printAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getOrders({});
  }, []);

  useEffect(() => {
    const matchOrders =
      orders.length > 0
        ? orders.filter((order: any) => {
            return order.id === props.match.params.orderId;
          })
        : [];
    matchOrders.length > 0 &&
      setOrder(nestedObjToFlat(matchOrders[0], matchOrders[0].questions));
  }, [orders]);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  const getIssueDate = () => {
    if (order) {
      switch (order.prepayment) {
        case true:
          return formatDateJa(new Date(order.ordered_at), false);
        case false:
          return formatDateJa(new Date(order.confirmed_at), false);
        default:
          break;
      }
    }
  };

  const getDueDate = () => {
    if (order) {
      switch (order.prepayment) {
        case true:
          return formatDateJa(new Date(order.product.close_at), false);
        case false:
          let confirmedAt = new Date(order.confirmed_at);
          const nextMonthEndDate = new Date(
            confirmedAt.getFullYear(),
            confirmedAt.getMonth() + 2,
            0
          );
          return formatDateJa(nextMonthEndDate, false);
        default:
          break;
      }
    }
  };

  const getNote = () => {
    if (order) {
      switch (order.prepayment) {
        case true:
          return dict.order.noteContentPrepaymentInvoice;
        case false:
          return dict.order.noteContentPostpaymentInvoice;
        default:
          break;
      }
    }
  };

  const getMonth = (order: any) => {
    if (!order?.product?.courses || order.product.courses.length === 0) {
      return null;
    }

    const longTermCourses: { [key: string]: number } = {
      ブートキャンプ: 2,
      ベーシック: 2,
      アドバンス: 2,
      インテグレーション: 1,
    };

    return order.product.courses.reduce(
      (acc: number, course: { code: string }) => {
        for (const key in longTermCourses) {
          if (course?.code?.includes(key)) {
            return acc + longTermCourses[key];
          }
        }
        return acc;
      },
      0
    );
  };

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mtb4}>
        <Grid
          container
          className={classes.mt2}
          justify="flex-end"
          ref={printAreaRef}
        >
          <Button
            aria-label="delete"
            size="large"
            variant="contained"
            className={classes.mb2}
            onClick={() => {
              if (printAreaRef.current) {
                printAreaRef.current.style.display = "none";
                window.print();
                printAreaRef.current.style.display = "flex";
              }
            }}
          >
            {dict.order.print}
          </Button>
        </Grid>
        <Paper elevation={0} square className={classes.p4}>
          <Typography align="center" variant="h5">
            {dict.order.invoice}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography>
                {dict.order.postcodeIcon}
                {order && order.invoice.postcode}
              </Typography>
              <Typography className={classes.mb2}>
                {order && order.invoice.address}
              </Typography>
              <Typography variant="h6" className={classes.bolder}>
                {order && order.invoice.name} {dict.common.samaKanji}
              </Typography>
              <Box className={classes.mtb2}>
                <Typography className={classes.lineBreak}>
                  {dict.order.invoiceMessage}
                </Typography>
              </Box>
              <Typography
                variant="h5"
                align="right"
                className={classes.priceText}
              >
                {order && formatPrice(addTax(order.price))}
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={3}>
              <Grid container justify="space-between" className={classes.mb1}>
                <Typography>{dict.order.issueDate}</Typography>
                <Typography>{getIssueDate()}</Typography>
              </Grid>
              <Grid container justify="space-between" className={classes.mb2}>
                <Typography>{dict.order.dueDate}</Typography>
                <Typography>{getDueDate()}</Typography>
              </Grid>
              <Typography variant="subtitle2">
                {dict.order.datamixPostcode}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixAddress1}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixAddress2}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixName}
              </Typography>
              <Typography variant="subtitle2">
                {dict.order.datamixTel}
              </Typography>
              <Typography align="right" className={classes.stamp}>
                <img src={require("./stamp.png")} />
              </Typography>
            </Grid>
            <Typography className={classes.mb1}>{dict.order.detail}</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">{dict.order.detailName}</TableCell>
                  <TableCell align="right">{dict.order.detailPrice}</TableCell>
                  <TableCell align="right">{dict.order.detailAmount}</TableCell>
                  <TableCell align="right">
                    {dict.order.detailPriceTotal}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {order && order.product.name}{" "}
                    {(() => {
                      const month = getMonth(order);
                      return month != null && month > 0
                        ? `(${month}${dict.order.invoiceMonth})`
                        : "";
                    })()}
                  </TableCell>
                  <TableCell align="right">
                    {order && formatPrice(addTax(order.price))}
                  </TableCell>
                  <TableCell align="right">1</TableCell>
                  <TableCell align="right">
                    {order && formatPrice(addTax(order.price))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                    {dict.order.totalPrice}
                    {order && formatPrice(addTax(order.price))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{dict.order.tenPercentTarget}</TableCell>
                  <TableCell align="right">
                    {order && formatPrice(order.price)}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {dict.order.tax}{" "}
                    {order && formatPrice(addTax(order.price) - order.price)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid container className={classes.mt3}>
              <Typography className={classes.mtb2}>
                {dict.order.note}
              </Typography>
              <Box className={classes.note}>{getNote()}</Box>
            </Grid>
            <Grid container className={classes.mt2} justify="flex-end">
              <Typography variant="caption">
                {dict.order.orderId}：{order && order.id}
              </Typography>
            </Grid>
            <Grid container className={classes.mt2} justify="flex-end">
              <Typography variant="caption">
                {dict.order.invoiceRegistrationNumber}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesBill)(withRouter(Invoice))
);
