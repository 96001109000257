import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";

import {
  Container,
  LinearProgress,
  Paper,
  Typography,
  withStyles,
  Button
} from "@material-ui/core";

import { stylesAuthentication, stylesCommon } from "../../css";
import Firebase from "../../firebase";
import dict from "../../dictionary";

const RecoverEmail = (props: any) => {
  const { classes } = props;
  const [message, setMessage] = useState<string>(dict.verifyEmail.verifying);

  const [restoredEmail, setRestoredEmail] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [resetSent, setResetSent] = useState<boolean | null>(null);
  const [validCode, setValidCode] = useState<boolean | null>(null);
  const [verifiedCode, setVerifiedCode] = useState<boolean | null>(null);

  useEffect(() => {
    Firebase.auth.checkActionCode(props.actionCode).then(
      info => {
        // Get the restored email address.
        const restoredEmail = info["data"]["email"];
        // Revert to the old email.
        Firebase.auth.applyActionCode(props.actionCode).then(() => {
          // Account email reverted to restoredEmail
          setRestoredEmail(restoredEmail);
          setValidCode(true);
          setVerifiedCode(true);
        });
      },
      error => {
        setError(error.message);
        setValidCode(false);
        setVerifiedCode(true);
      }
    );
  }, []);

  const sendReset = () => {
    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    Firebase.auth.sendPasswordResetEmail(restoredEmail).then(() => {
      // Password reset confirmation sent. Ask user to check their email.
      setResetSent(true);
    });
  };

  return (
    <Container maxWidth="lg" className={classes.mt4}>
      <Paper elevation={0} square className={classes.p4}>
        {!verifiedCode ? null : resetSent ? (
          <Fragment>
            <Typography variant="h6" className={classes.mb2}>
              {dict.recoverEmail.checkEmailTitle}
            </Typography>
            <Typography>{dict.recoverEmail.checkEmailMessage}</Typography>
            <Typography>{restoredEmail}</Typography>
          </Fragment>
        ) : verifiedCode && validCode ? (
          <Fragment>
            <Typography variant="h6" className={classes.mb2}>
              {dict.recoverEmail.emailUpdatedTitle}
            </Typography>
            <Typography className={classes.lineBreak}>
              {dict.recoverEmail.emailUpdatedMessage}
            </Typography>
          </Fragment>
        ) : verifiedCode && !validCode ? (
          <Fragment>
            <Typography variant="h6" className={classes.mb2}>
              {dict.recoverEmail.emailUpdateFailedTitle}
            </Typography>
            <Typography className={classes.lineBreak}>
              {dict.recoverEmail.emailUpdateFailedMessage}
            </Typography>
          </Fragment>
        ) : null}
      </Paper>
    </Container>
  );
};
export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(RecoverEmail))
);
