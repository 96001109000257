import React, { Fragment, useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router";
import { Box, Container, Fab, Typography, withStyles } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import useUserStateContext from "../../userstate";
import { useGetMe } from "../../fetch/me";
import { stylesCommon, stylesProduct } from "../../css";
import { googleform, salesformUrl } from "../../constants";

type formInfoType = {
    tag: string
    name: string
    url: string
    width: string
    height: string
}

const procedure = `
### ご予約は以下の手順でお進めください。

1. カレンダー上のご希望の日程を選択

2. 表示されたフォームに必要事項を入力（**※メールアドレスはマイページ登録アドレスをご入力ください。**）

3. フォーム上の「予約」ボタンをクリック

4. 予約が確定したら、画面右上の「完了」をクリック（**※ 予約が完了するまではクリックしないでください。**）

`

const sendAdInflowInfoToSf = (
    search: any,
    me: any,
    title: string,
) => {
    // URL params
    const queryParams = new URLSearchParams(search);
    const utmSource = queryParams.get("utm_source");
    const utmMedium = queryParams.get("utm_medium");
    const utmCampaign = queryParams.get("utm_campaign");
    const utmContent = queryParams.get("utm_content");

    //user info
    const lastname = me?.lastname ? me.lastname : "_"
    const firstname = me?.firstname ? me.firstname : "_"
    const email = me?.email ? me.email : "placeholder@datamix.co.jp"

    // sendQuery
    const userQuery = `?lastname=${encodeURIComponent(lastname)}&firstname=${encodeURIComponent(firstname)}&email=${encodeURIComponent(email)}&title=${encodeURIComponent(title)}`
    const utmSourceQuery = utmSource ? `&utm_source=${encodeURIComponent(utmSource)}`: ""
    const utmMediumQuery = utmMedium ? `&utm_medium=${encodeURIComponent(utmMedium)}`: ""
    const utmCampaignQuery = utmCampaign ? `&utm_campaign=${encodeURIComponent(utmCampaign)}`: ""
    const utmContentQuery = utmContent ? `&utm_content=${encodeURIComponent(utmContent)}`: ""

    const sendUrl = `${salesformUrl}${userQuery}${utmSourceQuery}${utmMediumQuery}${utmCampaignQuery}${utmContentQuery}`

    // info to Salesforce
    const element = document.createElement("iframe");
    element.src = "salesforce";
    element.src = sendUrl;
    element.width = "1";
    element.height = "1";
    document.body.appendChild(element);
}

const GoogleForm = (props: {
    formInfo: formInfoType,
    eventHandler: () => void,
    classes: any
}) => {
    const { formInfo, eventHandler } = props
    return (
        <div onLoad={eventHandler}>
            <iframe
                id="googleForm"
                src={formInfo.url}
                width={formInfo.width}
                height={formInfo.height}
                frameBorder={"0"}
                marginHeight={0}
                marginWidth={0}
            >
                読み込んでいます…
            </iframe>
        </div>
    )
}

const GoogleCalendar = (props: {
    formInfo: formInfoType,
    eventHandler: () => void,
    classes: any
}) => {
    const { formInfo, eventHandler, classes } = props
    return (
        <Container maxWidth="lg" className={classes.container}>
            <ReactMarkdown source={procedure} />
            <Box
                display="flex"
                justifyContent="flex-end"
            >
                <Fab
                    className={classes.primaryFabFlat}
                    variant="extended"
                    size="medium"
                    color="primary"
                    type="submit"
                    onClick={eventHandler}
                >
                    {"完了"}
                </Fab>
            </Box>
            <iframe
                id="googleCalendar"
                src={formInfo.url}
                width={formInfo.width}
                height={formInfo.height}
                frameBorder={"0"}
                marginHeight={0}
                marginWidth={0}
            >
                読み込んでいます…
            </iframe>
        </Container>
    )
}


const ProductGoogleTools = (props: any) => {
    const { classes } = props;
    const { userState, setRedirect } = useUserStateContext();
    const { me, getMe } = useGetMe();

    const [isFirst, setIsFirst] = useState(true);
    const search = useLocation().search;
    const tag: string = props.match.params.freeType;

    const formInfo = googleform.filter((item) => {
        return item.tag === tag
    })[0]

    const redirectForm = () => {
        if (isFirst) {
            setIsFirst(false);
            return;
        }
        sendAdInflowInfoToSf(search, me, formInfo.name);
        props.history.push({
            pathname: `/order/completed`,
            state: {
                product: formInfo,
                previous: "OrderCreate",
            },
        });
    };
    const redirectCalendar = () => {
        sendAdInflowInfoToSf(search, me, formInfo.name);
        props.history.push({
            pathname: `/order/completed`,
            state: {
                product: formInfo,
                previous: "OrderCreate",
            },
        });
    }

    useEffect(() => {
        // if (userState.signedIn === false) {
        //     setRedirect(window.location.pathname);
        //     props.history.push("/login");
        // } else {
        //     getMe({})
        // }
        if (userState.signedIn === true) {
            getMe({})
        }
    }, [userState, props.history]);

    return (
        <Fragment>
            <Container
                maxWidth="lg"
                className={classes.container2}
            >
                {formInfo ?
                    <Box>
                        <Typography
                            component="h1"
                            variant="h6"
                            className={classes.mb2}
                        >
                            {formInfo.name}
                        </Typography>
                        {formInfo.type === "calendar" ?
                            <GoogleCalendar
                                formInfo={formInfo}
                                eventHandler={redirectCalendar}
                                classes={classes}
                            /> :
                            <GoogleForm
                                formInfo={formInfo}
                                eventHandler={redirectForm}
                                classes={classes}
                            />
                        }
                    </Box>
                : <Box>{"URLをお確かめください"}</Box>
                }
            </Container>
        </Fragment>
    )
}

export default withStyles(stylesCommon)(
    withStyles(stylesProduct)(withRouter(ProductGoogleTools))
);
  