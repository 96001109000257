import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  Box,
  Container,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  withStyles
} from "@material-ui/core";

import { stylesAuthentication, stylesCommon } from "../../css";
import dict from "../../dictionary";
import firebase, { actionCodeSettings } from "../../firebase";
import MessageDialog from "../MessageDialog";
import useUserStateContext from "../../userstate";

const VerificationExpired = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>(
    dict.form.verificationExpiredFailedTitle
  );
  const [messageContent, setMessageContent] = useState<string>("");
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [actionButtonCaption, setActionButtonCaption] = useState<string | null>(
    null
  );

  const handleClose = () => {
    setOpen(false);
  };

  const VerificationExpiredSchema = Yup.object().shape({
    email: Yup.string()
      .email(dict.form.email)
      .required(dict.form.required),
    password: Yup.string()
      .min(6, dict.form.minLen6)
      .required(dict.form.required)
  });

  useEffect(() => {
    userState.signedIn && props.history.push("/");
  }, [userState, props.history]);

  async function resendVerification(
    email: string,
    password: string,
    setSubmitting: any
  ) {
    try {
      await firebase.login(email, password);
      if (firebase.auth.currentUser) {
        if (firebase.auth.currentUser.emailVerified) {
          setNextUrl("/");
          setActionButtonCaption(dict.common.toTop);
          setMessageTitle(dict.form.verificationExpiredFailedTitle);
          setMessageContent(
            dict.form.verificationExpiredFailedMessageAlreadyVerified
          );
        } else {
          firebase.auth.currentUser.sendEmailVerification(actionCodeSettings);
          setNextUrl(null);
          setActionButtonCaption(null);
          setMessageTitle(dict.verificationExpired.succeededTitle);
          setMessageContent(dict.verificationExpired.succeededMessage);
        }
      }
      setOpen(true);
    } catch (error) {
      setMessageTitle(dict.form.verificationExpiredFailedTitle);
      switch (error.code) {
        case "auth/wrong-password":
          setMessageContent(dict.form.loginFailedMessageWrongPassword);
          break;
        case "auth/user-not-found":
          setMessageContent(dict.form.loginFailedMessageUserNotFound);
          break;
        default:
          setMessageContent(error.message);
      }
      setNextUrl(null);
      setActionButtonCaption(null);
      setOpen(true);
    }
    setSubmitting(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper} elevation={10}>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={(values, formikActions) => {
            resendVerification(
              values.email,
              values.password,
              formikActions.setSubmitting
            );
          }}
          validationSchema={VerificationExpiredSchema}
          render={({ submitForm, isSubmitting }) => (
            <Fragment>
              <Box className={classes.header}>
                <Typography component="h1" variant="h6" align="center">
                  {dict.common.verificationExpired}
                </Typography>
              </Box>
              <Divider />
              {isSubmitting && <LinearProgress />}
              <Form className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography component="p">
                      {dict.verificationExpired.message1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="email"
                      variant="outlined"
                      label={dict.common.email}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      type="password"
                      variant="outlined"
                      label={dict.common.password}
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fab
                      className={classes.primaryFabFlat2}
                      disabled={isSubmitting}
                      type="submit"
                      variant="extended"
                      onClick={submitForm}
                      size="large"
                      color="primary"
                    >
                      {dict.common.receiveMail}
                    </Fab>
                  </Grid>
                  <Divider />
                </Grid>
              </Form>
            </Fragment>
          )}
        ></Formik>
      </Paper>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={nextUrl}
        actionButtonCaption={actionButtonCaption}
        history={props.history}
      />
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(VerificationExpired))
);
