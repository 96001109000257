import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { withRouter } from "react-router";

import { Fab, Grid, LinearProgress, withStyles } from "@material-ui/core";

import { stylesCommon, stylesProfile } from "../../css";
import dict from "../../dictionary";

const ProfileStudentInfoFormSubmit = (props: any) => {
  const { classes } = props;

  return (
    <Fragment>
      {props.isSubmitting && <LinearProgress />}
      <Grid container justify="center" className={classes.mt3}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6} xs={12}>
          <Fab
            className={classes.primaryFabMb0}
            disabled={props.isSubmitting || !props.isValid}
            type="submit"
            variant="extended"
            onClick={event => {
              props.setSubmitButtonClicked(true);
              props.submitForm();
            }}
            size="large"
            color="primary"
          >
            {dict.common.save}
          </Fab>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
    </Fragment>
  );
};

ProfileStudentInfoFormSubmit.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  setSubmitButtonClicked: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired
};

export default withStyles(stylesCommon)(
  withStyles(stylesProfile)(withRouter(ProfileStudentInfoFormSubmit))
);
