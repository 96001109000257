import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import App from "./components/App";

const tagManagerArgs = {
  gtmId: "GTM-ND9M7C5",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
