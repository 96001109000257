import {
  Container,
  LinearProgress,
  Paper,
  Typography,
  withStyles,
  Link,
  Button,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { stylesAuthentication, stylesCommon } from "../../css";

import Firebase from "../../firebase";
import dict from "../../dictionary";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const VerifyEmail = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const [message, setMessage] = useState<string>(dict.verifyEmail.verifying);
  const [
    showVerificationExpiredLink,
    setShowVerificationExpiredLink,
  ] = useState<boolean>(false);

  useEffect(() => {
    Firebase.auth.applyActionCode(props.actionCode).then(
      () => {
        setMessage(dict.verifyEmail.succeeded);
        window.location.href = props.redirect && props.redirect;
      },
      (error) => {
        setMessage(dict.verifyEmail.failed);
        setShowVerificationExpiredLink(true);
      }
    );
  }, []);

  return (
    <Container maxWidth="lg" className={classes.mt4}>
      {!message && <LinearProgress />}
      <Paper elevation={0} square className={classes.p4}>
        <Typography className={classes.lineBreak}>{message}</Typography>
        {showVerificationExpiredLink && (
          <Typography className={classes.mt1}>
            <Link
              onClick={() =>
                props.history.push({ pathname: "/verification-expired" })
              }
            >
              {dict.verifyEmail.verificationExpiredLink}
            </Link>
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesAuthentication)(withRouter(VerifyEmail))
);
