import {
  Box,
  Container,
  Fab,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { addTax, formatDate, formatPrice } from "../../util";
import { stylesCommon, stylesProduct } from "../../css";

import ProductDetailCourses from "./ProductDetailCourses";
import ProductDetailTop from "./ProductDetailTop";
import dict from "../../dictionary";
import { findSchoolName } from "./ProductList";
import { renderCoursePeriod } from "./ProductDetailIkusei";
import { useGetProducts } from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

export const renderApplyButton = (product: any, props: any, classes: any) => {
  return product && !product.ordered ? (
    product.remainings <= 0 && product.remainings != null ? (
      <Fab
        className={classes.primaryFabFlat2}
        disabled
        type="submit"
        variant="extended"
        size="large"
        color={"secondary"}
      >
        {dict.product.noRemainings}
      </Fab>
    ) : (
      <Fab
        className={classes.primaryFabMb0}
        type="submit"
        variant="extended"
        onClick={(event) => {
          props.history.push({
            pathname: `/order/basic-info`,
            state: { product: product && product },
          });
        }}
        size="large"
        color={"primary"}
      >
        {dict.order.goToStudentInfo}
      </Fab>
    )
  ) : (
    <Fab
      className={classes.primaryFabFlat2}
      disabled={true}
      type="submit"
      variant="extended"
      onClick={(event) => {
        props.history.push({
          pathname: `/order/basic-info`,
          state: { product: product && product },
        });
      }}
      size="large"
      color="secondary"
    >
      {dict.order.ordered}
    </Fab>
  );
};

export const remainingsNumToMessage = (remainings: number) => {
  return remainings > 3
    ? dict.product.remainingsPlenty
    : remainings > 0
    ? dict.product.remainingsFew
    : dict.product.remainings0;
};

const ProductDetailOther = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { productsOther, getProducts } = useGetProducts();
  const [product, setProduct] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    getProducts({});
  }, [userState, props.history]);

  useEffect(() => {
    const matchProducts = productsOther
      ? productsOther.filter((product: any) => {
          return product.id === props.match.params.productId;
        })
      : [];
    matchProducts.length > 0 && setProduct(matchProducts[0]);
  }, [productsOther]);

  const renderRemainings = () => {
    if (product) {
      switch (product.remainings) {
        case null:
          return null;
        case 0:
          return null;
        default:
          return (
            <Grid container className={classes.mt3}>
              <Grid item xs={12} md={2}>
                <Typography>{dict.product.remainings}</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>
                  {remainingsNumToMessage(product.remainings)}
                </Typography>
              </Grid>
            </Grid>
          );
      }
    }
  };

  const term = product?.tags
    .find((tag: string) => tag.startsWith("term_"))
    ?.replace("term_", "");

  return (
    <Fragment>
      <ProductDetailTop
        productTitle={product && product.name}
        productDescription={product && product.description}
        coursePeriod={
          product && [product].length > 0 && renderCoursePeriod([product])
        }
        priceRange={product && formatPrice(addTax(product.price))}
        dueDate={product && formatDate(new Date(product.close_at))}
        backImage={classes.backImageOther}
        schoolName={
          product?.tags.filter((tag: string) => {
            return tag.startsWith("school_");
          }).length > 0 &&
          findSchoolName(
            product.tags.filter((tag: string) => {
              return tag.startsWith("school_");
            })[0]
          )
        }
      />

      {product && (
        <ProductDetailCourses
          courses={product?.courses}
          // https://datamix-jp.slack.com/archives/CKQNNMPT4/p1611324036012000
          // https://datamix-jp.slack.com/archives/CKQNNMPT4/p1615172403002700
          scheduleLink={
            product?.tags.find((tag: string) => tag === "marketing_manager") &&
            product?.tags.find((tag: string) => tag === "school_tokyo")
              ? `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_marketing_manager${
                  term ? "_" + term : ""
                }_tokyo.pdf`
              : null
          }
        />
      )}

      {product && (
        <Container maxWidth="xl" className={classes.selectionContainer}>
          <Container maxWidth="lg">
            <Fragment>
              <Grid container className={classes.pt2}>
                <Grid item xs={12} md={2}>
                  <Typography>{dict.order.productName}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>{product && product.name}</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={2}>
                  <Typography>{dict.common.totalPrice}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>
                    {formatPrice(addTax(product && product.price))}
                  </Typography>
                </Grid>
              </Grid>
              {renderRemainings()}
              <Grid container className={classes.mt3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.mb4}>
                    {renderApplyButton(product, props, classes)}
                    {product.remainings == 0 &&
                      !product.totally_confirmed &&
                      !product.ordered && (
                        <Box className={classes.mt1}>
                          <Typography variant="caption">
                            {dict.order.subscribeMessage}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </Fragment>
          </Container>
        </Container>
      )}
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(ProductDetailOther))
);
