import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router";
import {
    Box,
    Container,
    Paper,
    Typography,
    withStyles
} from "@material-ui/core";
// import useUserStateContext from "../../userstate";
import OrderStepper from "./OrderStepper";
import OrderStepperFree from "./OrderStepperFree";
import dict from "../../dictionary";
import { stylesCommon, stylesOrder } from "../../css";

interface MyWindow extends Window {
    dataLayer: any;
}
declare var window: MyWindow;

const OrderCompleted = (props: any) => {
    const { classes } = props;
    // const { userState, setRedirect } = useUserStateContext();

    // useEffect(() => {
    //     if (userState.signedIn === false) {
    //       setRedirect(window.location.pathname);
    //       props.history.push("/login");
    //     }
    // }, [userState, props.history]);

    window.dataLayer.push({
        productName: props?.location?.state?.product?.name,
    });

    return (
        <Fragment>
            {/* Not show if coming from order create page which is redirect page after signup */}
            {props.location.state.previous != "OrderCreate" && (
                <Box className={classes.box}>
                    <Container maxWidth="lg">
                    {props.location.state.product.price === 0 ? (
                        <OrderStepperFree activeStep={2} />
                    ) : (
                        <OrderStepper activeStep={3} />
                    )}
                    </Container>
                </Box>
            )}
            <Container maxWidth="lg" className={classes.mt4}>
                <Paper elevation={0} square className={classes.p4}>
                <Typography
                    variant="h6"
                    className={classes.mb2}
                    id={props.location.state.product.name}
                >
                    {dict.order.thankYou}
                </Typography>
                <Typography>{dict.order.thankYouMessage}</Typography>
                </Paper>
            </Container>
        </Fragment>
    )
}

export default withStyles(stylesCommon)(
    withStyles(stylesOrder)(withRouter(OrderCompleted))
);
  