import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography
} from "@material-ui/core";

import dict from "../../dictionary";
import { useModifyOrderInvoice } from "../../fetch/order";

const InvoiceModifyDialog = (props: {
  open: boolean;
  handleClose: any;
  history: any | null;
  orderId: string | null;
  order: any | null;
}) => {
  const InvoiceSchema = Yup.object().shape({
    name: Yup.string().required(dict.form.required),
    postcode: Yup.string(),
    address: Yup.string()
  });

  const { modifyOrderInvoice } = useModifyOrderInvoice();

  return (
    <Formik
      initialValues={{ name: "", postcode: "", address: "" }}
      enableReinitialize
      validationSchema={InvoiceSchema}
      onSubmit={async values => {
        await modifyOrderInvoice({ orderId: props.orderId, invoice: values });
        props.history.push(`../invoice/${props.orderId}`);
      }}
      render={({ submitForm, values, isValid, isSubmitting }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title">
              {dict.order.invoiceDownload}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography>
                  {dict.order.invoiceDownloadInstruction1}
                </Typography>
                <Typography>
                  {dict.order.invoiceDownloadInstruction2}
                </Typography>
                <Typography>
                  {dict.order.invoiceDownloadInstruction3}
                </Typography>
              </DialogContentText>
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label={dict.order.name}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="postcode"
                      label={dict.order.postcode}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="address"
                      label={dict.order.address}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            {isSubmitting && <LinearProgress />}
            <DialogActions>
              <Button onClick={props.handleClose} color="default">
                {dict.common.cancel}
              </Button>
              <Button
                onClick={submitForm}
                color="default"
                disabled={isSubmitting || !isValid}
              >
                {dict.order.saveAndDownload}
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

InvoiceModifyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object,
  orderId: PropTypes.string.isRequired,
  order: PropTypes.object
};

export default InvoiceModifyDialog;
