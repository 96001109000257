import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import { StepConnector } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import { StepIconProps } from "@material-ui/core/StepIcon";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";

import dict from "../../dictionary";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 32px)",
    right: "calc(50% + 32px)"
  },
  active: {
    "& $line": {
      borderColor: "#02B3E4"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#02B3E4"
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#02B3E4"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  completed: {
    color: "#02B3E4",
    zIndex: 1,
    fontSize: 18
  }
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const OrderStepper = (props: any) => {
  return (
    <Stepper
      activeStep={props.activeStep}
      alternativeLabel
      connector={<QontoConnector />}
    >
      <Step key="1">
        <StepLabel StepIconComponent={QontoStepIcon}>
          {dict.order.studentInfo}
        </StepLabel>
      </Step>
      <Step key="2">
        <StepLabel StepIconComponent={QontoStepIcon}>
          {dict.order.orderInfo}
        </StepLabel>
      </Step>
      <Step key="3">
        <StepLabel StepIconComponent={QontoStepIcon}>
          {dict.order.confirm}
        </StepLabel>
      </Step>
    </Stepper>
  );
};

OrderStepper.propTypes = {
  activeStep: PropTypes.number.isRequired
};

export default OrderStepper;
