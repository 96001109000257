import jaLocale from "date-fns/locale/ja";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { stylesCommon, stylesProfile } from "../../css";
import dict from "../../dictionary";
import { useGetMe, usePutMe } from "../../fetch/me";
import MessageDialog from "../MessageDialog";
import OrderStudentInfoFormSubmit from "../order/OrderStudentInfoFormSubmit";
import ProfileStudentInfoFormSubmit from "./ProfileStudentInfoFormSubmit";

const StudentInfoForm = (props: any) => {
  const { classes } = props;
  const { me, getMe } = useGetMe();
  const { putMe } = usePutMe();
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const MeSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(dict.form.required),
    last_name: Yup.string()
      .required(dict.form.required),
    first_name_kana: Yup.string()
      .required(dict.form.required)
      .matches(/^([ァ-ン]|ー)+$/, dict.form.kanaOnly),
    last_name_kana: Yup.string()
      .required(dict.form.required)
      .matches(/^([ァ-ン]|ー)+$/, dict.form.kanaOnly),
    gender: Yup.string()
      .required(dict.form.required),
    birth_date: Yup.date()
      .required(dict.form.required),
    tel_mobile_phone: Yup.string()
      .required(dict.form.required)
      .matches(/^\d{11}$/, dict.form.telMobileOnly),
    name_emergency: Yup.string()
      .required(dict.form.required),
    tel_emergency: Yup.string()
      .required(dict.form.required)
      .matches(/^\d{10}$|^\d{11}$/, dict.form.telOnly),
    postcode: Yup.string()
      .required(dict.form.required),
    address: Yup.string()
      .required(dict.form.required),
    subscription: Yup.object(),
    company: Yup.object().shape({
      name: Yup.string(),
      tel: Yup.string()
        .notRequired()
        .matches(/^$|^\d{10}$|^\d{11}$/, dict.form.telOnly),
      address: Yup.string(),
    }),
  });
  console.log(MeSchema);
  useEffect(() => {
    getMe({});
  }, []);

  function isInitialValid(props: any) {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  }

  return (
    <Formik
      // isInitialValid={isInitialValid}
      initialValues={{
        first_name: me.first_name ? me.first_name : "",
        last_name: me.last_name ? me.last_name : "",
        first_name_kana: me.first_name_kana ? me.first_name_kana : "",
        last_name_kana: me.last_name_kana ? me.last_name_kana : "",
        gender: me.gender ? me.gender : "",
        birth_date: me.birth_date ? new Date(me.birth_date) : null,
        tel_mobile_phone: me.tel_mobile_phone ? me.tel_mobile_phone : "",
        name_emergency: me.name_emergency ? me.name_emergency : "",
        tel_emergency: me.tel_emergency ? me.tel_emergency : "",
        postcode: me.postcode ? me.postcode : "",
        address: me.address ? me.address : "",
        subscription: me.subscription ? me.subscription : {},
        company: me.company ? me.company : { name: "", tel: "", address: "" },
      }}
      enableReinitialize
      validationSchema={MeSchema}
      onSubmit={async (values) => {
        const response = await putMe({ body: values });
        if (
          response.status === 200 &&
          props.parentName === "ProfileStudentInfo"
        ) {
          getMe({});
          // window.location.reload(); // FIXME form will be kept disabled after fab clicked without fields not updated thus forcing reload as of now
        } else if (
          response.status === 200 &&
          props.parentName === "OrderStudentInfo"
        ) {
          props.location.state.product.price === 0
            ? props.history.push({
                pathname: `/order/confirm`,
                state: { product: props.location.state.product, me: values },
              })
            : props.history.push({
                pathname: `/order/order-info`,
                state: { product: props.location.state.product, me: values },
              });
        }
      }}
      render={({
        submitForm,
        values,
        setFieldValue,
        errors,
        isValid,
        isInitialValid,
        isSubmitting,
      }) => (
        <Fragment>
          <Form className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Field
                  name="last_name"
                  label={dict.basicInfo.lastName}
                  type="text"
                  variant="outlined"
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="first_name"
                  label={dict.basicInfo.firstName}
                  type="text"
                  variant="outlined"
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="last_name_kana"
                  label={dict.basicInfo.lastNameKana}
                  type="text"
                  variant="outlined"
                  fullWidth
                  component={TextField}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="first_name_kana"
                  label={dict.basicInfo.firstNameKana}
                  type="text"
                  variant="outlined"
                  fullWidth
                  component={TextField}
                />
              </Grid>
            </Grid>
            <FormControl component="fieldset" className={classes.formControl}>
              <Grid container xs={12}>
                <Grid
                  container
                  xs={3}
                  alignItems="center"
                  className={classes.p2}
                >
                  <FormLabel component="label">
                    {dict.basicInfo.gender}
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    className={classes.radioGroup}
                    onChange={(event: any) => {
                      setFieldValue(
                        "gender",
                        (event.target as HTMLInputElement).value
                      );
                    }}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio color="primary" />}
                      label={dict.basicInfo.female}
                      checked={values.gender === "female" && true}
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio color="primary" />}
                      label={dict.basicInfo.male}
                      checked={values.gender === "male" && true}
                    />
                    {errors.gender && submitButtonClicked && (
                      <FormHelperText error={true}>
                        {errors.gender}
                      </FormHelperText>
                    )}
                  </RadioGroup>
                </Grid>
              </Grid>
            </FormControl>
            <Grid item xs={12} className={classes.mb3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                <DatePicker
                  disableFuture
                  autoOk
                  variant="dialog"
                  format="yyyy年MM月dd日"
                  inputVariant="outlined"
                  openTo="year"
                  label={dict.basicInfo.birthDate}
                  value={values.birth_date}
                  onChange={(date: any) => {
                    setFieldValue("birth_date", date);
                  }}
                  name="birth_date"
                  fullWidth
                  initialFocusedDate={new Date("1990-01-01T09:00:00.000Z")}
                  cancelLabel={dict.common.cancel}
                  okLabel={dict.common.ok}
                  disableToolbar
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  name="tel_mobile_phone"
                  label={
                    dict.basicInfo.telMobilePhone + dict.basicInfo.noHyphen
                  }
                  type="text"
                  variant="outlined"
                  fullWidth
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="name_emergency"
                  label={dict.basicInfo.nameEmergency}
                  type="text"
                  variant="outlined"
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="tel_emergency"
                  label={dict.basicInfo.telEmergency + dict.basicInfo.noHyphen}
                  type="text"
                  variant="outlined"
                  component={TextField}
                  fullWidth
                />
              </Grid>

              <Grid
                container
                spacing={4}
                alignItems="center"
                className={classes.p2}
              >
                <Grid item xs={7} md={6}>
                  <Field
                    name="postcode"
                    label={dict.order.postcode}
                    type="string"
                    variant="outlined"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={5} md={3}>
                  <Button
                    variant="contained"
                    color="default"
                    size="large"
                    onClick={async () => {
                      let postcode = values.postcode.replace(
                        /[Ａ-Ｚａ-ｚ０-９]/g,
                        function (s: any) {
                          return String.fromCharCode(s.charCodeAt(0) - 65248);
                        }
                      );
                      postcode = postcode.replace("-", "");
                      postcode = postcode.replace("ー", "");
                      await fetch(
                        `https://api.zipaddress.net/?zipcode=${postcode}`
                      )
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (resJson) {
                          const resCode = resJson.code;
                          setMessageTitle(dict.order.fetchAddressFailedTitle);
                          switch (resCode) {
                            case 200:
                              setFieldValue(
                                "address",
                                resJson.data.fullAddress
                              );
                              break;
                            case 400:
                              setMessageContent(
                                dict.order.fetchAddressFailedMessage400
                              );
                              setOpen(true);
                              break;
                            case 404:
                              setMessageContent(
                                dict.order.fetchAddressFailedMessage404
                              );
                              setOpen(true);
                              break;
                            default:
                              setMessageContent(resJson.message);
                              setOpen(true);
                          }
                        });
                    }}
                  >
                    {dict.order.searchAddress}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.mt4}>
              <Field
                name="address"
                // label={dict.common.address}
                label={dict.order.address}
                type="text"
                variant="outlined"
                fullWidth
                component={TextField}
              />
              <FormHelperText className={classes.textPrimary}>
                {dict.basicInfo.addressInstruction}
              </FormHelperText>
            </Grid>
            <FormLabel component="label" className={classes.formLabel}>
              {dict.basicInfo.companyInfo}
            </FormLabel>
            <Grid item xs={12} className={classes.mt3}>
              <Field
                name="company.name"
                label={dict.basicInfo.companyName}
                type="text"
                variant="outlined"
                fullWidth
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} className={classes.mt4}>
              <Field
                name="company.tel"
                label={dict.basicInfo.companyTel}
                type="text"
                variant="outlined"
                fullWidth
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} className={classes.mt4}>
              <Field
                name="company.address"
                label={dict.basicInfo.companyAddress}
                type="text"
                variant="outlined"
                fullWidth
                component={TextField}
              />
            </Grid>
            {/* <FormControl
              component="fieldset"
              margin="normal"
              className={classes.formControl}
            >
              <Grid container xs={12}>
                <Grid
                  container
                  xs={3}
                  // alignItems="center"
                  className={classes.p2}
                >
                  <FormLabel component="legend">
                    {dict.order.emailOptIn}
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <FormGroup>
                    {mailMagazines.map(
                      (mailMagazine: { name: string; title: string }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.subscription[mailMagazine.name] ===
                                    true && true
                                }
                                onChange={(event: any) => {
                                  let newSubscription = values.subscription;
                                  newSubscription[mailMagazine.name] =
                                    event.target.checked;
                                  setFieldValue(
                                    "subscription",
                                    newSubscription
                                  );
                                }}
                                value={mailMagazine.name}
                                color="primary"
                              />
                            }
                            label={mailMagazine.title}
                          />
                        );
                      }
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
            </FormControl> */}
            {props.parentName === "ProfileStudentInfo" ? (
              <ProfileStudentInfoFormSubmit
                isSubmitting={isSubmitting}
                submitForm={submitForm}
                setSubmitButtonClicked={setSubmitButtonClicked}
                isValid={isValid}
                errors={errors}
              />
            ) : props.parentName === "OrderStudentInfo" ? (
              <Fragment>
                <OrderStudentInfoFormSubmit
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  // setSubmitButtonClicked={setSubmitButtonClicked}
                  isValid={isValid}
                  errors={errors}
                />
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Form>
          <MessageDialog
            open={open}
            handleClose={handleClose}
            messageTitle={messageTitle}
            messageContent={messageContent}
            nextUrl={null}
            actionButtonCaption={null}
            history={null}
          />
        </Fragment>
      )}
    />
  );
};

StudentInfoForm.propTypes = {
  parentName: PropTypes.string.isRequired,
};

export default withStyles(stylesCommon)(
  withStyles(stylesProfile)(withRouter(StudentInfoForm))
);
