import {
  Card,
  CardContent,
  Container,
  Divider,
  Fab,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { formatDate, formatPrice, nestedObjToFlat } from "../../util";
import { stylesCommon, stylesList } from "../../css";

import dict from "../../dictionary";
import { useGetOrder } from "../../fetch/order";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

export const getOrderStatus = (order: any) => {
  const orderFlat = nestedObjToFlat(order, order.questions);
  const canceled = orderFlat.canceled_at ? true : false;
  const confirmed = orderFlat.confirmed_at ? true : false;
  const prepayment = orderFlat.prepayment ? true : false;

  if (canceled) {
    return dict.order.canceled;
  }
  if (confirmed) {
    return dict.order.confirmed;
  }
  if (prepayment) {
    return dict.order.paymentWaited;
  } else {
    return dict.order.evaluationWaited;
  }
};

const OrderList = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { orders, getOrders } = useGetOrder();

  useEffect(() => {
    getOrders({});
  }, []);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.listContainerInner}>
        <Grid container className={classes.mb4}>
          <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h6" className={classes.mb2}>
              {dict.order.orderListTitle}
            </Typography>
          </Grid>
          {orders.length > 0 &&
            orders.map((order: any) => {
              return (
                <Card className={classes.card}>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={8} className={classes.taLeft}>
                        <Typography
                          // gutterBottom
                          variant="h6"
                          color="textSecondary"
                          className={classes.bolder}
                        >
                          {order.product.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.taRight}>
                        {order.product.price > 0 && (
                          <Typography color="primary">
                            {getOrderStatus(order)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent className={classes.p3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={10} className={classes.taLeft}>
                        <Typography
                          color="textSecondary"
                          className={classes.mtb1}
                        >
                          {dict.order.orderedDate}：
                          {formatDate(new Date(order.ordered_at))}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          className={classes.mtb1}
                        >
                          {dict.common.totalPrice}：
                          {formatPrice(order.price + order.tax)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2} className={classes.taRight}>
                        <Fab
                          className={classes.primaryFabFlat2}
                          type="submit"
                          variant="extended"
                          onClick={() => {
                            props.history.push({
                              pathname: `/order-detail/${order.id}`,
                            });
                          }}
                          size="large"
                          color="primary"
                        >
                          {dict.common.detail}
                        </Fab>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesList)(withRouter(OrderList))
);
