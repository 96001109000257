import {
  ButtonBase,
  Card,
  CardContent,
  Chip,
  Grid,
  Hidden,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { addTax, formatDate, formatPrice, renderTerm } from "../../util";
import {
  extractApplicationDueDate,
  renderCoursePeriod,
  renderPriceRange,
} from "./ProductDetailIkusei";
import { stylesCommon, stylesProductCard } from "../../css";

import PropTypes from "prop-types";
import dict from "../../dictionary";
import { findSchoolName } from "./ProductList";
import { withRouter } from "react-router";

const ProductCard = (props: any) => {
  const { classes } = props;
  const [dueDate, setDueDate] = useState<string>("");
  const [priceRange, setPriceRange] = useState<string>("");
  const [coursePeriod, setCoursePeriod] = useState<string>("");

  useEffect(() => {
    if (props.products.length > 0) {
      setDueDate(extractApplicationDueDate(props.products));
      setPriceRange(renderPriceRange(props.products));
      setCoursePeriod(renderCoursePeriod(props.products));
    } else if (props.product) {
      setDueDate(formatDate(new Date(props.product.close_at)));
      setPriceRange(formatPrice(addTax(props.product.price)));
      setCoursePeriod(renderCoursePeriod([props.product]));
    }
  }, [props.products]);

  return (
    <ButtonBase
      className={classes.fullWidth}
      onClick={() => {
        switch (props.href) {
          case "ikusei":
          case "weather":
          case "hr":
          case "pda":
          case "de":
          case "mle":
          case "ds-ikusei":
          case "ds-weather":
          case "junbi":
            props.history.push({
              pathname: `/product/${props.href}/${props.term.replace(
                "term_",
                ""
              )}/${props.school.replace("school_", "")}`,
            });
            break;

          default:
            props.history.push({
              pathname: `/product/${props.href}`,
            });
            break;
        }
      }}
    >
      <Card className={classes.w100}>
        <CardContent className={classes.p3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={9} className={classes.taLeft}>
              <Typography
                gutterBottom
                variant="h6"
                color="textSecondary"
                className={classes.bolder}
              >
                {props.term && `【${renderTerm(props.term)}】`}
                {props.productName}
                {props.product?.tags.filter((tag: string) => {
                  return tag.startsWith(`school_`);
                }).length > 0 ? (
                  <Chip
                    label={findSchoolName(
                      props.product.tags.filter((tag: string) => {
                        return tag.startsWith(`school_`);
                      })[0]
                    )}
                    className={`${classes.ml2} ${classes.chipPlace}`}
                  />
                ) : (
                  props.school && (
                    <Chip
                      label={findSchoolName(props.school)}
                      className={`${classes.ml2} ${classes.chipPlace}`}
                    />
                  )
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.taRight}>
              <Typography>{priceRange}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className={classes.taLeft}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                className={classes.description}
              >
                {props.description}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} className={classes.taRight} spacing={2}>
              <Hidden mdUp>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.taLeft}
                  spacing={2}
                >
                  <Typography color="textSecondary">
                    {dict.common.applicationDueDate}
                  </Typography>
                </Grid>
              </Hidden>
              <Typography color="primary" className={classes.mt1}>
                <Hidden smDown>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    className={classes.mr2}
                  >
                    {dict.common.applicationDueDate}
                  </Typography>
                </Hidden>
                {dueDate}
              </Typography>

              {coursePeriod && (
                <Fragment>
                  <Hidden mdUp>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.taLeft}
                      spacing={2}
                    >
                      <Typography color="textSecondary">
                        {dict.common.opening}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Typography color="primary" className={classes.mt1}>
                    <Hidden smDown>
                      <Typography
                        color="textSecondary"
                        display="inline"
                        className={classes.mr2}
                      >
                        {dict.common.opening}
                      </Typography>
                    </Hidden>
                    {coursePeriod}
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  products: PropTypes.array,
  productName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  term: PropTypes.string,
};

export default withStyles(stylesCommon)(
  withStyles(stylesProductCard)(withRouter(ProductCard))
);
