import Firebase from "../firebase";

const useFetch = (method: string): any => {
  const execute = async ({ url, body }: { url: string; body?: object }) => {
    const token = await Firebase.getToken();
    let res = null;

    switch (method) {
      case "GET":
        res = await fetch(url, {
          method: method,
          headers: { Authorization: "Bearer " + token }
        });
        const getResponseJson = await res.json();
        return getResponseJson;
      case "POST":
        res = await fetch(url, {
          method: method,
          headers: { Authorization: "Bearer " + token },
          body: JSON.stringify(body)
        });
        return res;
      case "PUT":
        res = await fetch(url, {
          method: method,
          headers: { Authorization: "Bearer " + token },
          body: JSON.stringify(body)
        });
        return res;
      case "DELETE":
        res = await fetch(url, {
          method: method,
          headers: { Authorization: "Bearer " + token }
        });
        return res;
      default:
        console.log("other");
    }
  };

  return { execute };
};

export default useFetch;
