import {
  Box,
  Container,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { stylesCommon, stylesOrder } from "../../css";

import OrderStepper from "./OrderStepper";
import OrderStepperFree from "./OrderStepperFree";
import StudentInfoForm from "../profile/StudentInfoForm";
import dict from "../../dictionary";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const OrderStudentInfo = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    !props.location.state && props.history.push("/");
  }, [userState, props.history]);

  return (
    <Fragment>
      <Box className={classes.box}>
        <Container maxWidth="lg">
          {props.location.state.product.price === 0 ? (
            <OrderStepperFree activeStep={0} />
          ) : (
            <OrderStepper activeStep={0} />
          )}
        </Container>
      </Box>
      <Container maxWidth="lg" className={classes.mb4}>
        <Typography variant="h6" className={classes.p2}>
          {dict.order.studentInfo}
        </Typography>
        <Paper elevation={0} square>
          <StudentInfoForm parentName="OrderStudentInfo" />
        </Paper>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(OrderStudentInfo))
);
