import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  Checkbox,
  CircularProgress,
  Container,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

import { stylesCommon, stylesProduct } from "../../css";
import dict from "../../dictionary";
import { useGetMe, usePutMe } from "../../fetch/me";
import { usePostOrder } from "../../fetch/order";
import { useGetProducts } from "../../fetch/product";
import { usePostSignup } from "../../fetch/signup";
import firebase from "../../firebase";
import useUserStateContext from "../../userstate";
import { formatTime } from "../../util";
import MessageDialog from "../MessageDialog";
import { orderFreeProduct } from "../OrderCreate";
import { remainingsNumToMessage } from "./ProductDetailOther";
import { useLocation } from 'react-router';

const ProductDetailFree = (props: any) => {
  const { classes } = props;
  const { userState, switchSignedIn } = useUserStateContext();
  const { productsAll, getProducts } = useGetProducts();
  const [selectedProduct, setSelectedProduct] = useState<any | undefined>(
    undefined
  );
  const [products, setProducts] = useState<any[] | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const { postSignup } = usePostSignup();
  const { putMe } = usePutMe();
  const { me, getMe } = useGetMe();
  const { postOrder } = usePostOrder();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>(
    dict.order.orderFailedTitle
  );
  const [messageContent, setMessageContent] = useState<string>("");
  const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);

  useEffect(() => {
    getProducts({});
  }, []);

  useEffect(() => {
    userState.signedIn && getMe({});
    userState.signedIn && setPolicyAgreed(true);
  }, [userState]);

  useEffect(() => {
    const matchProducts = productsAll
      ? productsAll
          .filter((product: any) => {
            let tagMatches = false;
            product.tags.forEach((tag: string) => {
              tagMatches =
                tag === `free_${props.match.params.freeType}`
                  ? true
                  : tagMatches;
            });
            return tagMatches;
          })
          .sort((productA: any, productB: any) => {
            return (
              new Date(productA.courses[0]?.sessions[0]?.start_at).getTime() -
              new Date(productB.courses[0]?.sessions[0]?.end_at).getTime()
            );
          })
      : [];
    setProducts(matchProducts);
  }, [productsAll]);

  useEffect(() => {
    products?.length === 1 && setSelectedProduct(products[0]);
  }, [products]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    product: any
  ) => {
    setSelectedProduct(product);
    setSelectedIndex(index);
  };

  const FreeProductNewStudentSchema = Yup.object().shape({
    email: Yup.string().email(dict.form.email).required(dict.form.required),
    password: Yup.string()
      .min(6, dict.form.minLen6)
      .required(dict.form.required),
    first_name: Yup.string().required(dict.form.required),
    last_name: Yup.string().required(dict.form.required),
    first_name_kana: Yup.string()
      .required(dict.form.required)
      .matches(/^([ァ-ン]|ー)+$/, dict.form.kanaOnly),
    last_name_kana: Yup.string()
      .required(dict.form.required)
      .matches(/^([ァ-ン]|ー)+$/, dict.form.kanaOnly),
  });

  const FreeProductSchema = Yup.object().shape({
    first_name: Yup.string().required(dict.form.required),
    last_name: Yup.string().required(dict.form.required),
    first_name_kana: Yup.string()
      .required(dict.form.required)
      .matches(/^([ァ-ン]|ー)+$/, dict.form.kanaOnly),
    last_name_kana: Yup.string()
      .required(dict.form.required)
      .matches(/^([ァ-ン]|ー)+$/, dict.form.kanaOnly),
  });

  const initialValuesNewStudent = {
    email: me?.email ? me.email : "",
    password: "",
    first_name: "",
    last_name: "",
    first_name_kana: "",
    last_name_kana: "",
  };

  const initialValues = {
    first_name: me?.first_name ? me.first_name : "",
    last_name: me?.last_name ? me.last_name : "",
    first_name_kana: me?.first_name_kana ? me.first_name_kana : "",
    last_name_kana: me?.last_name_kana ? me.last_name_kana : "",
  };

  // URLからパラメータ情報取得
  const search = useLocation().search;

  // 広告流入情報のSalesforce連携用関数
  const sendAdInflowInfoToSf = (
    search: any,
    lastname: string,
    firstname: string,
    email: string,
    title: string,
  ) => {
    // Salesforceへの送信先URL
    const url_to_sf = "https://go.datamix-school.com/l/1029621/2023-10-04/6j24";

    // UTMパラメータ取得
    const queryParams = new URLSearchParams(search);
    const utm_source = queryParams.get("utm_source");
    const utm_medium = queryParams.get("utm_medium");
    const utm_campaign = queryParams.get("utm_campaign");
    const utm_content = queryParams.get("utm_content");

    // 送信先URLの設定処理
    let send_url = url_to_sf + `?lastname=${encodeURIComponent(lastname)}&firstname=${encodeURIComponent(firstname)}&email=${encodeURIComponent(email)}&title=${encodeURIComponent(title)}`;
    if (utm_source) {
      send_url = send_url + `&utm_source=${encodeURIComponent(utm_source)}`;
    }
    if (utm_medium) {
      send_url = send_url + `&utm_medium=${encodeURIComponent(utm_medium)}`;
    }
    if (utm_campaign) {
      send_url = send_url + `&utm_campaign=${encodeURIComponent(utm_campaign)}`;
    }
    if (utm_content) {
      send_url = send_url + `&utm_content=${encodeURIComponent(utm_content)}`;
    }

    // Salesforceにデータ連携
    let element = document.createElement("iframe");
    element.src = send_url;
    element.width = "1";
    element.height = "1";
    document.body.appendChild(element);
  }

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container2}>
        {products?.length === 0 ? (
          <CircularProgress />
        ) : (
          <Grid container>
            <Grid item xs={12} md={12}>
              <Paper elevation={0} className={classes.squareLeft}>
                <Typography component="h1" variant="h6" className={classes.mb2}>
                  {products && products.length > 0 && products[0].name}
                </Typography>
                <ReactMarkdown
                  source={
                    products && products.length > 0 && products[0].description
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
      {products && products.length > 0 && (
        <Container maxWidth="lg">
          <Typography className={classes.mtb2}>
            {dict.product.selectFromBelow}
          </Typography>
          <Paper elevation={0} square className={classes.prl2}>
            <List component="nav" aria-label="secondary mailbox folders">
              {products.length > 0 &&
                products.map((product: any, index) => {
                  return (
                    <Fragment>
                      <ListItem
                        button
                        selected={selectedIndex === index}
                        className={classes.mtb1}
                        onClick={(event) =>
                          handleListItemClick(event, index, product)
                        }
                        disabled={product.remainings === 0}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justify="center"
                          spacing={2}
                        >
                          <Grid item xs={12} md={6}>
                            <Typography>{product.courses[0]?.name}</Typography>
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.taRight}>
                            {product.courses[0]?.sessions[0] && (
                              <Fragment>
                                <Typography>
                                  {`${formatTime(
                                    new Date(
                                      product.courses[0]?.sessions[0]?.start_at
                                    )
                                  )} 〜 ${formatTime(
                                    new Date(
                                      product.courses[0]?.sessions[0]?.end_at
                                    )
                                  )}`}
                                </Typography>
                                {product.courses[0]?.sessions[0]?.room && (
                                  <Typography variant="caption" display="block">
                                    {dict.common.place}：
                                    {
                                      product.courses[0]?.sessions[0]?.room
                                        ?.name
                                    }
                                    <Typography
                                      variant="caption"
                                      display="inline"
                                    >
                                      {"　"}
                                      {remainingsNumToMessage(
                                        product.remainings
                                      )}
                                    </Typography>
                                  </Typography>
                                )}
                              </Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Fragment>
                  );
                })}
            </List>
          </Paper>
          <Formik
            initialValues={
              userState.signedIn ? initialValues : initialValuesNewStudent
            }
            enableReinitialize
            validationSchema={
              userState.signedIn
                ? FreeProductSchema
                : FreeProductNewStudentSchema
            }
            isInitialValid={() =>
              userState.signedIn
                ? FreeProductSchema.isValidSync(initialValues)
                : false
            }
            onSubmit={
              async (values: any, formikActions) => {
                const operationFail = () => {
                  setMessageTitle(dict.form.signupFailedTitle);
                  setMessageContent(dict.order.orderFailedMessageServerError);
                  formikActions.setSubmitting(false);
                  setOpen(true);
                };

                if (userState.signedIn === true) {
                  // When User Logged in
                  const responsePutMe = await putMe({ body: values });
                  if (responsePutMe.status === 200) {
                    orderFreeProduct({
                      product: selectedProduct,
                      postOrder: postOrder,
                      props: props,
                      setMessageContent: setMessageContent,
                      setOpen: setOpen,
                      formikActions: formikActions,
                    });

                    // 広告流入情報をSalesforceに連携
                    const email = firebase.auth.currentUser?.email;
                    if (email) {
                      sendAdInflowInfoToSf(
                        search,
                        values.last_name,
                        values.first_name,
                        email,
                        products[0].name,
                      );
                    }
                  } else {
                    operationFail();
                  }
                } else if (userState.signedIn === false) {
                  // When User Logged out
                  // Create user on firebase then process signup and me.
                  // Delete the user if any issue happens in the process (rollback)
                  try {
                    await firebase.auth.createUserWithEmailAndPassword(
                      values.email,
                      values.password
                    );
                    // const responseSignup: any = await postSignup({
                    //   redirect: `order/create/${selectedProduct.id}`,
                    // });
                    const responseSignup: any = await postSignup({}); // 追記
                    switchSignedIn(true);

                    if (responseSignup.status != 201) {
                      operationFail();
                      await firebase.auth.currentUser?.delete();
                    }
                    const responsePutMe = await putMe({
                      body: {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        first_name_kana: values.first_name_kana,
                        last_name_kana: values.last_name_kana,
                      },
                    });
                    if (responsePutMe.status === 200) {
                      // 広告流入情報をSalesforceに連携
                      sendAdInflowInfoToSf(
                        search,
                        values.last_name,
                        values.first_name,
                        values.email,
                        products[0].name,
                      );

                      orderFreeProduct({
                        product: selectedProduct,
                        postOrder: postOrder,
                        props: props,
                        setMessageContent: setMessageContent,
                        setOpen: setOpen,
                        formikActions: formikActions,
                      });

                      // props.history.push({
                      //   // pathname: `/signup/complete`,
                      //   pathname: `/order/create/${selectedProduct.id}`,
                      //   state: {
                      //     message: dict.form.signupOrderFreeSucceededMessage,
                      //   },
                      // });
                    } else {
                      operationFail();
                      await firebase.auth.currentUser?.delete();
                    }
                  } catch (error) {
                    setMessageTitle(dict.form.signupFailedTitle);
                    switch (error.code) {
                      case "auth/email-already-in-use":
                        error.message.includes("as another user's initial email.")
                          ? setMessageContent(
                              dict.form
                                .signupFailedMessageEmailAlreadyInUseAsInitial
                            )
                          : setMessageContent(
                              dict.form.signupFailedMessageEmailAlreadyInUse
                            );
                        break;
                      case "auth/user-not-found":
                        setMessageContent(
                          dict.form.loginFailedMessageUserNotFound
                        );
                        break;
                      default:
                        setMessageContent(error.message);
                    }
                    setOpen(true);
                  }
                  formikActions.setSubmitting(false);
                }
              }
            }
            render={({ isValid, isSubmitting }) => (
              <Form className={classes.mt2}>
                {selectedProduct && (
                  <Fragment>
                    <Typography className={classes.mtb2}>
                      {dict.product.fillApplication}
                    </Typography>
                    <Paper elevation={0} square className={classes.p3}>
                      {!userState.signedIn && (
                        <Typography
                          variant="caption"
                          className={classes.mb3}
                          display="block"
                        >
                          {dict.product.freeProductNewStudentInstruction}
                        </Typography>
                      )}
                      <Grid container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={4}>
                            <Grid item xs={6}>
                              <Field
                                name="last_name"
                                label={dict.basicInfo.lastName}
                                type="text"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                                autoFocus
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                name="first_name"
                                label={dict.basicInfo.firstName}
                                type="text"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                name="last_name_kana"
                                label={dict.basicInfo.lastNameKana}
                                type="text"
                                variant="outlined"
                                fullWidth
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                name="first_name_kana"
                                label={dict.basicInfo.firstNameKana}
                                type="text"
                                variant="outlined"
                                fullWidth
                                component={TextField}
                              />
                            </Grid>
                            {!userState.signedIn && (
                              <Fragment>
                                <Grid item xs={12}>
                                  <Field
                                    name="email"
                                    label={dict.common.email}
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    component={TextField}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    name="password"
                                    label={dict.common.password}
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    component={TextField}
                                  />
                                </Grid>

                                <Grid
                                  container
                                  justify="center"
                                  className={classes.mb2}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={policyAgreed}
                                        onChange={(event: any) => {
                                          setPolicyAgreed(event.target.checked);
                                        }}
                                        value={policyAgreed}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <Typography>
                                        <Link
                                          href="https://datamix.co.jp/privacy-policy/"
                                          target="_blank"
                                        >
                                          {dict.order.privacyPolicy}
                                        </Link>
                                        {dict.order.agree}
                                      </Typography>
                                    }
                                  />
                                </Grid>
                              </Fragment>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </Grid>
                    </Paper>
                    {isSubmitting && <LinearProgress />}
                    <Grid container className={classes.mt3}>
                      <Grid item xs={12} md={3}></Grid>
                      <Grid item xs={12} md={6}>
                        <Fab
                          className={classes.primaryFabMb02}
                          disabled={
                            selectedProduct === undefined ||
                            !isValid ||
                            !policyAgreed
                          }
                          type="submit"
                          variant="extended"
                          size="large"
                          color="secondary"
                        >
                          {dict.product.apply}
                        </Fab>
                      </Grid>
                      <Grid item xs={12} md={3}></Grid>
                    </Grid>
                  </Fragment>
                )}
              </Form>
            )}
          />
        </Container>
      )}
      <MessageDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={dict.order.goToProductList}
        history={props.history}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(ProductDetailFree))
);
