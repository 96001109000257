import useFetch from "./useFetch";
import { getUrl } from "../userstate";
import React from "react";

export const useGetQuestionnaire = (): any => {
  const { execute } = useFetch("GET");
  const [questionnaire, setQuestionnaire] = React.useState([]);
  const [getQuestionnaireError, setGetQuestionnaireError] = React.useState(
    null
  );

  const getQuestionnaire = async ({
    questionnaireId
  }: {
    questionnaireId: string;
  }) => {
    try {
      const fetchedQuestionnaire = await execute({
        url: getUrl() + `v1/s/questionnaire/${questionnaireId}`
      });
      setQuestionnaire(fetchedQuestionnaire);
    } catch (error) {
      setGetQuestionnaireError(error);
    }
  };

  return { questionnaire, getQuestionnaire, getQuestionnaireError };
};

export const usePutQuestionnaireAnswer = (): any => {
  const { execute } = useFetch("PUT");

  const putQuestionnaireAnswer = async ({
    questionnaireId,
    body
  }: {
    questionnaireId: string;
    body: object;
  }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/questionnaire/${questionnaireId}/answer`,
        body: body
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { putQuestionnaireAnswer };
};
