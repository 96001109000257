import { Container, Grid, Hidden, Paper, withStyles } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router";
import { stylesCommon, stylesProfile } from "../../css";
import dict from "../../dictionary";
import { usePutMe } from "../../fetch/me";
import useUserStateContext from "../../userstate";
import ProfileMenu from "./ProfileMenu";
import StudentInfoForm from "./StudentInfoForm";

const ProfileStudentInfo = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { putMe, getMe } = usePutMe();

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item md={3}>
              <ProfileMenu />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
            <Paper elevation={0} className={classes.squareMobile}>
              <StudentInfoForm parentName="ProfileStudentInfo" />
            </Paper>
            <Paper className={classes.squareMobile}>
              <p className={classes.p4}>{dict.settings.withdrawal}</p>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProfile)(withRouter(ProfileStudentInfo))
);
