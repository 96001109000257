import {
  ButtonBase,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { stylesCommon, stylesProductCard } from "../../css";

import PropTypes from "prop-types";
import dict from "../../dictionary";
import { findSchoolName } from "./ProductList";
import { withRouter } from "react-router";

const ProductCardFree = (props: any) => {
  const { classes } = props;
  const [href, setHref] = useState<string>("");

  useEffect(() => {
    props.product.tags.forEach((tag: string) => {
      tag.startsWith("free") && setHref(tag.replace("free_", ""));
    });
  }, [props.product]);

  return (
    <ButtonBase
      className={classes.fullWidth}
      onClick={() => {
        props.history.push({
          pathname: `/product/free/${href}`,
        });
      }}
    >
      <Card className={classes.w100}>
        <CardContent className={classes.p3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8} className={classes.taLeft}>
              <Typography
                gutterBottom
                variant="h6"
                color="textSecondary"
                className={classes.bolder}
              >
                {props.product.name}
                {props.product?.tags.filter((tag: string) => {
                  return tag.startsWith(`school_`);
                }).length > 0 ? (
                  <Chip
                    label={findSchoolName(
                      props.product.tags.filter((tag: string) => {
                        return tag.startsWith(`school_`);
                      })[0]
                    )}
                    className={`${classes.ml2} ${classes.chipPlace}`}
                  />
                ) : (
                  props.school && (
                    <Chip
                      label={findSchoolName(props.school)}
                      className={`${classes.ml2} ${classes.chipPlace}`}
                    />
                  )
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.taRight}>
              <Typography>{dict.common.free}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

ProductCardFree.propTypes = {
  product: PropTypes.object,
};

export default withStyles(stylesCommon)(
  withStyles(stylesProductCard)(withRouter(ProductCardFree))
);
