import { Box, Button, Hidden } from "@material-ui/core";
import React, { Fragment } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../image/logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import dict from "../dictionary";
import firebase from "../firebase";
import useUserStateContext from "../userstate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "gray",
      fontSize: "1em",
      fontWeight: "bolder",
      marginRight: "12px",
    },
    title: {
      flexGrow: 1,
      color: theme.palette.primary.main,
      fontWeight: "bold",
      marginLeft: theme.spacing(1),
      display: "flex",
    },
    logo: {
      width: "10rem",
      height: "100%",
    },
    lisItem: {
      paddingRight: "2rem",
      paddingLeft: "1.5rem",
    },
    divider: {
      marginTop: "0.75rem",
      marginBottom: "0.75rem",
    },
  })
);

const HeaderBar = (props: any) => {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const { userState } = useUserStateContext();
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const sideList = () => (
    <div
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {userState.signedIn ? (
        <List>
          {[
            [dict.productList.title, "/product"],
            [dict.order.orderListTitle, "/order"],
            [dict.course.myCourse, "/course"],
          ].map((values, index) => (
            <ListItem
              button
              component={Link}
              to={values[1]}
              className={classes.lisItem}
            >
              <div onClick={toggleDrawer(false)}>
                <ListItemText primary={values[0]} />
              </div>
            </ListItem>
          ))}
          <Divider className={classes.divider} />
          {[
            [dict.order.studentInfo, "/profile"],
            [dict.common.account, "/settings"],
          ].map((values, index) => (
            <ListItem
              button
              component={Link}
              to={values[1]}
              className={classes.lisItem}
            >
              <div onClick={toggleDrawer(false)}>
                <ListItemText primary={values[0]} />
              </div>
            </ListItem>
          ))}
          <ListItem
            button
            component={Link}
            to="/login"
            className={classes.lisItem}
          >
            <div
              onClick={() => {
                toggleDrawer(false);
                firebase.auth.signOut();
              }}
            >
              <ListItemText primary={dict.common.logout} />
            </div>
          </ListItem>
        </List>
      ) : (
        <List>
          {[[dict.productList.title, "/product"]].map((values, index) => (
            <ListItem
              button
              component={Link}
              to={values[1]}
              className={classes.lisItem}
            >
              <div onClick={toggleDrawer(false)}>
                <ListItemText primary={values[0]} />
              </div>
            </ListItem>
          ))}
          <Divider className={classes.divider} />
          {[
            [dict.common.login, "/login"],
            [dict.common.signup, "/signup"],
          ].map((values, index) => (
            <ListItem
              button
              component={Link}
              to={values[1]}
              className={classes.lisItem}
            >
              <div onClick={toggleDrawer(false)}>
                <ListItemText primary={values[0]} />
              </div>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );

  return (
    <Fragment>
      <AppBar position="relative">
        <Toolbar>
          <a href="https://datamix.co.jp" className={classes.title}>
            <img src={Logo} className={classes.logo} />
          </a>

          <Hidden mdUp>
            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          {userState.signedIn ? (
            <Fragment>
              <Hidden smDown>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/product"
                >
                  {dict.productList.title}
                </Button>
                <Button className={classes.button} component={Link} to="/order">
                  {dict.order.orderListTitle}
                </Button>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/course"
                >
                  {dict.course.myCourse}
                </Button>

                <Button
                  className={classes.button}
                  component={Link}
                  to="/profile"
                >
                  {dict.common.settings}
                </Button>
              </Hidden>
            </Fragment>
          ) : (
            <Fragment>
              <Hidden smDown>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/signup"
                >
                  {dict.common.signup}
                </Button>
                <Button className={classes.button} component={Link} to="/login">
                  {dict.common.login}
                </Button>
              </Hidden>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Drawer open={state} onClose={toggleDrawer(false)} anchor={"right"}>
        {sideList()}
      </Drawer>
    </Fragment>
  );
};

export default HeaderBar;
