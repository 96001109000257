import {
  Container,
  Grid,
  Hidden,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { stylesCommon, stylesProduct } from "../../css";

import dict from "../../dictionary";
import { withRouter } from "react-router";

const ProductDetailTop = (props: any) => {
  const { classes } = props;
  const [divideNumber, setDivideNumber] = useState<3 | 4>(3);

  useEffect(() => {
    props.schoolName ? setDivideNumber(3) : setDivideNumber(4);
  }, [props.schoolName]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container className={classes.mb4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0} className={classes.squareLeft}>
            <Typography component="h1" variant="h6" className={classes.mb2}>
              {props.productTitle}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.lineBreak}
            >
              {props.productDescription}
            </Typography>
          </Paper>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={6} className={props.backImage}></Grid>
        </Hidden>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper elevation={0} className={classes.p38}>
            <Grid container>
              {props.schoolName && (
                <Grid
                  item
                  xs={12}
                  md={divideNumber}
                  className={classes.borderLeft}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    className={`${classes.mb1} ${classes.bolder}`}
                  >
                    {dict.common.holdingSchool}
                  </Typography>
                  <Typography>{props.schoolName}</Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={divideNumber}
                className={classes.borderLeft}
              >
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  className={`${classes.mb1} ${classes.bolder}`}
                >
                  {dict.common.coursePeriod}
                </Typography>
                <Typography>
                  {props.coursePeriod
                    ? props.coursePeriod
                    : `${dict.common.empty}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={divideNumber}
                className={classes.borderLeft}
              >
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  className={`${classes.mb1} ${classes.bolder}`}
                >
                  {dict.common.totalPrice}
                </Typography>
                <Typography>{props.priceRange}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={divideNumber}
                className={classes.borderLeft}
              >
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  className={`${classes.mb1} ${classes.bolder}`}
                >
                  {dict.common.applicationDueDate}
                </Typography>
                <Typography>{props.dueDate}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProduct)(withRouter(ProductDetailTop))
);
