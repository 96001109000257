import { intToWeekdayJa } from "../../enum";

export const formatDate = (date: Date, withWeekDay: boolean = true) => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const weekday = intToWeekdayJa[date.getDay()];

  return withWeekDay ? y + "/" + m + "/" + d + weekday : y + "/" + m + "/" + d;
};

export const formatDateJa = (date: Date, withWeekDay: boolean = true) => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const weekday = intToWeekdayJa[date.getDay()];

  return withWeekDay
    ? y + "年" + m + "月" + d + "日" + weekday
    : y + "年" + m + "月" + d + "日";
};

export const formatDateTime = (date: Date) => {
  const y = date.getFullYear();
  const m = ("00" + (date.getMonth() + 1)).slice(-2);
  const d = ("00" + date.getDate()).slice(-2);
  const H = ("00" + date.getHours()).slice(-2);
  const M = ("00" + date.getMinutes()).slice(-2);
  return y + "/" + m + "/" + d + " " + H + ":" + M;
};

export const formatDateWeekdayTime = (date: Date) => {
  const m = ("00" + (date.getMonth() + 1)).slice(-2);
  const d = ("00" + date.getDate()).slice(-2);
  const H = ("00" + date.getHours()).slice(-2);
  const M = ("00" + date.getMinutes()).slice(-2);

  const weekday = intToWeekdayJa[date.getDay()];

  return (
    date.getMonth() + 1 + "/" + date.getDate() + "" + weekday + H + ":" + M
  );
};

export const formatTime = (date: Date) => {
  const H = ("00" + date.getHours()).slice(-2);
  const M = ("00" + date.getMinutes()).slice(-2);

  return H + ":" + M;
};

export const doubleToSingleArray = (doubleArray: []) => {
  return doubleArray.reduce((pre: any[], current: any) => {
    pre.push(...current);
    return pre;
  }, []);
};

export const nestedObjToFlat = (originalObj: object, nestedObj: object) => {
  let newObj: any = originalObj;
  for (const [k, v] of Object.entries(nestedObj)) {
    for (const [kk, vv] of Object.entries(v)) {
      newObj[kk] = vv;
    }
  }
  return newObj;
};

export const addTax = (price: number) => {
  return Math.floor(price * 1.1);
};

export const formatPrice = (price: number) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 円";
};

// /**
//  * 配列に対してSQLのdistinct句的なことをするやつ
//  * @param fields distinctする対象のフィールドの名前を配列で
//  * @return filterメソッドに渡すためのfunctionを返す
//  */
export function distinct(this: any, fieldNames: { [x: string]: any }) {
  var self = this;
  return function (item: any, i: string | number, arr: any) {
    return i == indexOf(arr, item, equalsAllFields);
  };

  // arrのなかにobjが含まれていればそのインデックス番号を返す
  // 探し方はcomparatorを使って探す
  function indexOf(
    arr: { [x: string]: any },
    obj: any,
    comparator: { (a: any, b: any): boolean; (arg0: any, arg1: any): boolean }
  ) {
    for (var index in arr) {
      if (comparator(obj, arr[index]) == true) return index;
    }
    return -1;
  }

  // オブジェクトaとbが fieldNamesに当てられたプロパティーを比較して同じであればtrueを返す
  function equalsAllFields(a: { [x: string]: any }, b: { [x: string]: any }) {
    for (var i in fieldNames) {
      var f = fieldNames[i];
      if (a[f] !== b[f]) return false;
    }
    return true;
  }
}

export const getUniqueObjects = (arr: any, comp: any) => {
  const unique = arr
    .map((e: any) => e[comp])

    // store the keys of the unique objects
    .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e: any) => arr[e])
    .map((e: any) => arr[e]);

  return unique;
};

export const renderTerm = (term: string) => {
  term = term.replace("term_", "");
  const year = term.slice(0, 4);
  let month = term.slice(4, 6);
  month = month.slice(0, 1) === "0" ? month.slice(1, 2) : month;
  return `${year}年${month}月期`;
};
