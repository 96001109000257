import React, { ReactNode, useEffect } from "react";

import firebase from "../firebase";
import useUserStateContext from "../userstate";

interface IProps {
  children: ReactNode;
  // any other props that come into the component
}

const UserStateWtapper = ({ children, ...props }: IProps) => {
  const { switchSignedIn } = useUserStateContext();

  useEffect(() => {
    firebase.auth.onAuthStateChanged(val => {
      const user = firebase.getCurrentUser();
      user
        // ? user.emailVerified
        //   ? switchSignedIn(true)
        //   : switchSignedIn(false)
        ? switchSignedIn(true)
        : switchSignedIn(false);
    });
  }, []);

  return <div>{children}</div>;
};

export default UserStateWtapper;
