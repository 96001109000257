import { UseFetch } from "./../../../admin/src/fetch/types";
import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetSessions = (): any => {
  const { execute } = useFetch("GET");
  const [sessions, setSessions] = React.useState([]);
  const [
    getSessionsOfCourseError,
    setGetSessionsOfCourseError,
  ] = React.useState(null);

  const getSessionsOfCourse = async ({ courseId }: { courseId: string }) => {
    try {
      const fetchedSessions = await execute({
        url: getUrl() + `v1/s/course/${courseId}/session`,
      });
      setSessions(fetchedSessions);
    } catch (error) {
      setGetSessionsOfCourseError(error);
    }
  };

  return { sessions, getSessionsOfCourse, getSessionsOfCourseError };
};

export const useAddAttendSession = () => {
  const { execute } = useFetch("PUT");

  const addAttendSession = async ({ sessionId }: { sessionId: string }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/session/${sessionId}/attend`,
        body: undefined,
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { addAttendSession };
};

export const useCancelSession = () => {
  const { execute } = useFetch("PUT");

  const cancelSession = async ({ sessionId }: { sessionId: string }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/session/${sessionId}/cancel`,
        body: undefined,
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { cancelSession };
};

export const useGetTransferrableSessions = () => {
  const { execute } = useFetch("GET");
  const [sessions, setSessions] = React.useState([]);
  const [getSessionsError, setGetSessionsError] = React.useState(null);

  const getSessions = async ({ sessionId }: { sessionId: string }) => {
    try {
      const fetchedSessions = await execute({
        url: getUrl() + `v1/s/session/${sessionId}/transfer`,
      });
      setSessions(fetchedSessions);
    } catch (error) {
      setGetSessionsError(error);
    }
  };

  return { sessions, getSessions, setSessions, getSessionsError };
};

export const useTransferSession = () => {
  const { execute } = useFetch("PUT");

  const transferSession = async ({
    fromSessionId,
    toSessionId,
  }: {
    fromSessionId: string;
    toSessionId: string;
  }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/session/${fromSessionId}/transfer/${toSessionId}`,
        body: undefined,
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { transferSession };
};
