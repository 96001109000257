import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useGetMe, usePutMe } from "../../fetch/me";

import MessageDialog from "../MessageDialog";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import dict from "../../dictionary";
import { useCancelOrder } from "../../fetch/order";

const initialValuesCancel = {
  reason: "",
};

const CancelDialog = (props: {
  open: boolean;
  handleClose: any;
  history: any | null;
  order: any;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { cancelOrder } = useCancelOrder();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const { me, getMe } = useGetMe();

  useEffect(() => {
    getMe({});
  }, []);

  return (
    <Fragment>
      <Dialog open={props.open}>
        <Formik
          initialValues={initialValuesCancel}
          onSubmit={async (value) => {
            setIsSubmitting(true);

            const resJson = await cancelOrder({ orderId: props.order.id });
            const resStatus = resJson.status;
            switch (resStatus) {
              case 200:
                const slackEndpoint: string = process.env
                  .REACT_APP_SLACK_ENDPOINT
                  ? process.env.REACT_APP_SLACK_ENDPOINT
                  : "https://hooks.slack.com/services/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";
                await fetch(slackEndpoint, {
                  method: "POST",
                  body: JSON.stringify({
                    text: `id:${me.id} has canceled ${props.order?.product?.name}. reason: ${value.reason}`,
                    icon_emoji: "thinking_face",
                  }),
                })
                  .then(function (response) {
                    props.history.push({
                      pathname: `/cancel/complete`,
                    });
                  })
                  .then(function (myJson) {});

                break;
              case 500:
                setMessageTitle(dict.order.cancelFailedTitle);
                setMessageContent(resJson.statusText);
                setOpen(true);
                break;
              default:
                console.log(resJson);
            }
            setIsSubmitting(false);
          }}
          render={({ submitForm, isSubmitting }) => (
            <Form>
              {isSubmitting && <LinearProgress />}
              <DialogTitle id="alert-dialog-title">
                {dict.common.cancel}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dict.order.confirmCancel}
                </DialogContentText>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Field
                          name="reason"
                          label={dict.order.reason}
                          type="text"
                          variant="outlined"
                          component={TextField}
                          fullWidth
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose} color="default">
                  {dict.common.close}
                </Button>
                <Button onClick={submitForm} color="primary">
                  <Typography>{dict.order.cancel}</Typography>
                </Button>
              </DialogActions>
            </Form>
          )}
        />
      </Dialog>
      <MessageDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={null}
        history={null}
      />
    </Fragment>
  );
};

CancelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object,
  order: PropTypes.object,
};

export default CancelDialog;
