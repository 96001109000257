import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetCourses = (): any => {
  const { execute } = useFetch("GET");
  const [courses, setCourses] = React.useState([]);
  const [getEnrolledCoursesError, setGetEnrolledCoursesError] = React.useState(
    null
  );

  const getEnrolledCourses = async () => {
    try {
      const fetchedCourses = await execute({
        url: getUrl() + `v1/s/course`
      });
      const enrolledCourses = fetchedCourses.filter((course: any) => {
        return !course.categories.includes("Admission") && course.confirmed_at;
      });
      setCourses(enrolledCourses);
    } catch (error) {
      setGetEnrolledCoursesError(error);
    }
  };

  return { courses, getEnrolledCourses, getEnrolledCoursesError };
};
