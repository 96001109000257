import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { formatDateWeekdayTime, formatTime, renderTerm } from "../../util";

import PropTypes from "prop-types";
import React from "react";
import dict from "../../dictionary";
import { useTransferSession } from "../../fetch/session";

const SessionTransferDialog = (props: {
  open: boolean;
  handleClose: any;
  sessions: any[];
  fromSessionId: any;
  openMessageDialog: any;
  setMessageTitle: any;
  setMessageContent: any;
  setDisableBackdropClick: any;
  setNextUrl: any;
}) => {
  const { transferSession } = useTransferSession();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {dict.course.transferSession}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.sessions &&
          props.sessions.filter((session: any) => {
            return new Date() < new Date(session.end_at);
          }).length != 0
            ? dict.course.transferInstruction
            : dict.course.noTransferrableCourses}
        </DialogContentText>

        <Grid container spacing={2}>
          {/* Filtering future sessions */}
          {props.sessions &&
            props.sessions
              .filter((session: any) => {
                return new Date() < new Date(session.end_at);
              })
              .sort((a: any, b: any) => {
                return a.start_at > b.start_at ? 1 : -1;
              })
              .map((session: any) => {
                return (
                  <Grid item xs={12}>
                    <ButtonBase
                      style={{ width: "100%", textAlign: "initial" }}
                      disabled={session.remainings <= 0}
                      onClick={async () => {
                        props.setDisableBackdropClick(false);
                        props.setNextUrl(null);
                        const response = await transferSession({
                          fromSessionId: props.fromSessionId,
                          toSessionId: session.id,
                        });
                        switch (response.status) {
                          case 200:
                            props.setMessageTitle(
                              dict.course.transferSuccessTitle
                            );
                            props.setMessageContent(
                              dict.course.transferSuccessContent
                            );
                            break;
                          case 403:
                            props.setMessageTitle(
                              dict.course.transferFailTitle
                            );
                            props.setMessageContent(
                              dict.course.transferFailContent403
                            );
                            break;
                          default:
                            props.setMessageTitle(
                              dict.course.transferFailTitle
                            );
                            props.setMessageContent(
                              dict.course.transferFailContentDefault
                            );
                            break;
                        }
                        props.handleClose();
                        props.openMessageDialog();
                      }}
                    >
                      <Card
                        style={{
                          width: "100%",
                        }}
                      >
                        <CardContent style={{ padding: "16px" }}>
                          <Typography
                            variant="caption"
                            display="block"
                            style={{ marginBottom: "4px" }}
                          >{`${renderTerm(session.course.term)}${
                            session.course.name
                          }　${session.name}`}</Typography>
                          <Typography style={{ marginBottom: "4px" }}>
                            {`${formatDateWeekdayTime(
                              new Date(session.start_at)
                            )} 〜 ${formatTime(new Date(session.end_at))} @ ${
                              session.room?.name
                            }`}
                            <Typography variant="caption"></Typography>
                          </Typography>
                          {session.remainings <= 0 && (
                            <Typography
                              variant="caption"
                              display="block"
                              style={{ marginBottom: "4px", color: "#e43302" }}
                            >
                              {dict.course.noRemainings}
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </ButtonBase>
                  </Grid>
                );
              })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="default">
          {dict.common.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SessionTransferDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fromSessionId: PropTypes.string.isRequired,
  sessions: PropTypes.array.isRequired,
  openMessageDialog: PropTypes.func.isRequired,
  setMessageTitle: PropTypes.func.isRequired,
  setMessageContent: PropTypes.func.isRequired,
  setDisableBackdropClick: PropTypes.func.isRequired,
  setNextUrl: PropTypes.func.isRequired,
};

export default SessionTransferDialog;
