import {
  Box,
  Checkbox,
  Container,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { addTax, formatDate, formatPrice } from "../../util";
import { stylesCommon, stylesOrder } from "../../css";

import MessageDialog from "../MessageDialog";
import OrderStepper from "./OrderStepper";
import OrderStepperFree from "./OrderStepperFree";
import dict from "../../dictionary";
import { usePostOrder } from "../../fetch/order";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const OrderConfirm = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { postOrder } = usePostOrder();

  const [open, setOpen] = useState(false);
  const [messageTitle] = useState<string>(dict.order.orderFailedTitle);
  const [messageContent, setMessageContent] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    !props.location.state && props.history.push("/");
  }, [userState, props.history]);

  return (
    <Fragment>
      <Box className={classes.box}>
        <Container maxWidth="lg">
          {props.location.state.product.price === 0 ? (
            <OrderStepperFree activeStep={1} />
          ) : (
            <OrderStepper activeStep={2} />
          )}
        </Container>
      </Box>
      <Container maxWidth="lg" className={classes.mb4}>
        <Typography variant="h6" className={classes.p2}>
          {dict.order.confirm}
        </Typography>
        <Paper elevation={0} square className={classes.p4}>
          <Grid container className={classes.mb3} justify="flex-end">
            <Grid item xs={12} md={12} className={classes.mb1}>
              <Typography className={classes.mt1}>
                {dict.order.orderingProduct}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.order.productName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.product.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.common.totalPrice}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {formatPrice(addTax(props.location.state.product.price))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.mb3} justify="flex-end">
            <Grid item xs={12} md={12} className={classes.mb1}>
              <Typography className={classes.mt1}>
                {dict.order.studentInfo}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.order.studentName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.last_name}{" "}
                    {props.location.state.me.first_name}（
                    {props.location.state.me.last_name_kana}{" "}
                    {props.location.state.me.first_name_kana}）
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.basicInfo.gender}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.gender === "male"
                      ? dict.basicInfo.male
                      : props.location.state.me.gender === "female"
                      ? dict.basicInfo.female
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.basicInfo.birthDate}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {formatDate(props.location.state.me.birth_date, false)}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.basicInfo.telMobilePhone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.tel_mobile_phone}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.basicInfo.nameEmergency}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.name_emergency}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.basicInfo.telEmergency}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.tel_emergency}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.common.postcode}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.postcode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3} className={classes.mt1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dict.common.address}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className={classes.mt1}>
                  <Typography variant="subtitle2">
                    {props.location.state.me.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {props.location.state?.order && (
            <Grid container className={classes.mb3} justify="flex-end">
              <Grid item xs={12} md={12} className={classes.mb1}>
                <Typography className={classes.mt1}>
                  {dict.order.orderInfo}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container>
                  <Grid item xs={12} md={3} className={classes.mt1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {dict.order.orderInfo}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.mt1}>
                    <Typography variant="subtitle2">
                      {props.location.state.order.prepayment
                        ? dict.order.prepayment
                        : dict.order.nonPrepayment}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.mt1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {dict.order.paymentName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.mt1}>
                    <Typography variant="subtitle2">
                      {props.location.state.order.oneself
                        ? dict.order.oneself
                        : dict.order.nonOneself}
                    </Typography>
                  </Grid>
                  {props.location.state.order.prepayment &&
                    !props.location.state.order.oneself && (
                      <Fragment>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {props.location.state.order.payment_name}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                  {!props.location.state.order.prepayment &&
                    !props.location.state.order.oneself && (
                      <Fragment>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {props.location.state.order.payment_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9} className={classes.mt1}>
                          <Typography variant="subtitle2">
                            {props.location.state.order.postcode}{" "}
                            {props.location.state.order.address}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                  {props.location.state.order.kyufukin && (
                    <Fragment>
                      <Grid item xs={12} md={3} className={classes.mt1}>
                        <Typography variant="subtitle2" color="textSecondary">
                          {dict.order.kyufukin}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} className={classes.mt1}>
                        <Typography variant="subtitle2">
                          {dict.order.requestKyufukin}
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  <Grid item xs={12} md={3} className={classes.mt1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {dict.order.note}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.mt1}>
                    <Typography
                      variant="subtitle2"
                      className={classes.lineBreak}
                    >
                      {props.location.state.order.note
                        ? props.location.state.order.note
                        : dict.common.none}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.mb3} justify="flex-end">
            <Grid item xs={12} md={12} className={classes.mb1}>
              <Typography className={classes.mt1}>
                {dict.order.policyAgreement}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={policyAgreed}
                      onChange={(event: any) => {
                        setPolicyAgreed(event.target.checked);
                      }}
                      value={policyAgreed}
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      <Link
                        className={classes.mb2}
                        href="https://datamix.co.jp/corp/terms/"
                        target="_blank"
                      >
                        {dict.order.policy}
                      </Link>
                      {dict.common.and}
                      <Link
                        className={classes.mb2}
                        href="https://datamix.co.jp/privacy-policy/"
                        target="_blank"
                      >
                        {dict.order.privacyPolicy}
                      </Link>
                      {dict.order.agree}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="center" className={classes.mt3} spacing={3}>
            <Grid item sm={3} xs={6}>
              <Fab
                className={classes.defaultFab}
                variant="extended"
                disabled={isDisabled}
                onClick={() => {
                  props.history.goBack();
                }}
                size="large"
                color="default"
              >
                {dict.common.back}
              </Fab>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Fab
                className={classes.primaryFabMb0}
                type="submit"
                variant="extended"
                disabled={isDisabled || !policyAgreed}
                onClick={async (event) => {
                  setIsDisabled(true);
                  const note = props.location.state?.order?.note ?? "";
                  let questions = props.location.state?.order ?? [];

                  delete questions?.note;
                  const body = {
                    price: props.location.state.product.price,
                    note: note,
                    questions: Object.entries(questions).map(([key, value]) => {
                      let questionObj: any = new Object();
                      questionObj[key] = value;
                      return questionObj;
                    }),
                    invoice: props.location.state?.invoice,
                    receipt: props.location.state?.receipt,
                  };
                  const resJson = await postOrder({
                    productId: props.location.state.product.id,
                    body: body,
                  });
                  const resStatus = await resJson.status;
                  switch (resStatus) {
                    case 201:
                      props.history.push({
                        pathname: `/order/complete`,
                        state: {
                          product: props.location.state.product,
                        },
                      });
                      break;
                    case 461:
                      setMessageContent(
                        dict.order.orderFailedMessageAlreadyOrdered
                      );
                      setOpen(true);
                      break;
                    case 462:
                      setMessageContent(
                        dict.order.orderFailedMessageTotallyConfirmed
                      );
                      setOpen(true);
                      break;
                    case 463:
                      setMessageContent(
                        dict.order.orderFailedMessageNoRemainings
                      );
                      setOpen(true);
                      break;
                    case 464:
                      setMessageContent(
                        dict.order.orderFailedMessageDuplicated
                      );
                      setOpen(true);
                      break;
                    case 400:
                    case 500:
                      setMessageContent(
                        dict.order.orderFailedMessageServerError
                      );
                      setOpen(true);
                      break;
                    default:
                      console.log(resJson);
                  }
                  setIsDisabled(false);
                }}
                size="large"
                color="primary"
              >
                {dict.order.confirmOrder}
              </Fab>
            </Grid>
          </Grid>
        </Paper>
        {isDisabled && <LinearProgress className={classes.bottom} />}
      </Container>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={"../product"} // TODO currently url will be order/product
        actionButtonCaption={dict.order.goToProductList}
        history={props.history}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(OrderConfirm))
);
