import { Container, Paper, Typography, withStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { stylesCommon, stylesOrder } from "../css";

import MessageDialog from "./MessageDialog";
import dict from "../dictionary";
import { useGetProducts } from "../fetch/product";
import { usePostOrder } from "../fetch/order";
import useUserStateContext from "../userstate";
import { withRouter } from "react-router";

const currentRegion = async () => {
  try {
    const resIp = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
    const resIpRows = (await resIp.text()).split("\n");
    const rowsIpAddress = resIpRows.filter((resRow: string) => {
      return resRow.startsWith("ip=");
    });
    const ipAddress =
      rowsIpAddress.length > 0 && rowsIpAddress[0].replace("ip=", "");
    const resGeo = await fetch(`https://ipapi.co/${ipAddress}/region`);
    const currentRegion = await resGeo.text();
    return currentRegion;
  } catch (error) {
    return "FailToGetRegion";
  }
};

export const orderFreeProduct = async ({
  product,
  postOrder,
  props,
  setMessageContent,
  setOpen,
  formikActions,
}: {
  product: any;
  postOrder: any;
  props: any;
  setMessageContent: any;
  setOpen: any;
  formikActions?: any;
}) => {
  const body = {
    price: product.price,
    note: "",
    questions: [{ region: await currentRegion() }],
  };
  const resJson = await postOrder({
    productId: product.id,
    body: body,
  });
  const resStatus = await resJson.status;
  switch (resStatus) {
    case 201:
      props.history.push({
        pathname: `/order/complete`,
        state: {
          product: product,
          previous: "OrderCreate",
        },
      });
      break;
    case 461:
      setMessageContent(dict.order.orderFreeFailedMessageAlreadyOrdered);
      setOpen(true);
      break;
    case 462:
      setMessageContent(dict.order.orderFreeFailedMessageTotallyConfirmed);
      setOpen(true);
      break;
    case 463:
      setMessageContent(dict.order.orderFreeFailedMessageNoRemainings);
      setOpen(true);
      break;
    case 464:
      setMessageContent(dict.order.orderFreeFailedMessageDuplicated);
      setOpen(true);
      break;
    case 400:
    case 500:
      setMessageContent(dict.order.orderFailedMessageServerError);
      setOpen(true);
      break;
    default:
      console.log(resJson);
  }
  formikActions && formikActions.setSubmitting(false);
};

const OrderCreate = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { productsAll, getProducts } = useGetProducts();
  const [product, setProduct] = useState<any>(undefined);
  const { postOrder } = usePostOrder();
  const [open, setOpen] = useState(false);
  const [messageTitle] = useState<string>(dict.order.orderFailedTitle);
  const [messageContent, setMessageContent] = useState<string>("");

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    getProducts({});
  }, []);

  useEffect(() => {
    const matchProducts = productsAll
      ? productsAll.filter((product: any) => {
          return product.id === props.match.params.productId;
        })
      : [];
    matchProducts.length > 0 && setProduct(matchProducts[0]);
  }, [productsAll]);

  useEffect(() => {
    (async () => {
      if (product) {
        orderFreeProduct({
          product: product,
          postOrder: postOrder,
          props: props,
          setMessageContent: setMessageContent,
          setOpen: setOpen,
        });
      }
    })();
    return () => {};
  }, [product]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mt4}>
        <Paper elevation={0} square className={classes.p4}>
          <Typography variant="h6">
            {product && `${product?.name}${dict.order.completing}`}
          </Typography>
        </Paper>
      </Container>
      <MessageDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={"../product"} // TODO currently url will be order/product
        actionButtonCaption={dict.order.goToProductList}
        history={props.history}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(OrderCreate))
);
