export const weekdayOptions = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

export const intToWeekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const intToWeekdayShort = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const intToWeekdayJa = [
  "（日）",
  "（月）",
  "（火）",
  "（水）",
  "（木）",
  "（金）",
  "（土）",
];

export const mailMagazines = [
  { name: "school", title: "スクールに関するお知らせ" },
  { name: "recruitment", title: "人材紹介に関するお知らせ" },
];

export const schoolNamesDict = [
  { name: "tokyo", nameJa: "東京校" },
  { name: "osaka", nameJa: "大阪校" },
  { name: "fukuoka", nameJa: "福岡校" },
  { name: "any", nameJa: "全国どこでも" },
];

export const categoryNamesDict = [
  { name: "ikusei", nameJa: "育成講座" },
  // { name: "junbi", nameJa: "育成コース準備ステップ" },
  { name: "other", nameJa: "入学試験 / その他講座" },
  { name: "free", nameJa: "説明会 / 資料ダウンロード" },
];
