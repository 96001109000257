import { StyleRules } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";

const subColor = "#5cace8";
const subColor0 = "#5cace8";
const subColor1 = "#848484";
const subColor2 = "#00b7d1";
const subColor3 = "#6a8d97";
const subColor4 = "#9ba8db";
const subColor5 = "#02b3e4";
const subColor6 = "#02b3e4";
const subColor7 = "#00bac7";

export const stylesCommon = (theme: Theme): StyleRules =>
  createStyles({
    primaryFab: {
      color: "white",
      fontWeight: "bolder",
      width: "100%",
      marginBottom: theme.spacing(4),
      background:
        "linear-gradient(160deg, rgba(2,179,228,1) 0%, rgba(2,228,164,1) 100%)",
    },
    primaryFabMb0: {
      color: "white",
      fontWeight: "bolder",
      width: "100%",
      background:
        "linear-gradient(160deg, rgba(2,179,228,1) 0%, rgba(2,228,164,1) 100%)",
    },
    primaryFabMb02: {
      color: "white",
      fontWeight: "bolder",
      width: "100%",
      marginBottom: theme.spacing(4),
      background:
        "linear-gradient(160deg, rgba(2,179,228,1) 0%, rgba(2,228,164,1) 100%)",
      "@media(max-width:768px)": {
        marginBottom: theme.spacing(0),
      },
    },
    primaryFabFlat: {
      color: "white",
      fontWeight: "bolder",
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    primaryFabFlat2: {
      color: "white",
      fontWeight: "bolder",
      width: "100%",
    },
    defaultFab: {
      fontWeight: "bolder",
      width: "100%",
    },
    invoiceReceipt: {
      position: "absolute",
      boxShadow: "none",
    },
    positionAbsolute: {
      position: "absolute",
    },
    verticalAlignTop: {
      verticalAlign: "top",
    },
    colorPink: {
      color: "#e402b3",
    },
    colorYellow: {
      color: "#e4a402",
    },
    colorGreen: {
      color: "#b3e402",
    },
    colorRed: {
      color: "#e43302",
    },
    container: {
      padding: theme.spacing(4),
      "@media(max-width:768px)": {
        padding: theme.spacing(0),
      },
    },
    container2: {
      paddingTop: theme.spacing(4),
      "@media(max-width:768px)": {
        padding: theme.spacing(0),
      },
    },
    p1: {
      padding: theme.spacing(1),
    },
    pb0: {
      paddingBottom: theme.spacing(0),
    },
    pb1: {
      paddingBottom: theme.spacing(1),
    },
    pb2: {
      paddingBottom: theme.spacing(2),
    },
    pb3: {
      paddingBottom: theme.spacing(3),
    },
    pt1: {
      paddingTop: theme.spacing(1),
    },
    pt2: {
      paddingTop: theme.spacing(2),
    },
    pr2: {
      paddingRight: theme.spacing(2),
    },
    prl2: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    ptb1: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    ptb2: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    p0: {
      padding: theme.spacing(0),
    },
    p2: {
      padding: theme.spacing(2),
    },
    p3: {
      padding: theme.spacing(3),
    },
    p4: {
      padding: theme.spacing(4),
    },
    p5: {
      padding: theme.spacing(5),
    },
    p38: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(8),
      "@media(max-width:768px)": {
        paddingLeft: theme.spacing(0),
      },
    },
    m0: {
      margin: theme.spacing(0),
    },
    m2: {
      margin: theme.spacing(2),
    },
    m3: {
      margin: theme.spacing(3),
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    mt4: {
      marginTop: theme.spacing(4),
    },
    mt5: {
      marginTop: theme.spacing(5),
    },
    mt6: {
      marginTop: theme.spacing(6),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    mb4: {
      marginBottom: theme.spacing(4),
    },
    mb5: {
      marginBottom: theme.spacing(5),
    },
    mb6: {
      marginBottom: theme.spacing(6),
    },
    mb8: {
      marginBottom: theme.spacing(8),
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    ml2: {
      marginLeft: theme.spacing(2),
    },
    ml3: {
      marginLeft: theme.spacing(3),
    },
    ml4: {
      marginLeft: theme.spacing(4),
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    mr2: {
      marginRight: theme.spacing(2),
    },
    mr3: {
      marginRight: theme.spacing(3),
    },
    mr4: {
      marginRight: theme.spacing(4),
    },
    mrl2: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    mtb1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mtb2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    mtb3: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    mtb4: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    colorStrip: {
      borderLeft: `medium solid ${subColor}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip0: {
      borderLeft: `medium solid ${subColor0}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip1: {
      borderLeft: `medium solid ${subColor1}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip2: {
      borderLeft: `medium solid ${subColor2}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip3: {
      borderLeft: `medium solid ${subColor3}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip4: {
      borderLeft: `medium solid ${subColor4}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip5: {
      borderLeft: `medium solid ${subColor5}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip6: {
      borderLeft: `medium solid ${subColor6}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    colorStrip7: {
      borderLeft: `medium solid ${subColor7}`,
      borderWidth: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    squareMobile: {
      "@media(max-width:768px)": {
        borderRadius: "0px",
      },
      boxShadow: "0 0 0 0",
    },
    bolder: {
      fontWeight: "bolder",
    },
    form: {
      padding: theme.spacing(4),
    },
    w100: {
      width: "100%",
    },
    taLeft: {
      textAlign: "left",
    },
    taRight: {
      textAlign: "right",
    },
    taCenter: {
      textAlign: "center",
    },
    bottom: {
      bottom: theme.spacing(0),
    },
    lineBreak: {
      whiteSpace: "pre-line",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    mobileClose: {
      "@media(max-width:768px)": {
        display: `none`,
      },
    },
    bgWhite: {
      backgroundColor: "white",
    },
    borderRound: {
      borderRadius: `100px`,
    },
  });

export const stylesAuthentication = (theme: Theme): StyleRules =>
  createStyles({
    paper: {
      marginTop: theme.spacing(6),
    },
    header: {
      padding: theme.spacing(3),
    },
    appBar: {
      padding: theme.spacing(2),
    },
    marginBottom0: {
      marginBottom: theme.spacing(0),
    },
    link: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(5),
      cursor: "pointer",
    },
    link2: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
      cursor: "pointer",
    },
    backImage: {
      backgroundImage:
        "url(https://elements-cover-images-0.imgix.net/f8e4b435-cc1b-4061-a1e5-6f597007c81e?auto=compress%2Cformat&fit=max&w=2038&s=ede9891cbac25116936ad7ae41375a08)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
  });

export const stylesProfile = (theme: Theme): StyleRules =>
  createStyles({
    margin4: {
      margin: theme.spacing(4),
    },
    list: {
      backgroundColor: "white",
      borderRadius: "4px",
      padding: "0px",
    },
    listItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    cardActions: {
      width: "100%",
    },
    card: {
      marginTop: theme.spacing(3),
    },
    table: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    formControl: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    radioGroup: {
      display: "inline",
      justifyContent: "space-between",
    },
    form: {
      padding: theme.spacing(4),
      "@media(max-width:768px)": {
        padding: theme.spacing(2),
      },
    },
    formLabel: {
      display: "block",
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    textPrimary: {
      color: theme.palette.primary.main,
    },
  });

export const stylesProduct = (theme: Theme): StyleRules =>
  createStyles({
    borderLeft: {
      // borderLeft: `medium solid ${theme.palette.primary.main}`,
      borderLeft: `medium solid rgba(0, 0, 0, 0.12)`,
      borderWidth: "3px",
      paddingLeft: theme.spacing(2.5),
      "@media(max-width:768px)": {
        borderWidth: "0",
        paddingLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    courseContainerOuter: {
      backgroundColor: "#f3f8fe",
      paddingBottom: theme.spacing(4),
    },
    courseContainerInner: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      "@media(max-width:768px)": {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0),
        marginTop: theme.spacing(3),
      },
    },
    selectionContainer: {
      backgroundColor: "white",
    },
    squareLeft: {
      borderRadius: "4px 0px 0px 4px",
      padding: theme.spacing(3),
    },
    squareRight: {
      borderRadius: "0px 4px 4px 0px",
      // padding: theme.spacing(3)
    },

    backImageIkusei: {
      borderRadius: "0px 4px 4px 0px",
      backgroundImage:
        "url(https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/material/ikusei.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    backImageJunbi: {
      borderRadius: "0px 4px 4px 0px",
      backgroundImage:
        "url(https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/material/junbi.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    backImageOther: {
      borderRadius: "0px 4px 4px 0px",
      backgroundImage:
        "url(https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/material/other.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    containerHeader: {
      paddingTop: theme.spacing(2),
      marginBottom: "-16px",
    },
    stepHeader: {
      fontWeight: "bolder",
      marginBottom: theme.spacing(1),
    },
    stepTitle: {
      fontWeight: "bolder",
      marginBottom: theme.spacing(2),
    },
    pcMarginBottom: {
      "@media(min-width:769px)": {
        marginBottom: theme.spacing(4),
      },
    },
  });

export const stylesOrder = (theme: Theme): StyleRules =>
  createStyles({
    box: {
      backgroundColor: "white",
    },
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      "@media(max-width:768px)": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    kyufukinLabel: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  });

export const stylesBill = (theme: Theme): StyleRules =>
  createStyles({
    priceText: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontWeight: "bold",
    },
    stamp: {
      top: "-40px",
      position: "relative",
      '& img': {
        width: "103px",
      }
    },
    note: {
      width: "100%",
      padding: theme.spacing(2),
      border: "1px solid rgba(0, 0, 0, 0.12)",
      // borderRadius: theme.spacing(1),
      whiteSpace: "pre-line",
    },
  });

export const stylesList = (theme: Theme): StyleRules =>
  createStyles({
    box: {
      backgroundColor: "white",
    },
    productContainerInner: {
      padding: theme.spacing(4),
      paddingRight: theme.spacing(24),
      paddingLeft: theme.spacing(24),
      paddingBottom: theme.spacing(0),
      "@media(max-width:768px)": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0),
        marginTop: theme.spacing(3),
      },
    },
    listContainerInner: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      "@media(max-width:768px)": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0),
        marginTop: theme.spacing(3),
      },
    },
    scheduleContainerInner: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      "@media(max-width:768px)": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0),
        marginTop: theme.spacing(3),
      },
      marginBottom: theme.spacing(4),
    },
    card: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    contents: {
      position: "static",
      "&::after": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "0",
        width: "120%",
        height: "95vh",
        margin: "3% -10% 0",
        // background: "rgb(255,255,255)",
        background:
          "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,247,252) 100%)",
        webkitTransformOrigin: "left center",
        msTransformOrigin: "left center",
        transformOrigin: "left center",
        webkitTransform: "rotate(-12deg)",
        msTransform: "rotate(-12deg)",
        transform: "rotate(-12deg)",
        // zIndex: "-1",
      },
    },
    filterHeader: {
      paddingLeft: "1rem",
      color: theme.palette.primary.main,
    },
    filterPlace: {
      color: subColor4,
      borderLeft: `0.219rem solid ${subColor4}`,
    },
    filterKind: {
      color: subColor7,
      borderLeft: `0.219rem solid ${subColor7}`,
    },
    filteringOpenClose: {
      fontSize: `1.25rem`,
    },
    searchInput: {
      "& .MuiInputBase-root": {
        borderRadius: `100px`,
        backgroundColor: `white`,
        border: `none`,
        boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
        "& fieldset": {
          border: `none`,
        },
      },
      "& .MuiInputBase-input": {
        // marginTop: `4rem`,
        // marginBottom: `6rem`,
      },
    },
    courseList: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  });

export const stylesProductCard = (theme: Theme): StyleRules =>
  createStyles({
    fullWidth: {
      width: "100%",
      marginBottom: theme.spacing(3),
    },
    description: {
      whiteSpace: "pre-line",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipPlace: {
      backgroundColor: `${subColor4}`,
      color: `white`,
      fontSize: `1rem`,
    },
  });

export const stylesCourseCard = (theme: Theme): StyleRules =>
  createStyles({
    fullWidth: {
      width: "100%",
      marginBottom: theme.spacing(3),
    },
    description: {
      wordWrap: "break-word",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export const stylesFooter = (theme: Theme): StyleRules =>
  createStyles({
    marginTopAuto: { marginTop: "auto" },
    containerOuter: {
      "@media(max-width:768px)": {
        marginTop: theme.spacing(4),
      },
    },
    containerInner: {
      // backgroundColor: "#fafafa",
      padding: theme.spacing(4),
      // paddingBottom: theme.spacing(0),
      "@media(max-width:768px)": {
        padding: theme.spacing(3),
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
        // paddingTop: theme.spacing(0),
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(2)
      },
    },
  });
