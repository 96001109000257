import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Hidden,
  Typography,
  withStyles,
  Paper,
} from "@material-ui/core";

import { stylesCommon, stylesCourseCard } from "../../css";
import dict from "../../dictionary";
import { formatDate, formatPrice, addTax, renderTerm } from "../../util";

export const getFirstDate = (course: any) => {
  const startAts = course.sessions
    .map((session: any) => {
      return session.start_at;
    })
    .sort();
  return new Date(startAts[0]);
};

export const getLastDate = (course: any) => {
  const endAts = course.sessions
    .map((session: any) => {
      return session.end_at;
    })
    .sort();
  return new Date(endAts[endAts.length - 1]);
};

const CourseCard = (props: any) => {
  const { classes } = props;

  return (
    <Card
      className={eval(`classes.colorStrip${props.colorNum}`)}
      style={{ width: "100%", padding: "0px" }}
      // className={classes.w100}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={12} className={classes.taLeft}>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              className={classes.bolder}
            >
              {props.course.term && `【${renderTerm(props.course.term)}】`}
              {props.course.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {props.showDescription && (
            <Grid item xs={12} md={12} className={classes.taLeft}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                className={classes.description}
              >
                {props.course.description.length > 300
                  ? `${props.course.description.substr(0, 300)}...`
                  : props.course.description}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={12} className={classes.taRight} spacing={2}>
            <Hidden mdUp>
              <Grid item xs={12} md={12} className={classes.taLeft} spacing={2}>
                <Typography color="textSecondary">
                  {dict.common.applicationDueDate}
                </Typography>
              </Grid>
            </Hidden>
            <Typography color="primary" className={classes.mt1}>
              <Hidden smDown>
                <Typography
                  color="textSecondary"
                  display="inline"
                  // className={classes.mr2}
                >
                  {getFirstDate(props.course) &&
                    getLastDate(props.course) &&
                    `${formatDate(
                      getFirstDate(props.course),
                      false
                    )}〜 ${formatDate(getLastDate(props.course), false)}`}
                </Typography>
              </Hidden>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object,
  showDescription: PropTypes.bool,
  colorNum: PropTypes.number,
};

export default withStyles(stylesCommon)(
  withStyles(stylesCourseCard)(withRouter(CourseCard))
);
