import PropTypes from "prop-types";
import React, { Fragment } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@material-ui/core";

const MessageDialog = (props: {
  open: boolean;
  handleClose: any;
  messageTitle: string;
  messageContent: string;
  nextUrl: string | null;
  actionButtonCaption: string | null;
  history: any | null;
  disableBackdropClick: any;
}) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={
        props.disableBackdropClick ? props.disableBackdropClick : false
      }
    >
      <DialogTitle id="alert-dialog-title">{props.messageTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.messageContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.nextUrl ? (
          <Button
            onClick={() => props?.history.push(props.nextUrl)}
            color="primary"
          >
            <Typography>{props.actionButtonCaption}</Typography>
          </Button>
        ) : (
          <Button onClick={props.handleClose} color="default">
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  messageTitle: PropTypes.string.isRequired,
  messageContent: PropTypes.string.isRequired,
  nextUrl: PropTypes.string,
  actionButtonCaption: PropTypes.string,
  history: PropTypes.object,
  disableBackdropClick: PropTypes.bool
};

export default MessageDialog;
