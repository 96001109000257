import React, { Fragment, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router";

import {
  CircularProgress,
  Container,
  Divider,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

import { schoolNamesDict } from "../../../../enum";
import { stylesCommon, stylesList } from "../../css";
import dict from "../../dictionary";
import { useGetCourses } from "../../fetch/course";
import {
  useAddAttendSession,
  useCancelSession,
  useGetSessions,
  useGetTransferrableSessions,
} from "../../fetch/session";
import useUserStateContext from "../../userstate";
import { formatDateWeekdayTime, formatTime, renderTerm } from "../../util";
import MessageDialog from "../MessageDialog";
import SessionTransferDialog from "./SessionTransferDialog";

const CourseList = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const { courses, getEnrolledCourses, getEnrolledCoursesError } =
    useGetCourses();
  const [course, setCourse] = useState<any>(null);
  const { sessions, getSessionsOfCourse, getSessionsOfCourseError } =
    useGetSessions();
  const {
    sessions: transferrableSessions,
    getSessions: getTransferrableSessions,
    setSessions: setTransferrableSessions,
    getSessionsError: getTransferrableSessionsError,
  } = useGetTransferrableSessions();
  const { addAttendSession } = useAddAttendSession();
  const { cancelSession } = useCancelSession();
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [fromSessionId, setFromSessionId] = useState<any>(null);
  const [aWeekBeforeFirstSession, setAWeekBeforeFirstSession] =
    useState<Date | null>(null);

  // for popup menu for PC
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // for SessionTransferDialog and MessageDialog
  const [open, setOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [disableBackdropClick, setDisableBackdropClick] = useState(true);

  useEffect(() => {
    getEnrolledCourses({});
  }, []);

  useEffect(() => {
    setCourse(
      courses.filter((course: any) => {
        return course.id === props.match.params.courseId;
      })[0]
    );
  }, [courses]);

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    getSessionsOfCourse({ courseId: props.match.params.courseId });
  }, [userState, props.history, open, messageDialogOpen]);

  useEffect(() => {
    // sessions && sessions.length > 0 && setCourse(sessions[0].course);
    // calc 7 days before the first session
    sessions &&
      sessions.length > 0 &&
      setAWeekBeforeFirstSession(
        new Date(
          new Date(sessions[0].start_at).setDate(
            new Date(sessions[0].start_at).getDate() - 7
          )
        )
      );
  }, [sessions]);

  // for button in modal dialog if the session has questionnaire after the session
  const [nextUrl, setNextUrl] = useState(null);
  const [actionButtonCaption, setActionButtonCaption] = useState(null);
  useEffect(() => {
    selectSession(selectedSession);
  }, [selectedSession]);

  const selectSession = (session: any) => {
    let newNextUrl = null;
    let newActionButtonCaption = null;
    session?.questionnaires?.forEach((questionnaire: any) => {
      if (questionnaire.title.includes(dict.course.questionnaireAfterSession)) {
        newNextUrl = `../questionnaire/${questionnaire.id}?course_id=${course.id}&session_id=${session.id}`;
        newActionButtonCaption = `${questionnaire.title} ${dict.course.answer}`;
      }
    });
    setNextUrl(newNextUrl);
    setDisableBackdropClick(true);
    setActionButtonCaption(newActionButtonCaption);
  };

  const junbiNames = [
    "プログラミング基礎",
    "ビジネス統計学入門",
    "SQL入門",
    "Excelで理解する機械学習入門",
  ];
  const ikuseiNames = [
    "ブートキャンプステップ",
    "ベーシックステップ",
    "アドバンスステップ",
    "インテグレーションステップ",
  ];

  const findSchoolNameEn = (schoolNameJa: string) => {
    const schoolNameEnTmp = schoolNamesDict.filter(
      (schoolName: { nameJa: string }) => {
        return schoolNameJa === schoolName.nameJa;
      }
    );
    return schoolNameEnTmp.length > 0 ? schoolNameEnTmp[0].name : "";
  };

  useEffect(() => {
    console.log(sessions);
  }, [sessions]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.scheduleContainerInner}>
        {sessions && sessions.length > 0 ? (
          <>
            <Paper elevation={0} square className={classes.p4}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={12} className={classes.taLeft}>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    className={classes.bolder}
                  >
                    {course?.term && `【${renderTerm(course?.term)}】`}
                    {course?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className={classes.taLeft}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    source={course?.description}
                  />
                </Grid>
              </Grid>
              {course?.url && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} className={classes.taRight}>
                    <Link
                      className={classes.link2}
                      onClick={() => window.open(course?.url)}
                    >
                      {dict.course.toCanvas}
                    </Link>
                  </Grid>
                </Grid>
              )}
              {junbiNames.some((junbiName: string) =>
                course?.name.includes(junbiName)
              ) && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} className={classes.taRight}>
                    <Link
                      className={classes.link2}
                      onClick={() =>
                        window.open(
                          `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_junbi_${
                            course?.term
                          }_${findSchoolNameEn(course?.school?.name)}.pdf`
                        )
                      }
                    >
                      {dict.course.toSchedule}
                    </Link>
                  </Grid>
                </Grid>
              )}
              {ikuseiNames.some((ikuseiName: string) =>
                course?.name.includes(ikuseiName)
              ) && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} className={classes.taRight}>
                    <Link
                      className={classes.link2}
                      onClick={() =>
                        window.open(
                          `https://datamix-internal.s3-ap-northeast-1.amazonaws.com/info-session/datamix_schedule_ikusei_${
                            course?.term
                          }_${findSchoolNameEn(course?.school?.name)}.pdf`
                        )
                      }
                    >
                      {dict.course.toSchedule}
                    </Link>
                  </Grid>
                </Grid>
              )}
              {course?.questionnaires.length > 0 &&
                course?.questionnaires
                  .filter((questionnaire: any) => {
                    return !questionnaire.title.includes(
                      dict.course.questionnaireAfterSession
                    );
                  })
                  .map((questionnaire: any) => (
                    <Grid container spacing={2} className={classes.mt1}>
                      <Grid item xs={12} md={12} className={classes.taRight}>
                        <Link
                          className={classes.link2}
                          onClick={() => {
                            props.history.push(
                              `../questionnaire/${questionnaire.id}?course_id=${course.id}`
                            );
                          }}
                        >
                          {questionnaire.answered === true
                            ? `${questionnaire.title} ${dict.course.reAnswer}`
                            : `${questionnaire.title} ${dict.course.answer}`}
                        </Link>
                      </Grid>
                    </Grid>
                  ))}
            </Paper>

            <Typography component="h1" variant="h6" className={classes.mtb2}>
              {dict.common.schedule}
            </Typography>
            <Paper elevation={0} square className={classes.prl2}>
              <Menu
                id="session-actions"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!course?.categories?.includes("Online") && (
                  <>
                    {/* Transfer */}
                    <MenuItem
                      onClick={async () => {
                        setFromSessionId(selectedSession.id);
                        await getTransferrableSessions({
                          sessionId: selectedSession?.id,
                        });
                        setOpen(true);
                        handleClose();
                      }}
                      disabled={
                        selectedSession?.attend ||
                        selectedSession?.transferred_to?.attend ||
                        selectedSession?.canceled ||
                        (aWeekBeforeFirstSession &&
                          new Date() < aWeekBeforeFirstSession)
                      }
                    >
                      {dict.course.transferSession}
                    </MenuItem>

                    {/* Cancel */}
                    <MenuItem
                      onClick={() => {
                        if (window.confirm(dict.course.confirmCancel)) {
                          cancelSession({ sessionId: selectedSession?.id });
                          setMessageTitle(dict.course.cancelSuccessTitle);
                          setMessageContent(dict.course.cancelSuccessContent);
                          setMessageDialogOpen(true);
                        }
                        handleClose();
                      }}
                      disabled={
                        selectedSession?.attend ||
                        selectedSession?.transferred_to?.attend ||
                        selectedSession?.canceled ||
                        new Date(selectedSession?.end_at) < new Date()
                      }
                    >
                      {dict.course.cancel}
                    </MenuItem>
                  </>
                )}
                {selectedSession?.questionnaires?.length > 0 &&
                  selectedSession?.questionnaires?.map((questionnaire: any) => (
                    <MenuItem
                      onClick={() => {
                        props.history.push(
                          `../questionnaire/${questionnaire.id}?course_id=${course.id}&session_id=${selectedSession.id}`
                        );
                        handleClose();
                      }}
                    >
                      {questionnaire.answered === true
                        ? `${questionnaire.title} ${dict.course.reAnswer}`
                        : `${questionnaire.title} ${dict.course.answer}`}
                    </MenuItem>
                  ))}
              </Menu>
              <List>
                {sessions.map((session: any) => {
                  if (session.transferred_to) {
                    session = session.transferred_to;
                    session.transferred = true;
                  } else {
                    session.transferred = false;
                  }
                  return (
                    <Fragment>
                      <ListItem
                        divider={sessions.slice(-1)[0] != session}
                        className={classes.ptb1}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justify="center"
                          spacing={2}
                        >
                          <Grid item xs={12} md={7}>
                            <Typography>
                              {session.name}
                              {session?.attend ||
                              session?.transferred_to?.attend
                                ? dict.course.attended
                                : session.canceled
                                ? dict.course.canceled
                                : dict.course.notYetAttended}
                            </Typography>
                            {session.description && (
                              <Typography variant="caption" display="block">
                                {session.description}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.taRight}>
                            <Typography>{session?.room?.name}</Typography>
                            <Typography variant="caption" display="block">
                              {new Date(session.start_at).getDate() ===
                              new Date(session.end_at).getDate()
                                ? `${formatDateWeekdayTime(
                                    new Date(session.start_at)
                                  )} 〜 ${formatTime(new Date(session.end_at))}`
                                : `${formatDateWeekdayTime(
                                    new Date(session.start_at)
                                  )} 〜 ${formatDateWeekdayTime(
                                    new Date(session.end_at)
                                  )}`}
                              {session.transferred && dict.course.transferred}
                            </Typography>
                          </Grid>
                          {/* PC */}
                          <Hidden smDown>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              className={classes.taRight}
                            >
                              <div>
                                <IconButton
                                  aria-controls="session-actions"
                                  aria-haspopup="true"
                                  onClick={(
                                    event: React.MouseEvent<HTMLButtonElement>
                                  ) => {
                                    setAnchorEl(event.currentTarget);
                                    setSelectedSession(session);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            </Grid>
                          </Hidden>
                          {/* Mobile */}
                          <Hidden mdUp>
                            {!course?.categories?.includes("Online") && (
                              <>
                                {/* Transfer */}
                                <Grid item xs={6}>
                                  <Fab
                                    size="large"
                                    variant="extended"
                                    color="default"
                                    onClick={async () => {
                                      setFromSessionId(session.id);
                                      await getTransferrableSessions({
                                        sessionId: session.id,
                                      });
                                      setOpen(true);
                                    }}
                                    className={classes.defaultFab}
                                    disabled={
                                      session?.attend ||
                                      session?.transferred_to?.attend ||
                                      session?.canceled ||
                                      (aWeekBeforeFirstSession &&
                                        new Date() < aWeekBeforeFirstSession)
                                    }
                                  >
                                    <SwapHorizIcon />
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.ml1}
                                    >
                                      {dict.course.transferSessionShort}
                                    </Typography>
                                  </Fab>
                                </Grid>

                                {/* Cancel */}
                                <Grid
                                  item
                                  xs={
                                    course?.categories?.includes("Online")
                                      ? 12
                                      : 6
                                  }
                                >
                                  <Fab
                                    size="large"
                                    variant="extended"
                                    color="default"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          dict.course.confirmCancel
                                        )
                                      ) {
                                        cancelSession({
                                          sessionId: session?.id,
                                        });
                                        setMessageTitle(
                                          dict.course.cancelSuccessTitle
                                        );
                                        setMessageContent(
                                          dict.course.cancelSuccessContent
                                        );
                                        handleClose();
                                        setMessageDialogOpen(true);
                                      }
                                    }}
                                    disabled={
                                      session?.attend ||
                                      session?.transferred_to?.attend ||
                                      session?.canceled ||
                                      new Date(session?.end_at) < new Date()
                                    }
                                    className={classes.defaultFab}
                                  >
                                    <CancelIcon />
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.ml1}
                                    >
                                      {dict.course.cancelShort}
                                    </Typography>
                                  </Fab>
                                </Grid>
                              </>
                            )}
                            {session?.questionnaires?.length > 0 &&
                              session?.questionnaires?.map(
                                (questionnaire: any) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className={classes.taRight}
                                    >
                                      <Link
                                        className={classes.link2}
                                        onClick={() => {
                                          props.history.push(
                                            `../questionnaire/${questionnaire.id}?course_id=${course.id}&session_id=${session.id}`
                                          );
                                        }}
                                      >
                                        {questionnaire.answered === true
                                          ? `${questionnaire.title} ${dict.course.reAnswer}`
                                          : `${questionnaire.title} ${dict.course.answer}`}{" "}
                                      </Link>
                                    </Grid>
                                  );
                                }
                              )}
                          </Hidden>
                        </Grid>
                      </ListItem>
                    </Fragment>
                  );
                })}
              </List>
            </Paper>
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
      <SessionTransferDialog
        open={open}
        handleClose={() => {
          setTransferrableSessions([]);
          setOpen(false);
        }}
        fromSessionId={fromSessionId && fromSessionId}
        sessions={transferrableSessions && transferrableSessions}
        openMessageDialog={() => {
          setMessageDialogOpen(true);
        }}
        setMessageTitle={setMessageTitle}
        setMessageContent={setMessageContent}
        setDisableBackdropClick={setDisableBackdropClick}
        setNextUrl={setNextUrl}
      />
      <MessageDialog
        open={messageDialogOpen}
        handleClose={() => {
          setMessageDialogOpen(false);
        }}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={nextUrl}
        actionButtonCaption={actionButtonCaption}
        history={props.history}
        disableBackdropClick={true}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesList)(withRouter(CourseList))
);
