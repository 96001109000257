import useFetch from "./useFetch";
import { getUrl } from "../userstate";
import React from "react";

export const useGetOrder = (): any => {
  const { execute } = useFetch("GET");
  const [orders, setOrders] = React.useState([]);
  const [getOrdersError, setGetOrdersError] = React.useState(null);

  const getOrders = async () => {
    try {
      const fetchedOrders = await execute({
        url: getUrl() + `v1/s/order`
      });
      setOrders(fetchedOrders);
    } catch (error) {
      setGetOrdersError(error);
    }
  };

  return { orders, getOrders, getOrdersError };
};

export const usePostOrder = (): any => {
  const { execute } = useFetch("POST");

  const postOrder = async ({
    productId,
    body
  }: {
    productId: string;
    body: object;
  }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/product/${productId}/order`,
        body: body
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { postOrder };
};

export const useCancelOrder = (): any => {
  const { execute } = useFetch("DELETE");

  const cancelOrder = async ({ orderId }: { orderId: string }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/order/${orderId}`
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { cancelOrder };
};

export const useModifyOrderInvoice = (): any => {
  const { execute } = useFetch("PUT");

  const modifyOrderInvoice = async ({
    orderId,
    invoice
  }: {
    orderId: string;
    invoice: object;
  }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/order/${orderId}/invoice`,
        body: { invoice: invoice }
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { modifyOrderInvoice };
};

export const useModifyOrderReceipt = (): any => {
  const { execute } = useFetch("PUT");

  const modifyOrderReceipt = async ({
    orderId,
    receipt
  }: {
    orderId: string;
    receipt: object;
  }) => {
    try {
      const response = await execute({
        url: getUrl() + `v1/s/order/${orderId}/receipt`,
        body: { receipt: receipt }
      });
      return response;
    } catch (error) {
      alert(error);
    }
  };

  return { modifyOrderReceipt };
};
