import * as Yup from "yup";

import {
  Container,
  Divider,
  Fab,
  Grid,
  Hidden,
  LinearProgress,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import firebase, { actionCodeSettings } from "../../firebase";
import { stylesCommon, stylesProfile } from "../../css";

import MessageDialog from "../MessageDialog";
import ProfileMenu from "./ProfileMenu";
import { TextField } from "formik-material-ui";
import dict from "../../dictionary";
import { mailMagazines } from "../../../../enum";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const Settings = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();
  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const SettingsSchema = Yup.object().shape({
    email: Yup.string().email(dict.form.email).required(dict.form.required),
  });

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
  }, [userState, props.history]);

  async function updateEmail(email: string, setSubmitting: any) {
    try {
      if (firebase.auth.currentUser) {
        await firebase.auth.currentUser.updateEmail(email);
        firebase.auth.currentUser.sendEmailVerification(actionCodeSettings);
      }
      setMessageTitle(dict.settings.updateEmailSucceededTitle);
      setMessageContent(dict.settings.updateEmailSucceededMessage);
      setOpen(true);
    } catch (error) {
      setMessageTitle(dict.form.updateEmailFailedTitle);
      switch (error.code) {
        case "auth/email-already-in-use":
          setMessageContent(dict.form.signupFailedMessageEmailAlreadyInUse);
          break;
        case "auth/requires-recent-login":
          setMessageContent(
            dict.form.updateEmailFailedMessageRequiresRecentLogin
          );
          break;
        default:
          setMessageContent(error.message);
      }
      setOpen(true);
    }
    setSubmitting(false);
  }

  async function resetPassword() {
    try {
      if (firebase.auth.currentUser) {
        const email = firebase.auth.currentUser.email;
        email &&
          (await firebase.auth.sendPasswordResetEmail(
            email,
            actionCodeSettings
          ));
        setMessageTitle(dict.passwordReset.succeededTitle);
        setMessageContent(dict.passwordReset.succeededMessage);
        setOpen(true);
      }
    } catch (error) {
      setMessageTitle(dict.form.passwordResetFailedTitle);
      switch (error.code) {
        case "auth/user-not-found":
          setMessageContent(dict.form.loginFailedMessageUserNotFound);
          break;
        default:
          setMessageContent(error.message);
      }
      setOpen(true);
    }
  }

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item md={3}>
              <ProfileMenu />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
            <Paper elevation={0} className={classes.squareMobile}>
              <Formik
                initialValues={{
                  email: firebase.auth.currentUser
                    ? firebase.auth.currentUser.email
                    : "",
                }}
                validationSchema={SettingsSchema}
                onSubmit={(values, formikActions) => {
                  values.email &&
                    updateEmail(values.email, formikActions.setSubmitting);
                }}
                render={({ submitForm, isSubmitting, isValid }) => (
                  <Fragment>
                    <Form className={classes.form}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography component="h4">
                            {dict.settings.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="email"
                            type="email"
                            variant="outlined"
                            label={dict.common.email}
                            component={TextField}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        className={classes.mt3}
                        justify="flex-end"
                      >
                        <Fab
                          className={classes.primaryFabFlat}
                          disabled={isSubmitting || !isValid}
                          type="submit"
                          variant="extended"
                          onClick={submitForm}
                          size="medium"
                          color="primary"
                        >
                          {dict.common.update}
                        </Fab>
                      </Grid>

                      {isSubmitting && <LinearProgress />}
                      <Divider />
                      <Grid container spacing={4} className={classes.mt2}>
                        <Grid item xs={12}>
                          <Typography component="h4">
                            {dict.settings.password}
                          </Typography>
                          <Typography component="p" className={classes.mt2}>
                            {dict.settings.message1}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        className={classes.mt3}
                        justify="flex-end"
                      >
                        <Fab
                          className={classes.primaryFabFlat2}
                          variant="extended"
                          onClick={() => {
                            resetPassword();
                          }}
                          size="medium"
                          color="primary"
                        >
                          {dict.common.receiveMail}
                        </Fab>
                      </Grid>
                    </Form>
                  </Fragment>
                )}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={null}
        history={null}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesProfile)(withRouter(Settings))
);
