import { Container, Paper, Typography, withStyles } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router";
import { stylesCommon, stylesOrder } from "../../css";
import dict from "../../dictionary";
import { useGetMe } from "../../fetch/me";
import useUserStateContext from "../../userstate";

interface MyWindow extends Window {
  dataLayer: any;
  msmaf_m_v: string;
}
declare var window: MyWindow;

const SignupComplete = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { me, getMe } = useGetMe();

  useEffect(() => {
    userState.signedIn && props.history.push("/");
    getMe({});
  }, [userState, props.history]);

  useEffect(() => {
    if (me) {
      window.msmaf_m_v = me.id;
    }
  }, [me]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mt4}>
        <Paper elevation={0} square className={classes.p4}>
          <Typography variant="h6" className={classes.mb2}>
            {dict.form.signupSucceededTitle}
          </Typography>
          <Typography className={classes.lineBreak}>
            {props.location.state.message}
          </Typography>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(SignupComplete))
);
